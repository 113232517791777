import { useContext } from "react";
import { WarehouseContext } from "./WarehouseProvider";

export const useWarehouseContext = () => {
  const warehouseContext = useContext(WarehouseContext);
  if (!warehouseContext) {
    throw new Error(
      "No warehouse context.Did you wrap your component in WarehouseProvider?"
    );
  }

  return warehouseContext;
};
