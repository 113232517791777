import React, { useState, useContext } from "react";
import { Button } from "components/button";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import StoreFormContainer from "components/store-form-container";
import { getQueryParams } from "lib/utils";
import LoadingSpinner from "components/loading-spinner";
import AuthContext from "contexts/AuthContext";
import InputValidation from "components/input-validation";
import PasswordInput from "components/password-input";

export default function TradeInPassword() {
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = getQueryParams(location.search);
  const navigate = useNavigate();

  const updatedParams = { ...queryParams };

  const { loginHandler, loginError } = useContext(AuthContext);
  const [email] = useState(queryParams.email);
  console.log(email);
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = () => {
    setIsLoading(t("ui.signing_in"));
    loginHandler(
      { username: email, password },
      {
        onSuccess: () => {
          navigate(t("urls.store_check_in"));
          navigate(0);
          setTimeout(() => {
            setIsLoading(false);
          }, 500);
        },
      },
      {
        onError: () => {
          setIsLoading(false);
        },
      }
    );
  };

  return (
    <StoreFormContainer
      submitParams={updatedParams}
      secondaryParams={updatedParams}
    >
      {isLoading ? (
        <LoadingSpinner isLoading={isLoading} />
      ) : (
        <div className="flex flex-grow flex-col items-center justify-center px-6 py-4 w-max self-center h-screen">
          <h1 className="text-6xl mb-large">Enter your password to continue</h1>
          <div className="w-full">
            <span className="block mb-4 max-w-full overflow-hidden text-ellipsis whitespace-nowrap">
              Logged in as{" "}
              <span className="font-bold text-blue-500">{email}</span>
            </span>
            <PasswordInput
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <InputValidation
              className="mr-auto pt-4"
              errorMessage={loginError?.message}
            />
            <Button
              disabled={!password}
              onClick={onSubmit}
              className="w-full py-6 mt-6"
            >
              Continue
            </Button>
            <Button
              variant="secondary"
              onClick={() => navigate(t("urls.login"))}
              className="w-full py-6 mt-6"
            >
              Login as another user
            </Button>
          </div>
        </div>
      )}
    </StoreFormContainer>
  );
}
