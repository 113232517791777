import { useQuery } from "react-query";
import { useContext } from "react";
import AuthContext from "contexts/AuthContext";

const { REACT_APP_API } = process.env;

const searchPlaces = async ({
  accessToken,
  query,
  country,
  language,
  sessionToken,
}) => {
  const response = await fetch(
    `${REACT_APP_API}/address/place-suggestions?query=${encodeURIComponent(
      query
    )}&country=${country}&language=${language}&sessionToken=${sessionToken}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error("Failed to fetch places");
  }

  return response.json();
};

export const usePlacesSearch = (
  query,
  country,
  language,
  { onSuccess = () => ({}), onError = () => ({}) } = {}
) => {
  const { authTokens } = useContext(AuthContext);
  const accessToken = authTokens?.access_token;

  return useQuery({
    queryKey: ["places-search", query],
    queryFn: () => searchPlaces({ accessToken, query, country, language }),
    enabled: !!query && query.length >= 2, // Only search when query is at least 2 chars
    onSuccess,
    onError: (error) => {
      console.error("Places search failed:", error);
      onError(error);
    },
  });
};

export const getPlaceDetails = async ({ accessToken, placeId, language }) => {
  const response = await fetch(
    `${REACT_APP_API}/address/place-details?placeId=${encodeURIComponent(placeId)}&language=${language}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error("Failed to fetch place details");
  }

  return response.json();
};

export const usePlaceDetails = (
  placeId,
  language,
  sessionToken,
  { onSuccess = () => ({}), onError = () => ({}) } = {}
) => {
  const { authTokens } = useContext(AuthContext);
  const accessToken = authTokens?.access_token;

  return useQuery({
    queryKey: ["place-details", placeId, language],
    queryFn: () =>
      getPlaceDetails({ accessToken, placeId, language, sessionToken }),
    enabled:
      !!placeId && placeId.length > 0 && !!language && language.length > 0,
    onSuccess,
    onError: (error) => {
      console.error("Place details fetch failed:", error);
      onError(error);
    },
  });
};
