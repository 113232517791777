import React, { useContext } from "react";
import { Button } from "components/button";
import { getQueryParams } from "lib/utils";
import { useRestartIfAbsent } from "hooks/useRestartIfAbsent";
import { useLocation } from "react-router-dom";
import StoreFormContainer from "components/store-form-container";
import { TAKEBACK_DISBURSEMENT_NAME } from "consts/query-params";
import { useTakebackById } from "api/use-takebacks";
import DollarWEBP from "assets/bgDollar.webp";
import Dollar from "assets/bgDollar.jpg";
import AuthContext from "contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { calculateEstimate } from "lib/calculateEstimate";

export default function StoreTradeIFinal() {
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = getQueryParams(location.search);
  const takeback = useTakebackById({ id: queryParams.id });
  const { user, logoutHandler } = useContext(AuthContext);
  const userEmail = user.email;

  useRestartIfAbsent(
    queryParams,
    ["w", TAKEBACK_DISBURSEMENT_NAME],
    "/store/trade-in"
  );

  const updatedParams = { ...queryParams, email: userEmail };

  const handleSignOut = () => {
    const baseURL = `${t("urls.store_trade_in_password")}`;
    logoutHandler(baseURL, updatedParams);
  };

  return (
    <StoreFormContainer
      // submitParams={updatedParams}
      secondaryParams={updatedParams}
    >
      <div className="flex flex-col flex-grow h-[calc(100vh-81px)]">
        <picture className="absolute inset-0 w-full left-0 right-0 top-0 bottom-0 h-full">
          <source
            type="image/webp"
            srcSet={DollarWEBP}
            className="h-full object-cover w-full"
          />
          <img
            src={Dollar}
            alt="A dollar sign made out of LEGO bricks"
            className="h-full object-cover w-full"
          />
        </picture>
        <div className="absolute inset-x-0 bottom-0 flex flex-col items-center justify-center py-12 bg-white bg-opacity-80">
          <h1 className="text-6xl font-bold pb-large leading-none text-primary">
            Thanks, {takeback ? takeback.consumer.firstName : "Customer"}!
          </h1>
          <span className="text-2xl text-secondary">
            {updatedParams.takebackDisbursement === "cash" ? (
              <span>
                You’ll soon receive an email with details on how to receive your
                ${calculateEstimate(updatedParams.w).payment} reward!
              </span>
            ) : (
              <span>
                Your LEGO<sup>®</sup> e-Gift Card will be emailed to you
                shortly.
              </span>
            )}
          </span>
          <Button
            variant="secondary"
            onClick={handleSignOut}
            className="mt-6 bg-white py-x-large px-x-large"
          >
            Done
          </Button>
        </div>
      </div>
    </StoreFormContainer>
  );
}
