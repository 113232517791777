import { useWarehouseContext } from "./useWarehouseContext";
import { CapturePackagePhotos } from "./CapturePackagePhotos";
import { CheckContents } from "./CheckContents";
import { ControlWeightCapture } from "./ControlWeightCapture";
import { ContentWeightCapture } from "./ContentWeightCapture";
import { CaptureContentPhotos } from "./CaptureContentPhotos";
import { SortContents } from "./SortContents";
import { AssembledWeightCapture } from "./AssembledWeightCapture";
import { ConformingWeightCapture } from "./ConformingWeightCapture";
import { Summary } from "./Summary";

export const useStepsWithData = () => {
  const {
    packagePictures,
    controlWeight,
    contentWeight,
    contentPictures,
    sortedPictures,
    assembledWeight,
    conformingWeight,
    nonConformingWeight,
  } = useWarehouseContext();

  return [
    {
      StepComponent: CapturePackagePhotos,
      details: "Take a photo of the package",
      value: packagePictures,
      completed: packagePictures.length > 0,
    },
    {
      StepComponent: CheckContents,
      completed: true,
    },
    {
      StepComponent: ControlWeightCapture,
      details: "Record the control weight",
      value: controlWeight,
      completed: controlWeight !== "",
    },
    {
      StepComponent: ContentWeightCapture,
      details: "Record the contents weight",
      value: contentWeight,
      completed: contentWeight !== "",
    },
    {
      StepComponent: CaptureContentPhotos,
      details: "Take a photo of the contents",
      value: contentPictures,
      completed: contentPictures.length > 0,
    },
    {
      StepComponent: SortContents,
      details: "Take a photo of the sorted contents",
      value: sortedPictures,
      completed: Object.keys(sortedPictures).length > 0,
    },
    {
      StepComponent: AssembledWeightCapture,
      details: "Record the assembled weight",
      value: assembledWeight,
      completed: assembledWeight !== "",
    },
    {
      StepComponent: ConformingWeightCapture,
      details: "Record the conforming weight",
      value: conformingWeight,
      completed: conformingWeight !== "",
    },
    {
      StepComponent: Summary,
      linkText: "Review",
      title: "Negative Non-conforming Weight",
      details: "Review all recorded weights",
      completed: true,
      error: nonConformingWeight < 0,
    },
  ];
};
