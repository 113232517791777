import ReactWebcam from "react-webcam";
import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { useWindowSize } from "../useWindowSize";
import { ReactComponent as SpinnerSvg } from "../svgs/tail-spin.svg";

export const Webcam = forwardRef((props, ref) => {
  const webcamRef = useRef(null);

  useImperativeHandle(ref, () => ({
    getScreenshot() {
      if (webcamRef.current) {
        return webcamRef.current.getScreenshot();
      }
      return null;
    },
  }));
  const { width, height } = useWindowSize();

  return (
    <>
      <div className="absolute inset-0 flex flex-col items-center justify-center bg-white">
        <SpinnerSvg className="w-8 h-8" />
        <p className="mt-4 text-[21px] font-semibold text-[#282F44]">
          Initializing Camera
        </p>
      </div>
      <div className="fixed">
        <ReactWebcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          width={`${width}`}
          height={`${height}`}
          screenshotQuality={1}
          forceScreenshotSourceSize={true}
          videoConstraints={{
            width: { ideal: 3840 },
            height: { ideal: 2160 },
            aspectRatio: { ideal: 3840 / 2160 },
            facingMode: { ideal: "environment" },
          }}
        />
      </div>
    </>
  );
});
