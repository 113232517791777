import { Header } from "components/header";

export const Impressum = () => {
  return (
    <div className="min-h-screen w-screen">
      <Header classNames="bg-yellow border-none" />
      <div className="w-full px-8 container mx-auto mt-xxx-large md:mt-xxxx-large">
        <h1 className="text-6xl mb-xx-large">Impressum</h1>
        <p className="text-secondary text-base mb-large">
          <strong>Unternehmen:</strong> Pentatonic Ltd
        </p>
        <p className="text-secondary text-base mb-large">
          <strong>Adresse:</strong> Unit 3, 27 Downham Road, London, United
          Kingdom, N1 5AA
        </p>
        <p className="text-secondary text-base mb-large">
          <strong>Telefonnummer:</strong> +442079539503
        </p>
        <p className="text-secondary text-base mb-large">
          <strong>E-Mail-Adresse:</strong> support@legobricktakeback.com{" "}
        </p>
        <p className="text-secondary text-base mb-large">
          <strong>Vertretungsberechtigte Personen:</strong> Jamie Hall,
          Geschäftsführer
        </p>
        <p className="text-secondary text-base mb-large">
          <strong>Handelsregisternummer:</strong> 11621602 (Vereinigtes
          Königreich)
        </p>
        <p className="text-secondary text-base mb-large">
          <strong>Umsatzsteuer-Identifikationsnummer:</strong> GB307379003
        </p>
      </div>
    </div>
  );
};
