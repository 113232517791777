import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import checkin from "assets/checkin.svg";
import calendar from "assets/calendar.svg";
import tradeins from "assets/tradeins.svg";
import checkin_selected from "assets/checkin_selected.svg";
import tradeins_selected from "assets/tradeins_selected.svg";
import createshipment from "assets/createshipment.svg";
import createshipment_selected from "assets/createshipment_selected.svg";
// import signup_store from "assets/signup_store.svg";
// import signup_store_selected from "assets/signup_store_selected.svg";

const BottomBar = ({ showScheduleTab }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(location.pathname);

  // const locationId = localStorage.getItem("locationId");
  // const storeState = locationId === "30043" ? "atlanta" : "denver";

  const tabs = [
    // {
    //   name: "Sign Up",
    //   icon: signup_store,
    //   icon_selected: signup_store_selected,
    //   href: t("urls.signup_store_qr_entrance").replace(":l", storeState),
    // },
    {
      name: "Create Shipment",
      icon: createshipment,
      icon_selected: createshipment_selected,
      href: t("urls.store_create_shipment"),
    },
    {
      name: "Trade Ins",
      icon: tradeins,
      icon_selected: tradeins_selected,
      href: t("urls.store_trade_ins"),
    },
    {
      name: "Check In",
      icon: checkin,
      icon_selected: checkin_selected,
      href: t("urls.store_check_in"),
    },
    // { name: "Create Trade In", icon: createtradein, icon_selected: createtradein, href: "/store/trade-in" },
  ];

  if (showScheduleTab) {
    tabs.push({
      name: "Schedule",
      icon: calendar,
      icon_selected: calendar,
      href: t("urls.store_schedule"),
    });
  }

  const handleTabClick = (tabHref) => {
    setActiveTab(tabHref);
  };

  return (
    <div className="flex py-large bg-white w-full z-10 dark:bg-gray-800 fixed bottom-0 justify-center items-center border-t border-gray-300 dark:border-gray-700">
      <div className="flex flex-row items-center justify-between px-4 w-full">
        {tabs.map((tab, index) => (
          <Link key={index} to={tab.href} className="flex-1">
            <div
              className={`flex flex-col items-center cursor-pointer ${
                activeTab === tab.href
                  ? "text-blue"
                  : "text-tertiary hover:text-blue dark:text-gray-400 dark:hover:text-gray-50"
              }`}
              onClick={() => handleTabClick(tab.href)}
            >
              <img
                src={activeTab === tab.href ? tab.icon_selected : tab.icon}
                alt={tab.name}
                className={`sm:h-12 sm:w-12 h-8 w-8`}
              />
              <span className="sm:text-sm text-xs mt-x-small">{tab.name}</span>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

BottomBar.propTypes = {
  showScheduleTab: PropTypes.bool,
};

BottomBar.defaultProps = {
  showScheduleTab: false,
};

export default BottomBar;
