import { useState, forwardRef, useMemo, useEffect } from "react";
import { NumberFormatParse } from "./NumberFormatParser";
import { debounce } from "@mui/material";

export const LocaleWeightInput = forwardRef(
  (
    {
      locale,
      defaultValue,
      onChange,
      separatorError = "",
      setSeparatorError = () => {},
      ...inputProps
    },
    ref
  ) => {
    const [numberFormatter, numberParser] = useMemo(
      () => [new Intl.NumberFormat(locale), new NumberFormatParse(locale)],
      [locale]
    );

    const [inputValue, setInputValue] = useState(
      defaultValue ? numberFormatter.format(defaultValue) : defaultValue
    );

    const debouncedFormatInput = useMemo(
      () =>
        debounce((maybeFormattedValue) => {
          // Skip formatting if separatorError is true
          if (separatorError) {
            return;
          }
          const numberValue = numberParser.parse(maybeFormattedValue);
          const newFormattedValue = numberFormatter.format(numberValue);

          if (
            !Number.isNaN(numberValue) &&
            newFormattedValue !== maybeFormattedValue
          ) {
            if (!maybeFormattedValue.startsWith(newFormattedValue)) {
              // the input wasn't formatted properly, so set the properly formatted value now
              setInputValue(newFormattedValue);
            }
          }
        }, 600),
      [numberFormatter, numberParser, separatorError]
    );

    const handleValueChange = (e) => {
      const { value } = e.target;

      const [integerPart] = value.split(locale === "de-DE" ? "," : ".");
      if (integerPart.length > 3) {
        return;
      }

      if (locale === "de-DE" && value.includes(".")) {
        setSeparatorError("de-DE");
        setInputValue(value); // Update input with the current value
        onChange(NaN); // Trigger invalid number state
        return; // Stop further parsing
      } else if (locale === "en-US" && value.includes(",")) {
        setSeparatorError("en-US");
        setInputValue(value); // Update input with the current value
        onChange(NaN); // Trigger invalid number state
        return; // Stop further parsing
      } else {
        setSeparatorError(""); // Clear comma error if no comma
      }

      if (value === "") {
        setInputValue("");
        onChange("");
      } else if (/[1234567890.,\-e]$/.test(value)) {
        const numberValue = numberParser.parse(value);

        const isNaN = Number.isNaN(numberValue);
        if (!isNaN) {
          onChange(numberValue);
        } else {
          onChange(NaN);
        }

        setInputValue(value);
      } else if (inputValue.startsWith(value)) {
        // deleting invalid entry
        setInputValue(value);
      }
    };

    useEffect(() => {
      if (inputValue) {
        debouncedFormatInput(inputValue);
      }
    }, [debouncedFormatInput, inputValue]);

    return (
      <input
        ref={ref}
        {...inputProps}
        type="text"
        value={inputValue}
        onChange={handleValueChange}
      />
    );
  }
);
