export const TradeInIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.5 3.5C4.39543 3.5 3.5 4.39543 3.5 5.5V18.5C3.5 19.6046 4.39543 20.5 5.5 20.5H18.5C19.6046 20.5 20.5 19.6046 20.5 18.5V5.5C20.5 4.39543 19.6046 3.5 18.5 3.5H5.5ZM5.5 5C5.22386 5 5 5.22386 5 5.5V18.5C5 18.7761 5.22386 19 5.5 19H18.5C18.7761 19 19 18.7761 19 18.5V5.5C19 5.22386 18.7761 5 18.5 5H5.5ZM16 13H8V17H16V13ZM14.5 15.5H9.5V14.5H14.5V15.5ZM11.25 7.5H8.75V8.5H8V12H16V8.5H15.25V7.5H12.75V8.5H11.25V7.5Z"
      fill="black"
    />
  </svg>
);
