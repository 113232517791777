import { Button } from "components/button";

export default function FormContainer({
  children,
  submitLabel,
  onSubmit = () => {},
  submitParams,
  submitHref,
  onSecondary = () => {},
  secondaryLabel,
  secondaryHref,
  secondaryParams,
  submitDisabled = false,
  secondaryDisabled = false,
  submitTracking,
  onSubmitIfDisabled = () => {},
}) {
  return (
    <form
      className="h-full flex flex-col justify-between"
      onSubmit={(e) => {
        e.preventDefault();

        if (submitLabel) {
          onSubmit();
        }
      }}
    >
      <div className="w-auto lg:self-center xl:w-[660px] p-large md:p-xxx-large flex flex-col gap-x-large md:gap-xx-large">
        {children}
      </div>
      <div
        className={`flex justify-between px-large md:px-xxx-large pb-large md:pb-xx-large pt-large md:pt-0${!secondaryLabel ? " ml-auto" : ""}`}
      >
        {secondaryLabel && (
          <Button
            variant="secondary"
            href={secondaryHref}
            type="button"
            disabled={secondaryDisabled}
            onClick={onSecondary}
            queryParams={secondaryParams}
          >
            {secondaryLabel}
          </Button>
        )}
        <div
          className="inline-block"
          onClick={submitDisabled ? onSubmitIfDisabled : null}
        >
          {submitLabel && (
            <Button
              disabled={submitDisabled}
              href={submitHref}
              type="submit"
              queryParams={submitParams}
              tracking={submitTracking}
            >
              {submitLabel}
            </Button>
          )}
        </div>
      </div>
    </form>
  );
}
