import React from "react";
import KioskStartButton from "../components/start-button";

export default function WeightConfirmation({
  navigate,
  removeLastLoggedWeight,
}) {
  return (
    <div className="flex flex-col items-center justify-between w-full h-full relative">
      <div className="flex flex-col gap-[2vh]">
        <p className="text-[4.5vh] text-center max-w-[50vh] text-white mt-[3vh] px-[4vh] leading-[130%]">
          Hold on
          <br />a moment...
        </p>
        <p className="text-[3vh] font-normal max-w-5xl w-full text-center text-white">
          The next step will clear the scale of your LEGO® bricks. Once it’s in
          motion, there’s no getting them back.
        </p>
        <p className="text-[3vh] font-normal max-w-6xl w-full text-center text-white">
          You sure you’re ready?
        </p>
      </div>
      <div className="w-full flex flex-row justify-between px-[4vh] gap-[48px]">
        <KioskStartButton
          className={`relative z-50 w-1/2`}
          onClick={() => {
            removeLastLoggedWeight();
            navigate("scale");
          }}
        >
          Reject
        </KioskStartButton>
        <KioskStartButton
          className="relative z-50 w-1/2 font-bold"
          onClick={() => navigate("clear-tray")}
          icon="check"
          iconColor="black"
          color="yellow"
        >
          Accept
        </KioskStartButton>
      </div>
    </div>
  );
}
