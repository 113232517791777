import "material-symbols/outlined.css";

const RadioGroup = ({
  options,
  name,
  selectedOption,
  onChange,
  className,
  disabledOptions = [],
  infoMessage,
}) => {
  return (
    <div className={className}>
      {options.map((option, index) => {
        const isDisabled = disabledOptions.includes(option.value);

        return (
          <label
            key={option.value}
            className={`${isDisabled ? "" : "cursor-pointer"} flex border border-gray-300 px-x-large py-large ${index === 0 ? "rounded-t-2xl" : ""} ${index === options.length - 1 ? "rounded-b-2xl" : ""} ${index !== 0 ? "-mt-[1px]" : ""} ${selectedOption === option.value ? "!border-blue-300 relative bg-blue-300/25 z-10" : ""}`}
          >
            <div className="flex flex-col">
              <div className="flex flex-row">
                <div className="grid place-items-center cursor-pointer">
                  <input
                    type="radio"
                    name={name}
                    value={option.value}
                    checked={selectedOption === option.value}
                    onChange={() => onChange(option.value)}
                    className={`appearance-none w-6 h-6 border rounded-full shrink-0 col-start-1 row-start-1 bg-white  ${isDisabled ? "opacity-30" : ""} ${selectedOption === option.value ? "border-blue" : "border-primary"}`}
                    disabled={isDisabled}
                  />
                  {selectedOption === option.value && (
                    <div className="w-3 h-3 rounded-full bg-blue col-start-1 row-start-1" />
                  )}
                </div>
                <div className="flex items-center">
                  {option.icon && (
                    <span
                      className={`material-symbols-outlined ml-4 max-w-[16px] ${isDisabled ? "opacity-30" : ""} ${option.iconstyle} ${selectedOption === option.value ? "text-primary" : "text-tertiary"}`}
                    >
                      {option.icon}
                    </span>
                  )}
                  <div className="flex flex-col ml-x-large gap-x-small">
                    {option.title && (
                      <p
                        className={
                          selectedOption === option.value ? "font-medium" : ""
                        }
                      >
                        {option.title}
                      </p>
                    )}
                    {option.subtitle && (
                      <p className="text-tertiary text-sm">{option.subtitle}</p>
                    )}
                  </div>
                </div>
              </div>
              {isDisabled && infoMessage && (
                <div className="ml-10 mt-3">{infoMessage}</div>
              )}
            </div>
          </label>
        );
      })}
    </div>
  );
};

export default RadioGroup;
