import { useMutation } from "react-query";
import { useContext } from "react";
import AuthContext from "contexts/AuthContext";

const { REACT_APP_API } = process.env;

const updateUser = async ({ accessToken, payload }) => {
  const response = await fetch(`${REACT_APP_API}/user`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(payload),
  });

  if (!response.ok) {
    throw new Error("Failed to update user");
  }

  return response.json();
};

export const useUpdateUser = ({
  onSuccess = () => ({}),
  onError = () => ({}),
} = {}) => {
  const { authTokens, user } = useContext(AuthContext);
  const accessToken = authTokens?.access_token;

  return useMutation({
    mutationKey: ["update-user", accessToken],
    mutationFn: (payload) =>
      updateUser({ accessToken, payload: { email: user.email, ...payload } }),
    onSuccess: (data) => {
      onSuccess(data);
    },
    onError: (error) => {
      alert("Please try again");
      onError(error);
    },
  });
};
