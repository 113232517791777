import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Header } from "components/header";
import AuthContext from "contexts/AuthContext";
import { Button } from "components/button";
// import { LOCALE_NAME, STATE_NAME, COUNTRY_NAME } from "consts/query-params";
// import { useCurrentLocation } from "lib/utils";
import { Link } from "react-router-dom";
import ScrollToTop from "components/scroll-to-top";
import { Footer } from "components/footer/footer";

// import ImpressumFooter from "components/footer-impressum";

export default function CookiePolicyPage() {
  const { i18n, t } = useTranslation();
  const { user } = useContext(AuthContext);

  // const { location: currentLocation } = useCurrentLocation();

  // const CtaButton = ({ className }) => {
  //   return (
  //     <Button
  //       href="/trade-in-weight"
  //       queryParams={{
  //         [LOCALE_NAME]: currentLocation.locale,
  //         [STATE_NAME]: currentLocation.state,
  //         [COUNTRY_NAME]: currentLocation.country,
  //       }}
  //       className={className}
  //     >
  //       {t("landing.button_start_new")}
  //     </Button>
  //   );
  // };

  return (
    <div className="min-h-screen w-screen">
      <Header classNames="bg-yellow border-none">
        <div className="flex gap-large">
          {/* <CtaButton className="hidden md:block" /> */}
          <Button
            href={user ? t("urls.my_trade_ins") : t("urls.login")}
            variant="secondary"
          >
            {user
              ? t("landing.button_my_trade_ins")
              : t("landing.button_sign_in")}
          </Button>
        </div>
      </Header>
      <ScrollToTop />
      <div className="w-full px-8 container mx-auto mt-16 mb-16">
        {i18n.language === "en-US" ? (
          <div className="max-w-prose mx-auto">
            <h1 className="text-3xl font-bold mb-8">Cookie Policy</h1>

            <p className="mb-6">
              <em>Last updated: 15th July 2024</em>
            </p>

            <h3 className="text-2xl font-semibold mb-4">
              We use cookies on LEGOBrickTakeBack.com
            </h3>
            <p className="mb-6">
              Cookies are small data files that your browser sets on your
              computer or device. A cookie itself does not contain or collect
              information. However, when it is read by a server via a web
              browser, it can help a website deliver a more user-friendly
              service – for example, remembering previous site interactions or
              account details.
            </p>

            <p className="mb-6">
              Both we and our third-party tracking partners use browser storage,
              app storage, cookies, pixels, beacons, scripts and tags to operate
              our web application, and provided you have given consent, to
              analyze your use and market to you. We may receive reports on
              these from our third-party tracking partners on an individual and
              aggregate basis, and those partners may combine that information
              with other information they have collected from you.
            </p>
            <p className="mb-6">
              Through the use of cookies we can provide the users of this web
              application with more user-friendly services that would not be
              possible without the cookie setting. Cookies enable us to optimize
              the use of our websites for the users. An example is a cookie of a
              shopping cart in an online shop that remembers the articles a
              customer has placed in the virtual shopping cart.
            </p>

            <p className="mb-6">
              The data subject may, at any time, prevent the setting of cookies
              through our web application by setting the browser preferences
              accordingly, and may thus permanently avoid the setting of
              cookies. Furthermore, cookies already set may be deleted at any
              time in the browser preferences or by other software programs. If
              the user deactivates cookies in his internet browser, not all
              functions of our web application may be entirely usable.
            </p>
            <p className="mb-6">
              The data collected through cookies may also be shared with the
              LEGO Group and its affiliates to help manage and improve the
              LEGO® Brick Take Back Program, and to provide you with enhanced
              web application experience, and better customer support and
              services.
            </p>

            <h3 className="text-2xl font-semibold mb-4">
              The cookies we use fall into three categories.
            </h3>
            <ul className="list-disc pl-6 mb-6">
              <li>
                Necessary Cookies: Necessary cookies are required to enable
                technical site functionality and to provide the services
                explicitly requested by you. This includes as an example
                services such as your selected country and language, keeping you
                logged in, providing security and fraud prevention, having your
                digital shopping bag and wish list items stored while you
                browse, remembering volume settings, and you getting access to
                secure areas of the website. This category of cookies cannot be
                disabled and does not require a consent.
              </li>
              <li>
                Analytic Cookies: These cookies are optional and collect
                information about how visitors use and experience our website in
                order to optimize design, operations, efficiency and to improve
                your user experience. We only ask for consent to place these
                types of cookies on sites that are intended for young people who
                are 16 years or older or adults.
              </li>
              <li>
                Third-Party Marketing Cookies: These cookies are optional and
                allow third parties to collect cookies allowing us to make
                targeted marketing/ads of our products and services on other
                websites, apps and on social media. If you allow this you will
                allow the listed third parties to set cookies tracking your
                interests and behavior including which products and services you
                are interested in, or purchase, on this and other websites,
                social media, apps and devices. Be aware that these third
                parties are either joint or independent data controllers of the
                personal data tracked via the cookies and they will use the data
                for their own purposes. These are the third parties we allow to
                place cookies on our websites and in our apps. Read about how
                these third parties process your personal information here:
              </li>
            </ul>

            <p className="mb-2">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://myadcenter.google.com/home?hl=en"
                className="text-blue"
              >
                Google
              </a>
            </p>
            <p className="mb-2">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/login/?next=https%3A%2F%2Fwww.facebook.com%2Fbusiness%2Fgdpr"
                className="text-blue"
              >
                Facebook
              </a>
            </p>
            <p className="mb-2">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://help.ads.microsoft.com/#apex/ads/en/60212/-1"
                className="text-blue"
              >
                Bing
              </a>
            </p>
            <p className="mb-2">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://help.twitter.com/en/safety-and-security/privacy-controls-for-tailored-ads"
                className="text-blue"
              >
                X (fka Twitter)
              </a>
            </p>
            <p className="mb-6">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://firework.com/legal/privacypolicy/"
                className="text-blue"
              >
                Firework
              </a>
            </p>

            <p className="mb-6">
              We only ask for permission to allow the above third parties to
              place these types of cookies on sites that are intended for young
              people who are 16 years or older or adults.
            </p>

            <h3 className="text-2xl font-semibold mb-4">List of our cookies</h3>
            <p className="mb-6">
              See our{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href={t("urls.cookie_declaration")}
                className="text-blue"
              >
                Cookie Declaration
              </a>{" "}
              listing our different types of cookies – including the third
              parties we share your cookie information with – either to process
              the cookie on our behalf or as separate data controllers.
            </p>

            <h3 className="text-2xl font-semibold mb-4">
              What about your privacy?
            </h3>
            <p className="mb-6">
              Some of the cookies set on LEGO.com will result in processing of
              your personal information.
            </p>

            <ul className="list-disc pl-6 mb-6">
              <li>
                our legitimate interest in preventing fraudulent behavior when
                using our website and its services;
              </li>
              <li>
                our legitimate interest in allowing you to interact with our
                customer service via chat functionality and to remain logged in
                with your LEGO® VIP or LEGO Account;
              </li>
              <li>
                our obligation to secure a safe use and operation of the website
                and the services offered on the website (including measures to
                secure compliance with our legal obligations towards children);
              </li>
              <li>
                our contractual obligation to deliver specific services or
                functionalities specifically requested by the website user.
              </li>
            </ul>
            <p className="mb-6">
              In regard to “analytical” cookies processing personal information,
              this is based on consent.
            </p>
            <p className="mb-6">
              Read how to manage and control what information we collect from
              you in our full{" "}
              <Link
                target="_blank"
                rel="noreferrer"
                to={t("urls.privacy_policy")}
                className="text-blue"
              >
                Privacy Policy
              </Link>
              .
            </p>

            <h3 className="text-2xl font-semibold mb-4">
              What if I do not want cookies:
            </h3>
            <p className="mb-6">
              Necessary cookies are essential for the website and you cannot opt
              out of them, but you can delete necessary cookies after use – see
              how below.
            </p>
            <p className="mb-6">
              For all other cookies, you can always adjust your settings and
              revoke your consent.
            </p>

            <h3 className="text-2xl font-semibold mb-4">
              How to get rid of your cookies:
            </h3>
            <p className="mb-6">
              The cookies are placed on your device. This means that we cannot
              delete them for you. You must do that yourself in the way
              prescribed by your specific device. If you are on a website
              operated by LEGO System A/S, you can also avoid cookies being
              placed by adjusting the browser settings on your device. If you
              are using an app published by LEGO System A/S, please note that
              cookies set in an app work in a different way and you will have to
              control this via your device directly. Real browser cookies are
              set to expire. Similar types of technologies, like local storage
              on your device, do not have expiration built-in but must be
              deleted by you. We have linked to instructions on how to
              adjust/delete cookies on some of the more common devices below.
            </p>

            <h3 className="mb-6">
              Please find examples of guides on how to block cookies and how to
              remove cookies in the various browsers for PCs and Macs:
            </h3>
            <p className="mb-2">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://support.google.com/chrome/answer/95647?hl=en"
                className="text-blue"
              >
                Google Chrome
              </a>
            </p>
            <p className="mb-2">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox"
                className="text-blue"
              >
                Mozilla Firefox
              </a>
            </p>
            <p className="mb-2">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://help.opera.com/en/latest/web-preferences/#cookies"
                className="text-blue"
              >
                Opera
              </a>
            </p>
            <p className="mb-2">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://support.apple.com/en-us/HT201265"
                className="text-blue"
              >
                Safari
              </a>
            </p>
            <p className="mb-2">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://support.microsoft.com/en-us/windows/manage-cookies-in-microsoft-edge-view-allow-block-delete-and-use-168dab11-0753-043d-7c16-ede5947fc64d"
                className="text-blue"
              >
                Microsoft Edge
              </a>
            </p>
            <p className="mb-6">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.samsung.com/uk/support/mobile-devices/using-the-samsung-internet-app/"
                className="text-blue"
              >
                Samsung Browser
              </a>
            </p>
          </div>
        ) : (
          <div className="max-w-prose mx-auto">
            <h1 className="text-3xl font-bold mb-8">Cookie-Richtlinie</h1>

            <p className="mb-6">
              <em>Zuletzt aktualisiert: 15. Juli 2024</em>
            </p>

            <h3 className="text-2xl font-semibold mb-4">
              Wir verwenden Cookies auf LEGOBrickTakeBack.com
            </h3>
            <p className="mb-6">
              Cookies sind kleine Datendateien, die Ihr Browser auf Ihrem
              Computer oder Gerät ablegt. Ein Cookie selbst enthält oder sammelt
              keine Informationen. Wenn es jedoch von einem Server über einen
              Webbrowser gelesen wird, kann es einer Website helfen, einen
              benutzerfreundlicheren Service zu bieten - zum Beispiel, indem es
              sich an frühere Interaktionen auf der Website oder an
              Nutzerkontodaten erinnert.
            </p>

            <h3 className="mb-6">
              Sowohl wir als auch unsere Tracking-Partner verwenden
              Browser-Speicher, App-Speicher, Cookies, Pixel, Beacons, Skripte
              und Tags, um unsere Webanwendung zu betreiben und, sofern Sie Ihr
              Einverständnis gegeben haben, um Ihre Nutzung zu analysieren und
              Ihnen Angebote zu machen. Wir können von unseren Tracking-Partnern
              Berichte über diese Daten auf individueller und aggregierter Basis
              erhalten, und diese Partner können diese Informationen mit anderen
              Informationen, die sie von Ihnen gesammelt haben, kombinieren.
            </h3>
            <p className="mb-6">
              Durch den Einsatz von Cookies können wir den Nutzern dieser
              Webanwendung benutzerfreundlichere Dienste anbieten, die ohne die
              Cookie-Einstellung nicht möglich wären. Cookies ermöglichen es
              uns, die Nutzung unserer Websites für die Nutzer zu optimieren.
              Ein Beispiel ist das Cookie eines Warenkorbs in einem Online-Shop,
              das sich die Artikel merkt, die ein Kunde in den virtuellen
              Einkaufswagen gelegt hat.
            </p>

            <p className="mb-6">
              Die betroffene Person kann das Setzen von Cookies durch unsere
              Webanwendung jederzeit durch eine entsprechende Einstellung des
              Browsers verhindern und so das Setzen von Cookies dauerhaft
              vermeiden. Ferner können bereits gesetzte Cookies jederzeit in den
              Browser-Einstellungen oder durch andere Softwareprogramme gelöscht
              werden. Deaktiviert der Nutzer Cookies in seinem Internetbrowser,
              sind unter Umständen nicht alle Funktionen unserer Webanwendung
              vollumfänglich nutzbar.
            </p>
            <p className="mb-6">
              Die durch Cookies gesammelten Daten können auch an die LEGO Gruppe
              und ihre Tochtergesellschaften weitergegeben werden, um das LEGO®
              Brick Take Back-Programm zu verwalten und zu verbessern und um
              Ihnen eine verbesserte Webanwendung sowie einen besseren
              Kundendienst und Service zu bieten.
            </p>

            <h3 className="text-2xl font-semibold mb-4">
              Die von uns verwendeten Cookies fallen in drei Kategorien:
            </h3>
            <ul className="list-disc pl-6 mb-6">
              <li>
                Erforderliche Cookies: Erforderliche Cookies sind notwendig, um
                die technische Funktionalität der Website zu ermöglichen und die
                von Ihnen ausdrücklich angeforderten Dienste bereitzustellen.
                Dazu gehören zum Beispiel Dienste wie die Auswahl Ihres Landes
                und Ihrer Sprache, Ihre Anmeldung, Sicherheit und
                Betrugsprävention, die Speicherung Ihrer digitalen
                Einkaufstasche und Ihrer Wunschliste während des Surfens, die
                Speicherung Ihrer Lautstärkeeinstellungen und Ihr Zugang zu
                sicheren Bereichen der Website. Diese Kategorie von Cookies kann
                nicht deaktiviert werden und erfordert keine Zustimmung.
              </li>
              <li>
                Analytische Cookies: Diese Cookies sind optional und sammeln
                Informationen darüber, wie Besucher unsere Website nutzen und
                erleben, um das Design, den Betrieb und die Effizienz zu
                optimieren und Ihre Benutzererfahrung zu verbessern. Wir bitten
                nur um die Zustimmung, diese Art von Cookies auf Websites zu
                platzieren, die für Jugendliche ab 16 Jahren oder Erwachsene
                bestimmt sind.
              </li>
              <li>
                Marketing-Cookies von Drittanbietern: Diese Cookies sind
                optional und ermöglichen es Dritten, Cookies zu sammeln, die es
                uns ermöglichen, gezieltes Marketing/Werbung für unsere Produkte
                und Dienstleistungen auf anderen Websites, Apps und in sozialen
                Medien zu machen. Wenn Sie dies zulassen, erlauben Sie den
                aufgeführten Dritten, Cookies zu setzen, die Ihre Interessen und
                Ihr Verhalten verfolgen, einschließlich der Produkte und
                Dienstleistungen, an denen Sie interessiert sind oder die Sie
                auf dieser und anderen Websites, sozialen Medien, Apps und
                Geräten kaufen. Seien Sie sich bewusst, dass diese Dritten
                entweder gemeinsame oder unabhängige Datenverantwortliche für
                die über die Cookies verfolgten personenbezogenen Daten sind und
                die Daten für ihre eigenen Zwecke verwenden werden. Dies sind
                die Drittanbieter, denen wir erlauben, Cookies auf unseren
                Websites und in unseren Apps zu platzieren. Lesen Sie hier, wie
                diese Drittanbieter Ihre personenbezogenen Daten verarbeiten:
              </li>
            </ul>

            <p className="mb-2">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://myadcenter.google.com/home?hl=en"
                className="text-blue"
              >
                Google
              </a>
            </p>
            <p className="mb-2">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/login/?next=https%3A%2F%2Fwww.facebook.com%2Fbusiness%2Fgdpr"
                className="text-blue"
              >
                Facebook
              </a>
            </p>
            <p className="mb-2">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://help.ads.microsoft.com/#apex/ads/en/60212/-1"
                className="text-blue"
              >
                Bing
              </a>
            </p>
            <p className="mb-2">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://help.twitter.com/en/safety-and-security/privacy-controls-for-tailored-ads"
                className="text-blue"
              >
                X (ehemals Twitter)
              </a>
            </p>
            <p className="mb-6">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://firework.com/legal/privacypolicy/"
                className="text-blue"
              >
                Firework
              </a>
            </p>

            <p className="mb-6">
              Wir bitten nur um die Erlaubnis, den oben genannten Drittanbietern
              zu erlauben, diese Arten von Cookies auf Websites zu platzieren,
              die für Jugendliche ab 16 Jahren oder Erwachsene bestimmt sind.
            </p>

            <h3 className="text-2xl font-semibold mb-4">
              Liste unserer Cookies
            </h3>
            <p className="mb-6">
              Siehe unsere{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href={t("urls.cookie_declaration")}
                className="text-blue"
              >
                Cookie-Erklärung
              </a>
              , in der unsere verschiedenen Arten von Cookies aufgelistet sind -
              einschließlich der Dritten, an die wir Ihre Cookie-Informationen
              weitergeben - entweder zur Verarbeitung des Cookies in unserem
              Namen oder als separate Datenverantwortliche.
            </p>
            <h3 className="text-2xl font-semibold mb-4">
              Wie steht es um Ihre Privatsphäre?
            </h3>
            <p className="mb-6">
              Einige der auf LEGO.com gesetzten Cookies führen zur Verarbeitung
              Ihrer persönlichen Daten.
            </p>

            <ul className="list-disc pl-6 mb-6">
              <li>
                unser berechtigtes Interesse, betrügerisches Verhalten bei der
                Nutzung unserer Website und ihrer Dienste zu verhindern;
              </li>
              <li>
                unser berechtigtes Interesse, Ihnen die Interaktion mit unserem
                Kundendienst über die Chat-Funktion zu ermöglichen und mit Ihrem
                LEGO® VIP oder LEGO Konto eingeloggt zu bleiben;
              </li>
              <li>
                unsere Verpflichtung, eine sichere Nutzung und einen sicheren
                Betrieb der Website und der auf der Website angebotenen Dienste
                zu gewährleisten (einschließlich Maßnahmen zur Sicherstellung
                der Einhaltung unserer gesetzlichen Verpflichtungen gegenüber
                Kindern);
              </li>
              <li>
                unsere vertragliche Verpflichtung, bestimmte Dienste oder
                Funktionen bereitzustellen, die vom Nutzer der Website
                ausdrücklich angefordert wurden.
              </li>
            </ul>

            <p className="mb-6">
              Was die "analytischen" Cookies betrifft, die personenbezogene
              Daten verarbeiten, so beruht dies auf einer Einwilligung.
            </p>
            <p className="mb-6">
              Lesen Sie hier in unserer vollständigen{" "}
              <Link
                target="_blank"
                rel="noreferrer"
                to={t("urls.privacy_policy")}
                className="text-blue"
              >
                Datenschutzerklärung
              </Link>
              , wie Sie die von uns gesammelten Daten verwalten und
              kontrollieren können.
            </p>

            <h3 className="text-2xl font-semibold mb-4">
              Was ist, wenn ich keine Cookies möchte?
            </h3>
            <p className="mb-6">
              Notwendige Cookies sind für die Website unverzichtbar, und Sie
              können sie nicht ablehnen, aber Sie können notwendige Cookies nach
              ihrer Verwendung löschen - siehe unten.
            </p>
            <p className="mb-6">
              Für alle anderen Cookies können Sie jederzeit Ihre Einstellungen
              anpassen und Ihre Zustimmung widerrufen.
            </p>

            <h3 className="text-2xl font-semibold mb-4">
              Wie Sie Ihre Cookies loswerden können:
            </h3>
            <p className="mb-6">
              Die Cookies werden auf Ihrem Gerät gespeichert. Das bedeutet, dass
              wir sie nicht für Sie löschen können. Das müssen Sie selbst tun,
              und zwar so, wie es Ihr Gerät vorschreibt. Wenn Sie sich auf einer
              von LEGO System A/S betriebenen Website befinden, können Sie das
              Setzen von Cookies auch verhindern, indem Sie die
              Browsereinstellungen Ihres Geräts anpassen. Wenn Sie eine von LEGO
              System A/S herausgegebene App verwenden, beachten Sie bitte, dass
              die in einer App gesetzten Cookies auf andere Weise funktionieren
              und Sie dies direkt über Ihr Gerät steuern müssen. Echte
              Browser-Cookies sind so eingestellt, dass sie ablaufen. Ähnliche
              Arten von Technologien, wie die lokale Speicherung auf Ihrem
              Gerät, haben keinen eingebauten Ablauf, sondern müssen von Ihnen
              gelöscht werden. Nachstehend finden Sie Anleitungen zur
              Einstellung/Löschung von Cookies auf einigen der gängigsten
              Geräte.
            </p>

            <h3 className="mb-6">
              Hier finden Sie Beispiele für Anleitungen zum Blockieren von
              Cookies und zum Entfernen von Cookies in den verschiedenen
              Browsern für PCs und Macs:
            </h3>
            <p className="mb-2">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://support.google.com/chrome/answer/95647?hl=en"
                className="text-blue"
              >
                Google Chrome
              </a>
            </p>
            <p className="mb-2">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen"
                className="text-blue"
              >
                Mozilla Firefox
              </a>
            </p>
            <p className="mb-2">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://help.opera.com/de/latest/web-preferences/#cookies"
                className="text-blue"
              >
                Opera
              </a>
            </p>
            <p className="mb-2">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://support.apple.com/en-us/HT201265"
                className="text-blue"
              >
                Safari
              </a>
            </p>
            <p className="mb-2">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://support.microsoft.com/en-us/windows/manage-cookies-in-microsoft-edge-view-allow-block-delete-and-use-168dab11-0753-043d-7c16-ede5947fc64d"
                className="text-blue"
              >
                Microsoft Edge
              </a>
            </p>
            <p className="mb-6">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.samsung.com/uk/support/mobile-devices/using-the-samsung-internet-app/"
                className="text-blue"
              >
                Samsung Browser
              </a>
            </p>
          </div>
        )}
      </div>
      <Footer />
      {/* <ImpressumFooter /> */}
    </div>
  );
}
