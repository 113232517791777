import { useWarehouseContext } from "../useWarehouseContext";
import { Header } from "./Header";

export const StepHeader = ({ rightSlot, ...props }) => {
  const { sellerDisplayName, caseId } = useWarehouseContext();

  return (
    <Header {...props}>
      <div className="flex justify-center items-center">
        <div className="flex flex-col items-center">
          <span className="font-bold text-2xl">{sellerDisplayName}</span>
          {caseId && (
            <span className="text-sm text-[#464C64]">ID: {caseId}</span>
          )}
        </div>
        <div className="absolute right-0 pr-4">{rightSlot}</div>
      </div>
    </Header>
  );
};
