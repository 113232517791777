import React from "react";
import { ReactComponent as TickSvg } from "../svgs/tick.svg";
import { ReactComponent as CameraSvg } from "../svgs/camera.svg";

import { Header } from "./Header";
import { Footer } from "./Footer";
import { FullscreenModal } from "./FullscreenModal";

export const PhotoConfirmation = ({
  dispatch,
  title,
  photo,
  onCancel,
  onConfirm,
}) => {
  return (
    <FullscreenModal>
      <div className="fixed h-full w-full">
        <img className="w-full" src={photo} alt={title} />
      </div>
      <Header dispatch={dispatch} opacity="100%" noExit>
        <div className="flex justify-center items-center">
          <span className="font-bold text-2xl">{title}</span>
        </div>
      </Header>

      <Footer>
        <div className="flex gap-4 flex-col p-8">
          <div className="w-full flex flex-col">
            <h1 className="text-6xl text-[#282F44]">
              Are the items in this photo clearly visible? (Fully in frame and
              not blurry).
            </h1>
          </div>
          <div className="grid grid-cols-2 w-full gap-8">
            <button
              className="rounded-lg font-normal border-2 border-[#DAE3F9] py-4 w-full m-0"
              onClick={() => {
                onCancel();
              }}
            >
              <div className="flex flex-row justify-center items-center gap-2">
                <CameraSvg className="fill-[#282F44]" />
                <span>No, Take Again</span>
              </div>
            </button>
            <button
              className="rounded-lg bg-[#173991] text-white hover:bg-blue-700 font-normal py-4 w-full m-0"
              onClick={() => {
                onConfirm();
              }}
            >
              <div className="flex flex-row justify-center items-center gap-2">
                <TickSvg style={{ width: "1rem" }} className="fill-[#00CFA8]" />
                <span>Yes</span>
              </div>
            </button>
          </div>
        </div>
      </Footer>
    </FullscreenModal>
  );
};
