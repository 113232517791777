import React, { useState, useEffect, useRef } from "react";
import Checklist from "components/checklist";
import "material-symbols/outlined.css";
import { Trans, useTranslation } from "react-i18next";
import FormContainer from "components/form-container";
import ProgressBar from "components/progress-bar";
import { Link, useLocation, useOutletContext } from "react-router-dom";
import { getQueryParams, useCurrentLocation } from "lib/utils";
import { useRestartIfAbsent } from "hooks/useRestartIfAbsent";
import { TAKEBACK_METHOD_NAME } from "consts/query-params";
import InputValidation from "components/input-validation";
import TermsText from "components/terms-text";

export default function TermsPage() {
  const [isBottom, setIsBottom] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [othersChecked, setOthersChecked] = useState(false);
  const [warning, setWarning] = useState(false);
  const containerRef = useRef(null);

  //translation
  const { t } = useTranslation();

  // Get query params and restart flow if absent
  const location = useLocation();
  const queryParams = getQueryParams(location.search);
  useRestartIfAbsent(
    queryParams,
    ["w", TAKEBACK_METHOD_NAME, "p"],
    t("urls.trade_in_weight")
  );

  //set right side visual
  const { location: currentLocation } = useCurrentLocation();
  const { setSidebarVisual } = useOutletContext();
  useEffect(() => {
    setSidebarVisual(currentLocation.locale === "de-DE" ? "euro" : "dollar");
  }, [setSidebarVisual, currentLocation]);

  const checklistItems1 = [
    { name: "item1", label: t("trade-in.terms.check1") },
  ];

  const checklistItems2 = [
    { name: "item2", label: t("trade-in.terms.check2") },
    { name: "item3", label: t("trade-in.terms.check3") },
    { name: "item4", label: t("trade-in.terms.check4") },
  ];

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
    if (scrollTop + clientHeight >= scrollHeight - 5) {
      setIsBottom(true);
      setWarning(false);
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    container.addEventListener("scroll", handleScroll);
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <FormContainer
      submitLabel={t("ui.button_continue")}
      submitHref={t("urls.trade_in_summary")}
      submitParams={queryParams}
      submitDisabled={!othersChecked || !termsChecked}
      secondaryLabel={t("ui.button_back")}
      secondaryParams={queryParams}
      secondaryHref={t("urls.trade_in_payout_method")}
      onSubmitIfDisabled={
        !isBottom ? () => setWarning(true) : () => setWarning(false)
      }
    >
      <ProgressBar step={6} />
      <div className="flex flex-col gap-regular">
        <h2 className="text-primary text-6xl">{t("trade-in.terms.title")}</h2>
        <p className="text-tertiary text-2xl">
          {t("trade-in.terms.description")}
        </p>
      </div>
      <div className="">
        <div className="mb-regular text-2xl font-semibold">
          {t("trade-in.terms.title")}
        </div>
        <div
          ref={containerRef}
          className={`overflow-y-scroll h-[300px] w-full border bg-gray-300/10 text-sm p-4 rounded-xl${
            warning ? " border-red" : " border-gray-400"
          }`}
        >
          <TermsText scroll={false} />
        </div>
        {warning && (
          <InputValidation errorMessage={t("trade-in.terms.warning")} />
        )}
        <div
          onClick={!isBottom ? () => setWarning(true) : () => setWarning(false)}
        >
          <Checklist
            checklistItems={checklistItems1}
            setAllChecked={setTermsChecked}
            className="mt-8"
            disabled={!isBottom}
          />
        </div>
        <Checklist
          checklistItems={checklistItems2}
          setAllChecked={setOthersChecked}
          className="mt-8"
        />
        <div className="mt-8">
          <Trans
            i18nKey="trade-in.terms.add1"
            components={{
              1: (
                <Link
                  to={t("urls.terms_and_conditions")}
                  className="text-blue"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
            }}
          />
        </div>
      </div>
    </FormContainer>
  );
}
