import { useMutation } from "react-query";
import { useContext } from "react";
import AuthContext from "../../../../contexts/AuthContext";

const { REACT_APP_API } = process.env;

export const useCreateLogIssue = (options) => {
  const { authTokens } = useContext(AuthContext);
  const accessToken = authTokens?.access_token;

  const createLogIssue = async ({
    caseId,
    issueDescription,
    screenShotData,
  }) => {
    const response = await fetch(`${REACT_APP_API}/issue/log-issue`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        caseId,
        description: issueDescription,
        screenshots: [screenShotData],
      }),
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Failed to log issue: ${errorText}`);
    } else {
      return await response.json();
    }
  };

  return useMutation(createLogIssue, options);
};
