export const Step2 = ({
  controlContainerWeight,
  setControlContainerWeight,
  handlePreviousStep,
  handleNextStep,
}) => {
  return (
    <div className="space-y-4">
      <h2 className="text-xl font-semibold">Step 2: Weigh Control Container</h2>
      <label className="block">
        Control Container Weight (kg):
        <input
          type="number"
          value={controlContainerWeight}
          onChange={(e) => setControlContainerWeight(e.target.value)}
          className={`mt-1 block w-full p-2 border rounded`}
        />
      </label>
      <div className="space-x-4">
        <button
          onClick={handlePreviousStep}
          className="bg-gray-500 hover:bg-gray-700 font-bold py-2 px-4 rounded"
        >
          Previous
        </button>
        <button
          onClick={handleNextStep}
          className="bg-green text-white hover:bg-green-700 font-bold py-2 px-4 rounded"
        >
          Next
        </button>
      </div>
    </div>
  );
};
