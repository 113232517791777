import { Fragment, useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useCurrentLocation } from "lib/utils";
import { Outlet } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import LocaleModal from "components/locale-modal";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import RestrictedModal from "components/restricted-modal";

export default function ModalProvider() {
  const [showLocationModal, setShowLocationModal] = useState(false);

  const { t } = useTranslation();

  // Get current path name
  const location = useLocation();
  const currentPath = location.pathname;

  // Get location settings
  const { location: currentLocation, error } = useCurrentLocation();
  const isRestrictedLocationCookie = Cookies.get("isRestrictedLocation");
  // restricted modal logic
  const [isRestrictedLocation, setIsRestrictedLocation] = useState(
    Boolean(
      isRestrictedLocationCookie &&
        !localStorage.getItem(`acknowledgedRestrictedLocation`)
    )
  );
  const handleRestrictedLocationAcknowledged = () => {
    localStorage.setItem(
      `acknowledgedRestrictedLocation`,
      isRestrictedLocationCookie
    );
    setIsRestrictedLocation(false);
  };

  // locale modal logic
  useEffect(() => {
    //show modal only on specific routes
    const showModalRoutes = [
      "/en-US",
      "/de-DE",
      t("urls.preparation_checklist"),
      t("urls.terms_and_conditions"),
      t("urls.privacy_policy"),
      t("urls.cookie_policy"),
      t("urls.cookie_declaration"),
    ];

    if (
      error &&
      !currentLocation.state &&
      currentLocation.locale === "en-US" &&
      showModalRoutes.includes(currentPath) &&
      (localStorage.getItem(`acknowledgedRestrictedLocation`) ||
        !isRestrictedLocation)
    ) {
      setShowLocationModal(true);
    }
  }, [
    setShowLocationModal,
    error,
    currentLocation,
    currentPath,
    t,
    isRestrictedLocation,
  ]);

  return (
    <Fragment>
      <AnimatePresence>
        {isRestrictedLocation && (
          <RestrictedModal
            setModalOpen={handleRestrictedLocationAcknowledged}
          />
        )}
        {showLocationModal && (
          <LocaleModal setModalOpen={setShowLocationModal} />
        )}
      </AnimatePresence>
      <Outlet
        context={{
          setShowLocationModal,
        }}
      />
    </Fragment>
  );
}
