import React, { useState } from "react";
import { Button } from "components/button";
import { AnimatePresence, motion } from "framer-motion";
import { ReactComponent as WarningSvg } from "../svgs/warning.svg";
import { CircularProgress } from "@mui/material";
import { useWarehouseCheckPin } from "../../../../api/use-warehouse-check-pin";

export const PinModal = ({
  isOpen,
  onClose,
  onPinSuccess,
  title,
  body,
  primaryText,
  secondaryText,
}) => {
  const [pin, setPin] = useState("");
  const [error, setError] = useState("");

  const { mutateAsync: checkPin, isLoading } = useWarehouseCheckPin();

  if (!isOpen) return null;

  const handlePinChange = (e) => {
    setPin(e.target.value);
  };

  const onConfirm = async () => {
    setError("");

    try {
      await checkPin(pin);

      onPinSuccess();
      onClose();
    } catch (e) {
      setError(e.message);
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <motion.div
            className="fixed inset-0 bg-[#0B1C47] z-30"
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.6 }}
            exit={{ opacity: 0 }}
          />
          <motion.div
            className="fixed inset-0 flex items-center justify-center z-40"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div className="bg-white p-6 rounded-xl shadow-lg w-full max-w-lg max-h-[95vh] overflow-auto z-20">
              <div className="flex flex-row items-center gap-2 mb-2">
                <WarningSvg
                  style={{
                    width: "32px",
                    height: "32px",
                    color: "#D8A924",
                  }}
                />
                <h2 className="text-[24px] text-[#282F44] text-left font-bold text-balance">
                  {title}
                </h2>
              </div>
              <div className="mb-6 text-[#464C64] text-[18px] text-left font-light flex max-h-[70vh]">
                {body}
              </div>
              <input
                placeholder="PIN"
                className={`w-full outline-blue placeholder:text-[#C6CCE8] p-6 border ${error ? "mb-[8px]" : "mb-[24px]"} rounded-xl font-space-mono text-[18px] text-[#282F44] font-normal h-[72px] ${error ? "border-[#C12960] bg-[#F9DAE3]" : "border-[#C6CCE8]"}`}
                onChange={handlePinChange}
                value={pin}
              />
              {error && (
                <div className="text-[#C12960] text-[18px] mb-[24px] font-normal align-top">
                  {error}
                </div>
              )}
              <div className="flex justify-between gap-6">
                <Button
                  onClick={onClose}
                  variant="secondary"
                  className="w-full border-2 rounded-xl text-[21px] font-normal h-[72px] p-6 border-[#DAE3F9]"
                >
                  {secondaryText}
                </Button>
                <Button
                  onClick={onConfirm}
                  className="w-full rounded-xl text-[21px] p-6 bg-[#173991]"
                  disabled={isLoading}
                >
                  {isLoading ? <CircularProgress size={24} /> : primaryText}
                </Button>
              </div>
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};
