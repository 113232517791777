import { validateTrackingNumber } from "../receive/receive";

export const useTrackingNumberValidator = () => {
  return (trackingNumber) => ({
    upsTracking:
      validateTrackingNumber(trackingNumber, "UPS") && trackingNumber,
    dhlTracking:
      validateTrackingNumber(trackingNumber, "DHL") && trackingNumber,
    shippingCode:
      validateTrackingNumber(trackingNumber, "SHIP") && trackingNumber,
  });
};
