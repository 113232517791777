import React from "react";
import { Header } from "components/header";
import { Outlet } from "react-router-dom";
import ImpressumFooter from "components/footer-impressum";

const PasswordLayout = () => {
  return (
    <div className="min-h-screen flex flex-col justify-between">
      <div>
        <Header />
        <div className="px-8">
          <Outlet />
        </div>
      </div>
      <ImpressumFooter />
    </div>
  );
};

export default PasswordLayout;
