import React, { useState } from "react";
import { createPortal } from "react-dom";

import { ReactComponent as CameraSvg } from "../svgs/camera.svg";
import { PhotoConfirmation } from "./PhotoConfirmation";

export const TakePhotoButton = ({
  dispatch,
  onPhotoConfirmed,
  capturePhoto,
  title,
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [photo, setPhoto] = useState("");

  const handleCapturePackage = () => {
    const picture = capturePhoto();

    setPhoto(picture);
    setShowConfirmation(true);
  };

  const onConfirm = () => {
    onPhotoConfirmed(photo);
    setShowConfirmation(false);
  };

  const onCancel = () => {
    setShowConfirmation(false);
  };

  return (
    <>
      <button
        className="rounded-lg text-[#00CFA8] py-4 w-full m-0 border-2 border-[#00CFA8]"
        onClick={() => {
          handleCapturePackage();
        }}
      >
        <div className="flex flex-row justify-center items-center gap-2">
          <CameraSvg className="fill-[#00CFA8]" />
          <span>Take Photo</span>
        </div>
      </button>
      {showConfirmation &&
        createPortal(
          <PhotoConfirmation
            dispatch={dispatch}
            title={title}
            photo={photo}
            onConfirm={onConfirm}
            onCancel={onCancel}
          />,
          document.body
        )}
    </>
  );
};
