import React, { Fragment, useEffect } from "react";
import { useLocation, useOutletContext, Link } from "react-router-dom";
import "material-symbols/outlined.css";
import { Button } from "components/button";
import { getQueryParams } from "lib/utils";
import { useRestartIfAbsent } from "hooks/useRestartIfAbsent";
import { useTranslation, Trans } from "react-i18next";
import { TAKEBACK_METHOD_NAME, COUNTRY_NAME } from "consts/query-params";
import storeLocations from "data/stores.json";

export default function NextStepsPage() {
  const { t } = useTranslation();

  // Update right side visual
  const { setSidebarVisual } = useOutletContext();
  useEffect(() => {
    setSidebarVisual("face");
  }, [setSidebarVisual]);

  // Get query params and restart flow if absent
  const location = useLocation();
  const queryParams = getQueryParams(location.search);
  useRestartIfAbsent(
    queryParams,
    ["w", TAKEBACK_METHOD_NAME, "p"],
    t("urls.trade_in_weight")
  );

  const matchingAddress = storeLocations.find(
    (location) => location.value === queryParams[TAKEBACK_METHOD_NAME]
  )?.address;
  const addressString = matchingAddress
    ? matchingAddress
    : t("trade-in.next_steps.address_not_found");

  return (
    <div className="h-full flex flex-col justify-between">
      <div className="w-auto lg:self-center xl:w-[660px] p-large md:p-xxx-large flex flex-col gap-x-large md:gap-xx-large">
        <div className="flex flex-col gap-regular">
          <h2 className="text-primary text-6xl">
            {t("trade-in.next_steps.title_thanks")}
          </h2>
          <p className="text-tertiary text-2xl">
            {t("trade-in.next_steps.confirmation_message")}
          </p>
        </div>
        <div className="flex flex-col gap-6 max-w-xl mt-4">
          <h3 className="text-2xl font-bold">
            {t("trade-in.next_steps.next_steps_title")}
          </h3>
          <div className="flex">
            <div className="bg-red text-white rounded-full w-6 h-6 flex items-center justify-center shrink-0 mr-4">
              1
            </div>
            <div>
              <Trans
                i18nKey={
                  queryParams[TAKEBACK_METHOD_NAME] === "mail"
                    ? "trade-in.next_steps.step_collect_mail"
                    : "trade-in.next_steps.step_collect_instore"
                }
                components={{
                  1: (
                    <Link
                      to={t("urls.preparation_checklist")}
                      className="cursor-pointer text-blue"
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  ),
                }}
              />
            </div>
          </div>
          {queryParams[TAKEBACK_METHOD_NAME] === "mail" ? (
            <Fragment>
              <div className="flex">
                <div className="bg-red text-white rounded-full w-6 h-6 flex items-center justify-center shrink-0 mr-4">
                  2
                </div>
                <div>
                  <Trans
                    i18nKey={"trade-in.next_steps.step_mail_2"}
                    components={{
                      1: <b />,
                      2: (
                        <Link
                          to={`${t("urls.trade_in_weight")}?locale=${queryParams.locale}&state=${queryParams.state}&country=${queryParams.country}`}
                          className="cursor-pointer text-blue"
                          target="_blank"
                          rel="noopener noreferrer"
                        />
                      ),
                    }}
                  />
                </div>
              </div>
              <div className="flex">
                <div className="bg-red text-white rounded-full w-6 h-6 flex items-center justify-center shrink-0 mr-4">
                  3
                </div>
                <div className="flex flex-col gap-4">
                  <div>{t("trade-in.next_steps.step_mail_3")}</div>
                  <div>
                    <a
                      href={
                        queryParams[COUNTRY_NAME] === "DE"
                          ? "https://www.dhl.de/de/privatkunden/dhl-standorte-finden.html"
                          : "https://www.theupsstore.com/tools/find-a-store"
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-[#016db8]"
                    >
                      {t("trade-in.next_steps.step_mail_find_ups")}
                    </a>
                  </div>
                </div>
              </div>
              <div className="flex">
                <div className="bg-red text-white rounded-full w-6 h-6 flex items-center justify-center shrink-0 mr-4">
                  4
                </div>
                <div className="flex flex-col gap-4">
                  <div>
                    <Trans
                      i18nKey="trade-in.next_steps.step_mail_4"
                      components={{
                        1: (
                          <Link
                            to={`${t("urls.my_trade_ins")}?locale=${queryParams.locale}&state=${queryParams.state}`}
                            className="text-blue"
                          />
                        ),
                      }}
                    />
                  </div>
                </div>
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <div className="flex">
                <div className="bg-red text-white rounded-full w-6 h-6 flex items-center justify-center shrink-0 mr-4">
                  2
                </div>
                <div className="flex flex-col gap-4">
                  <div>{t("trade-in.next_steps.step_store_2")}</div>
                  <div>
                    {t("trade-in.next_steps.step_store_location", {
                      addressString,
                    })}
                  </div>
                </div>
              </div>
              <div className="flex">
                <div className="bg-red text-white rounded-full w-6 h-6 flex items-center justify-center shrink-0 mr-4">
                  3
                </div>
                <div>{t("trade-in.next_steps.step_store_3")}</div>
              </div>
            </Fragment>
          )}
        </div>
        <Button
          href={t("urls.my_trade_ins")}
          search={`?locale=${queryParams.locale}&state=${queryParams.state}`}
          className="w-fit"
        >
          {t("ui.button_go_to_trade_ins")}
        </Button>
      </div>
    </div>
  );
}
