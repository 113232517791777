import React from "react";
import QRCode from "react-qr-code";
import { useNavigate } from "react-router-dom";
import { TAKEBACK_METHOD_NAME, COUNTRY_NAME } from "consts/query-params";
import { useTranslation } from "react-i18next";
import CloseButton from "components/close-button";

export default function InStoreQR() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  let queryParam = "";
  // let locationName = "";

  const locationId = localStorage.getItem("locationId");
  const storeState = locationId === "30043" ? "in_store_ga" : "in_store_co";

  if (storeState === "in_store_ga") {
    queryParam = `?${TAKEBACK_METHOD_NAME}=in_store_ga&${COUNTRY_NAME}=US`;
    // locationName = "Georgia";
  } else if (storeState === "in_store_co") {
    queryParam = `?${TAKEBACK_METHOD_NAME}=in_store_co&${COUNTRY_NAME}=US`;
    // locationName = "Colorado";
  } else {
    navigate(0);
  }

  const qrCodeValue = `legobricktakeback.com${t(
    "urls.signup_store_start"
  )}${queryParam}`;

  return (
    <div className="flex flex-col items-center justify-between h-[calc(100vh-81px)] p-6">
      <CloseButton onClick={() => navigate(-1)} />
      <div className="bg-white flex flex-col my-auto max-w-md items-center">
        <QRCode value={qrCodeValue} size={250} />
        <h1 className="text-6xl font-semibold text-center mt-12">
          Customer registration
        </h1>
        <span className="text-center text-lg text-[#2c2c2c] mt-4">
          Ask the customer to scan the code above with their smartphone to start
          the registration / sign-in process.
        </span>
      </div>
    </div>
  );
}
