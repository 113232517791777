import React, { useContext, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { getQueryParams } from "lib/utils";
import FormContainer from "components/form-container";
import ProgressBar from "components/progress-bar";
import { useTranslation, Trans } from "react-i18next";
import AuthContext from "contexts/AuthContext";
import { createSearchParams } from "react-router-dom";
import { Button } from "components/button";

export default function InStoreEmailConfirm() {
  const location = useLocation();
  const queryParams = getQueryParams(location.search);
  const { t } = useTranslation();
  const emailInput = localStorage.getItem("quickRegEmailInput");
  const { resendEmailVerificationHandler } = useContext(AuthContext);
  const resendSuccessful = useRef(false);
  const [isResending, setIsResending] = useState(false);

  const handleResendEmail = () => {
    setIsResending(true);
    const params = queryParams
      ? createSearchParams(queryParams).toString()
      : "";
    resendEmailVerificationHandler(
      {
        email: emailInput,
        redirectTo: `${window.location.origin}${t(
          `urls.signup_store_sign_in`
        )}?${params}`,
      },
      {
        onSuccess: () => {
          setIsResending(false);
          resendSuccessful.current = true;
        },
      },
      {
        onError: (error) => {
          console.error("Failed to resend verification email", error.message);
        },
      }
    );
  };

  return (
    <FormContainer
      submitDisabled={!queryParams.e}
      submitParams={queryParams}
      secondaryLabel="Back"
      secondaryHref={`${t("urls.signup_store_email")}`}
    >
      <ProgressBar step={2} stepsNum={3} />
      <div className="flex flex-col gap-2">
        <h2 className="font-semibold text-6xl max-w-lg">
          {t("trade-in.verification.title")}
        </h2>
        <div className="w-full flex flex-col">
          <div>
            <span className="text-tertiary max-w-lg text-base leading-normal">
              <Trans
                i18nKey="trade-in.verification.description"
                components={{ email: emailInput }}
              />
            </span>
          </div>
          <Button
            variant="default"
            type="button"
            className={`w-full ${
              isResending && "opacity-50 hover:bg-blue"
            } md:w-1/2 mx-auto md:ml-0 mt-8`}
            onClick={handleResendEmail}
          >
            <div className="flex flex-row items-center">
              {isResending ? (
                <div className="h-6 w-6 animate-spin mr-4 rounded-full border-b-2 border-t-2 border-blue-500"></div>
              ) : null}
              <span>{t("trade-in.verification.resend")}</span>
            </div>
          </Button>
        </div>
        {resendSuccessful.current && (
          <div className="w-full mt-10 flex flex-col bg-[#C0DFC3] border-l-4 px-4 py-3 border-[#00963E] rounded-md">
            <h2 className="font-semibold text-2xl max-w-lg">
              {t("trade-in.verification.resend-title")}
            </h2>
            <span className="max-w-lg text-base font-light leading-normal">
              <Trans
                i18nKey="trade-in.verification.resend-description"
                components={{ email: emailInput }}
              />
            </span>
          </div>
        )}
      </div>
    </FormContainer>
  );
}
