import { Header } from "./Header";

import { ReactComponent as BinSVG } from "../svgs/bin.svg";
import { ReactComponent as CloseSvg } from "../svgs/close.svg";
import React from "react";
import { FullscreenModal } from "./FullscreenModal";

export const ViewBucketPhoto = ({
  dispatch,
  title,
  photo,
  onDelete,
  onClose,
}) => {
  return (
    <FullscreenModal>
      <div className="fixed h-full w-full">
        <img className="h-full w-full" src={photo} alt={title} />
      </div>
      <Header dispatch={dispatch} opacity="100%" noExit>
        <div className="relative flex w-full">
          <div className="flex justify-items-end w-full">
            <button onClick={() => onClose()} type="button">
              <CloseSvg className="w-6 h-6 fill-[#282F44]" alt="Close" />
            </button>
          </div>
          <div className="flex justify-center items-center w-full">
            <span className="font-bold text-2xl">{title}</span>
          </div>
          <div className="flex justify-end w-full">
            <button onClick={() => onDelete()} type="button">
              <BinSVG
                style={{ width: "1.5rem" }}
                className="w-12 h-12 fill-[#282F44]"
                alt="Delete"
              />
            </button>
          </div>
        </div>
      </Header>
    </FullscreenModal>
  );
};
