import AuthContext from "contexts/AuthContext";
import { useContext, useEffect, useState } from "react";

const { REACT_APP_API } = process.env;

const fetchUserExists = async ({ email, setConfig }) => {
  setConfig((prev) => ({ ...prev, isLoading: true }));

  try {
    const response = await fetch(`${REACT_APP_API}/user/exists/${email}`);

    setConfig((prev) => ({ ...prev, exists: response.ok }));
  } catch (error) {
    console.error(error);
    setConfig((prev) => ({ ...prev, exists: false }));
  }

  setConfig((prev) => ({ ...prev, isLoading: false }));
};

export const useUserExists = ({ email, skip }) => {
  const [config, setConfig] = useState({ isLoading: false });
  const { authTokens } = useContext(AuthContext);

  useEffect(() => {
    if (!skip) {
      fetchUserExists({
        email,
        accessToken: authTokens?.access_token,
        setConfig,
      });
    }
  }, [email, skip, authTokens?.access_token]);

  useEffect(() => {
    setConfig((prev) => ({ ...prev, exists: undefined }));
  }, [email]);

  return config;
};
