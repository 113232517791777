import React, { useCallback, useState } from "react";
import { useWarehouseContext } from "../useWarehouseContext";
import { AnimatePresence, motion } from "framer-motion";
import { Button } from "components/button";
import { ReactComponent as WarningSvg } from "../svgs/warning.svg";

export const RejectButton = ({
  dispatch,
  text = "Reject",
  reason,
  className = "rounded-lg text-red py-4 w-full m-0 border-2 border-red",
}) => {
  const {
    updateTakeback,
    clearTakeback,
    rejectedContentsPictures,
    confirmWithPin,
  } = useWarehouseContext();
  const [showModal, setShowModal] = useState(false);

  const missingData =
    !Boolean(reason) || rejectedContentsPictures?.length === 0;

  const incompleteSteps = [
    {
      title: "Missing Reason",
      details: "Please provide a reason for the rejection.",
      complete: Boolean(reason),
    },
    {
      title: "Missing Photos",
      details: "Please take a photo of the rejected contents.",
      complete: rejectedContentsPictures?.length > 0,
    },
  ];

  const onReject = useCallback(() => {
    setShowModal(false);
    const doReject = async () => {
      updateTakeback({ isRejected: true, rejectReason: reason });

      clearTakeback();

      dispatch({
        type: "show-rejection",
      });
    };

    return missingData ? confirmWithPin(doReject) : doReject();
  }, [
    clearTakeback,
    confirmWithPin,
    dispatch,
    missingData,
    reason,
    updateTakeback,
  ]);

  const handleMissingData = () => {
    setShowModal(true);
  };

  return (
    <>
      <button
        className={className}
        disabled={false}
        onClick={() => {
          missingData ? handleMissingData() : onReject();
        }}
      >
        {text}
      </button>
      <AnimatePresence>
        {showModal && (
          <>
            <motion.div
              className="fixed inset-0 bg-[#0B1C47] z-30"
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.6 }}
              exit={{ opacity: 0 }}
            />
            <motion.div
              className="fixed inset-0 flex items-center justify-center z-40"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <div className="bg-[#EFF6FF] p-10 rounded-xl shadow-lg w-full max-w-[60rem] max-h-[95vh] min-w-[45vw] overflow-auto z-20">
                <div className="flex flex-col justify-center items-center ">
                  <div className="flex flex-row items-center gap-2 mb-4">
                    <h1 className="font-semibold text-3xl max-w-lg">
                      Processing Issues
                    </h1>
                  </div>
                  <p className="text-xl font-medium text-[#464C64]">
                    Please review all issues before proceeding, or enter a pin
                    to finish anyway.
                  </p>
                </div>
                {/* the incomplete steps list */}
                {incompleteSteps.length > 0 && (
                  <div className="my-14">
                    <ul className="w-full flex flex-col gap-4 mt-x-large">
                      {incompleteSteps
                        .filter((step) => !step.complete)
                        .map(({ title, details }, index) => {
                          return (
                            <li
                              key={`incomplete-step-${index}`}
                              className="flex flex-row items-center justify-center text-xl pb-4 border-b border-b-[#DAE3F9]"
                            >
                              <WarningSvg style={{ width: "4rem" }} />
                              <span className="flex flex-col gap-1 justify-center w-full ml-6">
                                <b className="text-[#282F44] text-[16px]">
                                  {title}
                                </b>
                                <p className="text-sm text-[#464C64] text-[14px]">
                                  {details}
                                </p>
                              </span>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                )}

                <div className="flex justify-between gap-6">
                  <Button
                    onClick={() => {
                      setShowModal(false);
                    }}
                    variant="secondary"
                    className="w-full border-2 rounded-xl text-[21px] font-normal h-[72px] p-6 border-[#DAE3F9]"
                  >
                    Close
                  </Button>
                  <Button
                    onClick={onReject}
                    className="w-full rounded-xl text-[21px] p-6 bg-[#173991]"
                  >
                    Finish
                  </Button>
                </div>
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </>
  );
};
