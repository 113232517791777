import { useState, useEffect } from "react";
import WeightEstimation from "components/weight-estimation";
import RadioGroup from "components/radio-group";
import FormContainer from "components/form-container";
import { getQueryParams, localizeDecimalSeparator } from "lib/utils";
import { calculateEstimate } from "lib/calculateEstimate";
import { useOutletContext, useLocation } from "react-router-dom";
import ProgressBar from "components/progress-bar";
import { useRestartIfAbsent } from "hooks/useRestartIfAbsent";
import { useCurrentLocation } from "lib/utils";
import { useTranslation } from "react-i18next";
import { TAKEBACK_METHOD_NAME } from "consts/query-params";
import useWhen from "hooks/useWhen";
import { PAYMENT_ARGS, END_OF_PILOT_ARGS } from "consts/feature-config";

export default function MethodPage() {
  // translation
  const { t, i18n } = useTranslation();

  // Get query params and restart flow if absent
  const location = useLocation();
  const queryParams = getQueryParams(location.search);
  useRestartIfAbsent(queryParams, ["w"], t("urls.trade_in_weight"));

  // Initialise input with query param
  const [selectedOption, setSelectedOption] = useState(
    queryParams[TAKEBACK_METHOD_NAME] || null
  );

  // Generate updated params
  const updatedParams = {
    ...queryParams,
    [TAKEBACK_METHOD_NAME]: selectedOption,
  };

  // Radio options
  const radioOptions = [
    {
      value: "mail",
      title: t("trade-in.method.option_mail_title"),
      subtitle: t("trade-in.method.option_mail_description"),
      icon: "package_2",
      iconstyle: "scale-125",
    },
    {
      value: `in_store_co`,
      title: t("trade-in.method.option_colorado-mills_title"),
      subtitle: t("trade-in.method.option_colorado-mills_description"),
      icon: "storefront",
      iconstyle: "scale-110",
    },
    {
      value: `in_store_ga`,
      title: t("trade-in.method.option_lawrenceville_title"),
      subtitle: t("trade-in.method.option_lawrenceville_description"),
      icon: "storefront",
      iconstyle: "scale-110",
    },
  ];

  // Filter radio options based on location methods
  let selectedRadioOptions = radioOptions;
  const { location: currentLocation } = useCurrentLocation();

  if (currentLocation) {
    selectedRadioOptions = radioOptions.filter((option) =>
      currentLocation?.takebackMethod?.includes(option.value)
    );
  }

  const estimate = calculateEstimate(queryParams.w, i18n.language.slice(-2));

  const parsedWeight = parseFloat(queryParams.w).toFixed(1);
  const weightEstimate =
    currentLocation?.locale !== "en-US"
      ? parsedWeight.toString().replace(".", ",")
      : parsedWeight;

  // update right side visual
  const { setSidebarVisual } = useOutletContext();

  useEffect(() => {
    if (selectedOption === "mail") {
      setSidebarVisual("mail");
    } else if (
      selectedOption === `in_store_co` ||
      selectedOption === `in_store_ga`
    ) {
      setSidebarVisual("store");
    } else if (currentLocation.locale === "en-US") {
      setSidebarVisual("dollar");
    } else {
      setSidebarVisual("euro");
    }
  }, [selectedOption, setSidebarVisual, currentLocation]);

  //only show cash payment in appropriate environments
  const showPayment = useWhen(PAYMENT_ARGS);

  const useRevisedLandingPage = useWhen(END_OF_PILOT_ARGS);

  const endOfPilotInfo = (
    <div className="mx-auto lg:max-w-6xl ">
      <div className="w-full h-full overflow-hidden relative rounded-md">
        <div className="h-full w-1 bg-blue absolute top-0 left-0"></div>
        <div className="flex flex-row justify-start bg-blue-300">
          <span
            className={`material-symbols-outlined scale-100 text-blue align-center block p-4 pt-4.5 pr-0`}
          >
            info
          </span>
          <div className="flex flex-col p-3 pl-2 bg-blue-300 w-full">
            <div className="font-semibold">
              Sorry, the In-Store trade-in option for this pilot ended November
              17th 2024.
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <FormContainer
      submitLabel={t("ui.button_continue")}
      submitHref={`${t("urls.trade_in_checklist")}`}
      submitParams={updatedParams}
      submitDisabled={!selectedOption}
      submitTracking="Trade-in method chosen"
      secondaryLabel={t("ui.button_back")}
      secondaryHref={`${t("urls.trade_in_weight")}`}
      secondaryParams={updatedParams}
    >
      <ProgressBar step={2} />
      <h2 className="font-semibold text-6xl max-w-lg">
        {t("trade-in.method.title")}
      </h2>
      <WeightEstimation
        title={t("ui.estimate_part1")}
        className="mt-4"
        singleValue={
          !showPayment
            ? localizeDecimalSeparator(estimate.gift, currentLocation)
            : null
        }
        range={
          showPayment
            ? [
                localizeDecimalSeparator(estimate.payment, currentLocation),
                localizeDecimalSeparator(estimate.gift, currentLocation),
                currentLocation,
              ]
            : null
        }
        animate
        locale={currentLocation?.locale}
        description={t("ui.estimate_part2", {
          weight: weightEstimate,
          unit: t("scale.weight"),
        })}
      />
      <RadioGroup
        options={selectedRadioOptions}
        name="custom-radio"
        selectedOption={selectedOption}
        onChange={setSelectedOption}
        disabledOptions={
          useRevisedLandingPage ? ["in_store_ga", "in_store_co"] : []
        }
        className="mt-2"
        infoMessage={useRevisedLandingPage && endOfPilotInfo}
      />
    </FormContainer>
  );
}
