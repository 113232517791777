import React, { useState, useEffect } from "react";
import KioskStartButton from "../components/start-button";
import DrawnArrow from "../assets/drawnArrow.svg";
import FallingBricks from "../components/falling-bricks";

export default function ClearTray({
  navigate,
  inputWeight,
  removeLastLoggedWeight,
  setInputWeight,
  preventCancel,
  setPreventCancel,
}) {
  //   useEffect(() => {
  //     if (inputWeight === 0) {
  //       navigate("clear-tray");
  //     }
  //   }, [inputWeight, navigate]);

  // const handleCancel = () => {
  //   removeLastLoggedWeight();
  //   navigate("scale");
  // };

  const handleAddTray = () => {
    setInputWeight(0);
    setPreventCancel(true);
    navigate("place-bricks");
  };

  const [showBricks, setShowBricks] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowBricks(false);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      className="flex flex-col items-center justify-between w-full h-full relative"
      //   onClick={() => navigate("clear-tray")}
    >
      <p className="text-[4.8vh] text-center max-w-[50vh] text-white mt-[3vh] px-[4vh] leading-[130%]">
        Clear the scale of LEGO® bricks
      </p>
      <div className="w-full max-h-[1000px] z-10">
        <FallingBricks
          isVisible={showBricks}
          brickCount={50}
          transitionDelay={0}
          isFalling={true}
        />
      </div>
      <div className="flex flex-col items-center w-full relative z-10 gap-[1vh]">
        {/* {inputWeight === 0 && ( */}
        <div className="grid grid-cols-2 w-full gap-[48px] px-[4vh] relative z-10">
          <div className="flex flex-col items-center">
            <p className="text-[40px] text-center text-white">
              Have more bricks
              <br />
              to weigh?
            </p>
            <img src={DrawnArrow} alt="Arrow symbol" />
          </div>
          <div></div>
        </div>
        {/* )} */}
        <div className="grid grid-cols-2 w-full gap-[48px] px-[4vh] relative z-10">
          {/* <KioskStartButton
            className={`relative z-50`}
            onClick={() => handleCancel()}
            disabled={preventCancel}
          >
            Cancel
          </KioskStartButton> */}
          <KioskStartButton
            // disabled={inputWeight !== 0}
            className=""
            onClick={() => {
              handleAddTray();
            }}
            icon="add"
          >
            <div className="text-left">Add more</div>
          </KioskStartButton>
          <KioskStartButton
            className="relative z-50 font-bold"
            onClick={() => {
              setPreventCancel(true);
              navigate("finish");
            }}
            icon="check"
            iconColor="black"
            color="yellow"
            // disabled={inputWeight !== 0}
          >
            Done
          </KioskStartButton>
        </div>
      </div>
    </div>
  );
}
