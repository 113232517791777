import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const FAQAccordion = ({ faqs = null }) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpansion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      {faqs.map((faq) => (
        <Accordion
          key={faq.id}
          expanded={expanded === faq.id}
          onChange={handleExpansion(faq.id)}
          sx={{
            borderBottom: "1px solid #D1D5DB",
            padding: "1rem",
            boxShadow: "none",
            "&:before": { display: "none" },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <p className="text-primary text-2xl">{faq.question}</p>
          </AccordionSummary>
          <AccordionDetails>
            <p>{faq.answer}</p>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};
