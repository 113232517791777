import html2canvas from "html2canvas";

export const useScreenshot = () => {
  const takeScreenShot = async () => {
    const canvas = await html2canvas(document.body);

    const croppedCanvas = document.createElement("canvas");
    const croppedCanvasContext = croppedCanvas.getContext("2d");

    const cropPositionTop = 0;
    const cropPositionLeft = 0;
    const cropWidth = canvas.width;
    const cropHeight = canvas.height;

    croppedCanvas.width = cropWidth;
    croppedCanvas.height = cropHeight;

    croppedCanvasContext.drawImage(canvas, cropPositionLeft, cropPositionTop);

    return croppedCanvas.toDataURL();
  };

  return { takeScreenShot };
};
