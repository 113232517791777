import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

export function useRestartIfAbsent(queryParams, requiredParams) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const missingParam = requiredParams.some((param) => !queryParams[param]);
    if (missingParam) {
      navigate(t("urls.trade_in_weight"));
    }
  }, [queryParams, requiredParams, navigate, t]);
}

useRestartIfAbsent.propTypes = {
  queryParams: PropTypes.object.isRequired,
  requiredParams: PropTypes.arrayOf(PropTypes.string).isRequired,
};
