import React, { useContext } from "react";
import { useNavigate, useBlocker } from "react-router-dom";
import QRCode from "react-qr-code";
import { Button } from "components/button";
import AuthContext from "contexts/AuthContext";
import { useMyTakebacks } from "api/use-takebacks";
import { useTranslation } from "react-i18next";
import ConfirmNavigationModal from "components/confirm-navigation-modal";
import { AnimatePresence } from "framer-motion";

export default function InStoreConfirmation() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { logoutHandler } = useContext(AuthContext);

  const handleSignOut = () => {
    logoutHandler();
    navigate(t("urls.signup_store_start"));
  };

  const currentPath = t("urls.signup_store_confirmation");
  const prevPath = t("urls.signup_store_sign_in");

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      prevPath === nextLocation.pathname &&
      currentPath === currentLocation.pathname
  );

  const { data: takebacks = [] } = useMyTakebacks();

  if (!takebacks.length) {
    return null;
  }

  const { sys_id } = takebacks[takebacks.length - 1];

  return (
    <div className="flex flex-col items-center justify-between h-[calc(100vh-81px)] p-6">
      <AnimatePresence>
        {blocker.state === "blocked" && (
          <ConfirmNavigationModal
            title="Your registration is complete"
            body="Present this QR code to the in-store associate to continue."
            buttonLabel="My Trade-Ins"
            cancelLabel="Close"
            blocker={blocker}
            buttonHref={t("urls.my_trade_ins")}
          />
        )}
      </AnimatePresence>
      <div className="bg-white flex flex-col my-auto items-center text-center px-large">
        <QRCode value={sys_id} size={200} />
        <h2 className="text-primary text-6xl mt-x-large">
          Registration complete
        </h2>
        <span className="text-tertiary text-base mt-regular">
          Present this QR code to the in-store associate to continue.
        </span>
      </div>
      <Button
        onClick={handleSignOut}
        variant="secondary"
        type="button"
        className="w-full bottom-0"
      >
        Sign Out
      </Button>
    </div>
  );
}
