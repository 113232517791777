import { useEffect, useState } from "react";
import FormContainer from "components/form-container";
import ProgressBar from "components/progress-bar";
import { useLocation, useOutletContext } from "react-router-dom";
import { getQueryParams, useCurrentLocation } from "lib/utils";
import { useRestartIfAbsent } from "hooks/useRestartIfAbsent";
import { useTranslation } from "react-i18next";
import { TAKEBACK_METHOD_NAME } from "consts/query-params";

// dummy function to generate appointments
function generateAppointments() {
  const timeSlots = [];
  const startHour = 10;
  const endHour = 18;

  for (let hour = startHour; hour < endHour; hour++) {
    timeSlots.push(`${hour}:00AM`);
    timeSlots.push(`${hour}:30AM`);
  }

  const formattedTimeSlots = timeSlots.map((time) => {
    const [hours, minutes] = time.split(":");
    let formattedTime = `${hours}:${minutes}`;
    if (parseInt(hours, 10) > 11) {
      const hourIn12Hour =
        parseInt(hours, 10) > 12
          ? parseInt(hours, 10) - 12
          : parseInt(hours, 10);
      formattedTime = `${hourIn12Hour}:${minutes.replace("AM", "PM")}`;
    }
    return formattedTime;
  });

  const appointments = {};
  for (let i = 1; i <= 3; i++) {
    const date = new Date();
    date.setDate(date.getDate() + i);
    const formattedDate = date.toLocaleDateString("en-US", {
      weekday: "long",
      month: "long",
      day: "numeric",
    });

    appointments[formattedDate] = formattedTimeSlots.map((time) => ({
      time,
      availability: Math.random() > 0.5 ? "free" : "taken",
    }));
  }

  return appointments;
}

export default function AppointmentPage() {
  const { t } = useTranslation();
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [appointments, setAppointments] = useState(null);
  //set right side visual
  const { location: currentLocation } = useCurrentLocation();
  const { setSidebarVisual } = useOutletContext();
  useEffect(() => {
    setSidebarVisual(currentLocation.locale === "de-DE" ? "euro" : "dollar");
  }, [setSidebarVisual, currentLocation]);

  // Get query params and restart flow if absent
  const location = useLocation();
  const queryParams = getQueryParams(location.search);
  useRestartIfAbsent(
    queryParams,
    ["w", TAKEBACK_METHOD_NAME, "p"],
    t("urls.trade_in_weight")
  );

  useEffect(() => {
    const appointments = generateAppointments();
    setAppointments(appointments);
  }, []);

  return (
    <FormContainer
      submitLabel={t("ui.button_continue")}
      submitHref={t("urls.trade_in_summary")}
      submitParams={queryParams}
      submitDisabled={!selectedAppointment}
      secondaryLabel={t("ui.button_back")}
      secondaryParams={queryParams}
      secondaryHref={t("urls.trade_in_payout_method")}
    >
      <ProgressBar step={5} />
      <div className="flex flex-col gap-regular">
        <h2 className="font-semibold text-6xl max-w-lg">
          {t("trade-in.appointment.title")}
        </h2>
        <p className="text-[#7E7D7D] text-2xl leading-normal font-light">
          {t("trade-in.appointment.description")}
        </p>
        <div className="mt-8 space-y-xx-large">
          {appointments &&
            Object.keys(appointments).map((date) => (
              <div key={date}>
                <h3 className="mb-4">{date}</h3>
                <div className="flex flex-wrap gap-large">
                  {appointments[date].map((slot, index) => (
                    <div
                      key={index}
                      onClick={() =>
                        slot.availability === "free"
                          ? setSelectedAppointment(`${date}-${slot.time}`)
                          : null
                      }
                      className={`w-[100px] px-2 py-1 text-center rounded-md font-light text-sm select-none ${
                        slot.availability === "taken"
                          ? "bg-black-300/20 text-black-300 border border-transparent"
                          : selectedAppointment === `${date}-${slot.time}`
                            ? "bg-blue text-white border border-transparent cursor-pointer"
                            : "bg-white border border-gray-300 cursor-pointer"
                      }`}
                    >
                      {slot.time}
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </div>
      </div>
    </FormContainer>
  );
}
