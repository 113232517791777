import React from "react";
import { Button } from "components/button";
import { useTranslation } from "react-i18next";

export default function PayallButton({ className, setModalOpen }) {
  const { t } = useTranslation();

  return (
    <div className={className}>
      <Button className="w-full" onClick={() => setModalOpen(true)}>
        {t("my_trade_ins.button_payall")}
      </Button>
    </div>
  );
}
