import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import RadioGroup from "components/radio-group";
import StoreFormContainer from "components/store-form-container";
import SingleCheckbox from "components/checkbox";
import { getQueryParams } from "lib/utils";
import { useTranslation } from "react-i18next";
import AuthContext from "contexts/AuthContext";
import { useTakebackById } from "../../api/use-takebacks";
import { Button } from "components/button";
import ConfirmationDialog from "components/confirmation-dialog";

const { REACT_APP_API } = process.env;

const radioOptions1 = [
  {
    value: true,
    title: "Yes",
    iconstyle: "scale-125",
  },
  {
    value: false,
    title: "No",
    iconstyle: "scale-125",
  },
];

const radioOptions2 = [
  {
    value: true,
    title: "Yes",
    iconstyle: "scale-125",
  },
  {
    value: false,
    title: "No",
    iconstyle: "scale-125",
  },
];

export default function CreateTradeIn() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [selectedOption1, setSelectedOption1] = useState(null);
  const [selectedOption2, setSelectedOption2] = useState(null);
  const [reason, setReason] = useState(``);
  const [description, setDescription] = useState(``);
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const { authTokens } = useContext(AuthContext);
  const location = useLocation();
  const queryParams = getQueryParams(location.search);
  const accessToken = authTokens.access_token;
  const openTakeback = useTakebackById({ id: queryParams.id });
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleSubmit = async () => {
    const response = await fetch(
      `${REACT_APP_API}/takeback/${openTakeback.sys_id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          u_condition_acceptable: selectedOption1,
          u_condition_unacceptable_reason: reason,
          u_non_conforming_items: selectedOption2,
          u_non_conforming_items_reason: description,
          u_rejected_items_returned: checked1,
          u_rejected_items_disposed: checked2,
        }),
      }
    );

    if (response.ok) {
      const searchParams = new URLSearchParams(queryParams).toString();
      navigate(`${t("urls.store_trade_in_weight")}?${searchParams}`);
    } else {
      alert("Please try again");
    }
  };

  const isContinueEnabled =
    (selectedOption1 === false &&
      ((reason && checked1) || checked2) &&
      (selectedOption2 === true ? description : true)) ||
    (selectedOption1 === true && selectedOption2 === false) ||
    (selectedOption1 === true && selectedOption2 === true && description);

  const handleCancel = () => {
    setIsDialogOpen(true);
  };

  const confirmCancel = () => {
    setIsDialogOpen(false);
    navigate(t("urls.store_check_in"), { replace: true });
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const showCancelButton =
    selectedOption1 === false || selectedOption2 === true;

  return (
    <StoreFormContainer
      submitLabel="Continue"
      secondaryLabel="Back"
      secondaryHref={t("urls.store_check_in")}
      submitDisabled={!isContinueEnabled}
      onSubmit={handleSubmit}
    >
      <div className="flex flex-col flex-grow items-center py-4">
        <div className="flex flex-col mr-auto mb-6">
          <h1 className="text-6xl font-bold pb-3">
            {t("store.trade-in-acceptance.title")}
          </h1>
          <div className="text-tertiary text-3xl">
            Condition of the LEGO® bricks
          </div>
        </div>
        <div className="bg-white w-full py-4 ">
          <h2 className="max-w-lg">
            {t("store.trade-in-acceptance.acceptable-criteria")}
          </h2>
          <RadioGroup
            options={radioOptions1}
            name="custom-radio"
            selectedOption={selectedOption1}
            onChange={setSelectedOption1}
            className="mt-regular"
          />
          {radioOptions1[1].value === selectedOption1 && (
            <div className="mt-xxx-large">
              <h2 className="text-primary max-w-lg mb-regular">
                Reason<span className="text-red">*</span>
              </h2>
              <textarea
                className="w-full rounded-lg resize-none focus:border-[#0275bf] focus:outline-none flex border border-[#d2d2d2] p-4"
                placeholder=""
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            </div>
          )}
          <h2 className="text-secondary max-w-lg mt-xxx-large mb-regular">
            Are there any items that do not meet the requirements?
          </h2>
          <RadioGroup
            options={radioOptions2}
            name="custom-radio"
            selectedOption={selectedOption2}
            onChange={setSelectedOption2}
            className="mt-regular"
          />
          {radioOptions2[0].value === selectedOption2 && (
            <div>
              <h2 className="text-primary max-w-lg mt-xxx-large mb-regular">
                Description<span className="text-red">*</span>
              </h2>
              <textarea
                className="w-full rounded-lg focus:border-[#0275bf] focus:outline-none flex border border-[#d2d2d2] p-4"
                placeholder=""
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
          )}
          {(radioOptions1[1].value === selectedOption1 ||
            radioOptions2[0].value === selectedOption2) && (
            <div>
              <h2 className="text-secondary mt-xxx-large max-w-lg mb-regular">
                Destination of rejected items<span className="text-red">*</span>
              </h2>
              <div
                className={`cursor-pointer flex border ${
                  !checked1
                    ? "border-[#d2d2d2] bg-none"
                    : "border-[#0275bf] bg-[#eff6ff]"
                } p-4 rounded-t-2xl -mt-[1px] focus:border-[#0275bf] relative z-10`}
              >
                <SingleCheckbox
                  label="Returned to customer"
                  checked={checked1}
                  setChecked={setChecked1}
                />
              </div>
              <div
                className={`cursor-pointer flex border ${
                  !checked2
                    ? "border-[#d2d2d2] bg-none"
                    : "border-[#0275bf] bg-[#eff6ff]"
                } p-4 rounded-b-2xl -mt-[1px] focus:border-[#0275bf] relative z-10`}
              >
                <SingleCheckbox
                  label="Disposal"
                  checked={checked2}
                  setChecked={setChecked2}
                />
              </div>
            </div>
          )}
        </div>
        {showCancelButton && (
          <Button
            onClick={handleCancel}
            className="mt-4 px-4 py-2 rounded-full mr-auto"
          >
            Cancel
          </Button>
        )}
      </div>
      <ConfirmationDialog
        isOpen={isDialogOpen}
        onClose={closeDialog}
        onConfirm={confirmCancel}
        title="Are you sure you want to cancel and close the trade-in?"
        primaryText="Yes, cancel and close the trade-in"
        secondaryText="Do not cancel and continue"
      />
    </StoreFormContainer>
  );
}
