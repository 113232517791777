import React, { useEffect, useRef, useState } from "react";
import { Button } from "components/button";
import { AnimatePresence, motion } from "framer-motion";
import { ReactComponent as CloseSvg } from "../svgs/close.svg";
import { useWarehouseContext } from "../useWarehouseContext";
import { useScreenshot } from "../utils/useScreenshot";
import { useCreateLogIssue } from "../utils/useCreateLogIssue";

export const LogIssueModal = ({ dispatch, isOpen, onClose }) => {
  const [issueDescription, setIssueDescription] = useState("");
  const [screenShotData, setScreenShotData] = useState("");
  const [screenshotReady, setScreenshotReady] = useState(false);
  const screenshotTakenRef = useRef(false);
  const { takeScreenShot } = useScreenshot();
  const { caseId } = useWarehouseContext();
  const [error, setError] = useState("");

  useEffect(() => {
    const handleTakeScreenshot = async () => {
      try {
        const screenshot = await takeScreenShot();
        setScreenShotData(screenshot);
        screenshotTakenRef.current = true;
        setScreenshotReady(true);
      } catch (error) {
        console.error("Error taking screenshot:", error);
        setScreenshotReady(true);
      }
    };

    if (isOpen && !screenshotTakenRef.current) {
      handleTakeScreenshot();
    } else if (!isOpen) {
      setScreenshotReady(false);
      screenshotTakenRef.current = false;
    }
  }, [isOpen, takeScreenShot]);

  const { mutateAsync: createLogIssue } = useCreateLogIssue();

  const handleIssueSubmit = async () => {
    try {
      await createLogIssue({
        caseId,
        issueDescription,
        screenShotData,
      });
      onClose();
      dispatch({
        type: "close-log-issue",
        props: { successMessage: "Successfully logged issue" },
      });
    } catch (error) {
      setError(error.message);
    }
  };

  if (!isOpen) return null;

  return (
    <AnimatePresence>
      {screenshotReady && isOpen && (
        <>
          <motion.div
            className="fixed inset-0 bg-[#0B1C47] z-30"
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.6 }}
            exit={{ opacity: 0 }}
          />
          <motion.div
            className="fixed inset-0 flex items-center justify-center z-40"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div className="bg-white shadow-lg w-full h-full">
              <div className="p-6">
                <div className="flex justify-center items-center relative">
                  <h2 className="text-4xl text-[#282F44] text-center">
                    Log Issue
                  </h2>
                  <button
                    onClick={() => onClose()}
                    type="button"
                    className="absolute top-0 right-0 p-1.5 rounded-lg text-white bg-transparent  inline-flex items-center"
                  >
                    <CloseSvg className="w-6 h-6 fill-[#282F44]" alt="Close" />
                  </button>
                </div>
              </div>

              <div className="p-8 flex flex-col flex-grow justify-between max-h-[90%] h-full">
                <div className="flex flex-col w-full items-center">
                  <div className="w-full">
                    <label className="text-3xl font-medium text-[#464C64]">
                      Description
                      <textarea
                        id="description"
                        value={issueDescription}
                        onChange={(e) => setIssueDescription(e.target.value)}
                        className={`mt-1 text-top block w-full p-2 max-h-[36rem] min-h-[172px] border-[#C6CCE8] rounded-lg border-2 focus:outline-none focus:ring-0`}
                      />
                    </label>
                  </div>
                </div>

                <div className="flex flex-col w-full">
                  {error && (
                    <div
                      className="bg-[#F9DAE3] text-[#51001C] border-l-2 border-l-[#C12960] text-xl p-4 mt-4 flex flex-row gap-2 align-top mb-large w-full"
                      style={{
                        color: "red",
                      }}
                    >
                      {error}
                    </div>
                  )}
                  <div className="flex justify-between gap-6 w-full">
                    <Button
                      onClick={onClose}
                      variant="secondary"
                      className="w-full border-2 rounded-xl text-[21px] font-normal h-[72px] p-6 border-[#DAE3F9]"
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={handleIssueSubmit}
                      className="w-full rounded-xl text-[21px] p-6 bg-[#173991]"
                      disabled={issueDescription === ""}
                    >
                      Send
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};
