export const Footer = ({ children, opacity = "88%" }) => {
  return (
    <div
      className="absolute bottom-0 left-0 bg-white w-full text-4xl font-normal"
      style={{ opacity }}
    >
      {children}
    </div>
  );
};
