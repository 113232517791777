export const Step5 = ({
  sortedItems,
  setSortedItems,
  handlePreviousStep,
  handleNextStep,
}) => {
  return (
    <div className="space-y-4">
      <h2 className="text-xl font-semibold">Step 5: Sort Items</h2>
      <label className="block">
        Assembled Lego Weight (kg):
        <input
          type="number"
          value={sortedItems.assembledNonConform}
          onChange={(e) =>
            setSortedItems({
              ...sortedItems,
              assembledNonConform: e.target.value,
            })
          }
          className="mt-1 block w-full p-2 border rounded"
        />
      </label>
      <label className="block">
        Good Quality Lego Weight (kg):
        <input
          type="number"
          value={sortedItems.goodQualityLego}
          onChange={(e) =>
            setSortedItems({
              ...sortedItems,
              goodQualityLego: e.target.value,
            })
          }
          className="mt-1 block w-full p-2 border rounded"
        />
      </label>
      <div className="space-x-4">
        <button
          onClick={handlePreviousStep}
          className="bg-gray-500 hover:bg-gray-700 font-bold py-2 px-4 rounded"
        >
          Previous
        </button>
        <button
          onClick={handleNextStep}
          className="bg-green text-white hover:bg-green-700 font-bold py-2 px-4 rounded"
        >
          Next
        </button>
      </div>
    </div>
  );
};
