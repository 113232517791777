import React, { useContext } from "react";
import QRCode from "react-qr-code";
import AuthContext from "contexts/AuthContext";
import { useTakebacksByEmail } from "api/use-takebacks";

export default function OnlineQrCode() {
  const { user } = useContext(AuthContext);

  const openTakeback = useTakebacksByEmail({ email: user.email });
  const latestTakeback = openTakeback?.sort(
    (a, b) => new Date(b.sys_updated_on) - new Date(a.sys_updated_on)
  )[0];
  const sys_id = latestTakeback?.sys_id;

  const qrCodeValue = `${sys_id}`;

  return (
    <div className="flex flex-col items-center justify-between h-[calc(100vh-81px)] p-6">
      <div className="bg-white flex flex-col my-auto items-center">
        <QRCode value={qrCodeValue} size={200} />
        <h2 className="font-semibold text-4xl mt-6">Registration Complete</h2>
        <span className="text-lg font-extralight text-[#757575] mt-2 text-center">
          Present this QR code to the store associate to continue
        </span>
      </div>
    </div>
  );
}
