import React from "react";
import ReaderQr from "components/qr-reader";

function QrReader() {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="flex justify-center items-center">
        <div className="w-full">
          <ReaderQr />
        </div>
      </div>
    </div>
  );
}

export default QrReader;
