import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const RedirectHandler = ({ lang }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const redirectPaths = [
      // t("urls.store"),
      t("urls.kiosk"),
      t("urls.scale"),
      t("urls.signup_store"),
      t("urls.trade_in"),
    ];

    const shouldRedirect = redirectPaths.some((path) =>
      location.pathname.startsWith(path)
    );

    if (shouldRedirect) {
      navigate(`/${lang}`, { replace: true });
    }
  }, [location, navigate, t, lang]);

  return null;
};
