import React from "react";
import LoadingGIF from "assets/loading.gif";
import { cn } from "lib/utils";

export default function LoadingSpinner({
  isLoading = "Loading...",
  className,
}) {
  return (
    <div
      className={cn(
        "flex flex-col gap-8 justify-center items-center h-[calc(100vh-81px)]",
        className
      )}
    >
      <img src={LoadingGIF} alt="" className="w-[72px]" />
      <h1 className="font-semibold text-6xl">{isLoading}</h1>
    </div>
  );
}
