import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getQueryParams } from "lib/utils";
import { useLocation } from "react-router-dom";
import { Header } from "components/header";
import { Input } from "components/input";
import { Button } from "components/button";

const { REACT_APP_API } = process.env;

const PasswordForgot = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = getQueryParams(location.search);
  const [email, setEmail] = useState(``);
  const [complete, setComplete] = useState(false);
  const [isResetDisabled, setIsResetDisabled] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setPasswordResetState(true);
    try {
      const response = await fetch(`${REACT_APP_API}/password/forgot`, {
        method: `POST`,
        body: JSON.stringify({
          email,
          returnToPath: `${queryParams.returnToPath}?updatedPassword=true`,
          passwordUpdatePath: t(`urls.password_update`),
        }),
      });

      if (!response.ok) {
        throw new Error();
      }
      setComplete(true);
    } catch (error) {
      console.error(error);
      alert("Please try again");
    }
  };

  const setPasswordResetState = (state) => {
    localStorage.setItem("passwordReset", state);
    sessionStorage.setItem("passwordResetTimestamp", Date.now());
    setIsResetDisabled(state);
  };

  useEffect(() => {
    const checkPasswordResetState = () => {
      const passwordReset = localStorage.getItem("passwordReset");
      const timestamp = sessionStorage.getItem("passwordResetTimestamp");

      if (passwordReset === "true" && timestamp) {
        const elapsedTime = Date.now() - parseInt(timestamp);
        if (elapsedTime >= 60000) {
          setPasswordResetState(false);
        } else {
          setIsResetDisabled(true);
          setTimeout(() => {
            setPasswordResetState(false);
          }, 60000 - elapsedTime);
        }
      }
    };

    checkPasswordResetState();
    const interval = setInterval(checkPasswordResetState, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="min-h-screen">
      <Header />
      {complete ? (
        <>{t(`ui.password_check_email`)}</>
      ) : (
        <form
          className="w-full flex flex-col gap-x-large"
          onSubmit={handleSubmit}
        >
          <h2 className="text-5xl font-semibold mb-6">
            {t(`ui.password_forgot`)}
          </h2>
          <Input
            label={t("ui.input_email")}
            type="email"
            onChange={(event) => setEmail(event.target.value)}
            value={email}
          />
          <Button type="submit" disabled={isResetDisabled}>
            {t(`ui.button_continue`)}
          </Button>
        </form>
      )}{" "}
      {isResetDisabled && !complete && (
        <div className="w-full mt-10 flex flex-col bg-[#C0DFC3] border-l-4 px-4 py-3 border-[#00963E] rounded-md">
          <h2 className="font-semibold text-2xl max-w-lg">
            {t("ui.password_forgot_title")}
          </h2>
          <span className="max-w-lg text-base font-light leading-normal">
            {t("ui.password_forgot_description")}
          </span>
        </div>
      )}
    </div>
  );
};

export default PasswordForgot;
