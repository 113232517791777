import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import locationsData from "data/locations.json";
import { useEffect, useState } from "react";

// Utility function to merge Tailwind CSS classes
export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

// Utility function to format dates for SNOW
export function formatSnowDate(date) {
  const padZero = (num) => num.toString().padStart(2, "0");

  const year = date.getFullYear();
  const month = padZero(date.getMonth() + 1); // Months are zero-based
  const day = padZero(date.getDate());
  const hours = padZero(date.getHours());
  const minutes = padZero(date.getMinutes());
  const seconds = padZero(date.getSeconds());

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

// Utility function to get all query parameters and convert numerical and boolean values
export function getQueryParams(search) {
  const params = new URLSearchParams(search);
  const queryParams = {};

  for (let [key, value] of params.entries()) {
    // Add additional type conversions if necessary
    if (!isNaN(parseFloat(value)) && isFinite(value)) {
      queryParams[key] = parseFloat(value);
    } else if (value.toLowerCase() === "true") {
      queryParams[key] = true;
    } else if (value.toLowerCase() === "false") {
      queryParams[key] = false;
    } else {
      queryParams[key] = value;
    }
  }

  return { ...queryParams };
}

export function checkPasswordCriteria(password) {
  const passwordCriteria = /^(?=.*[A-Z])(?=.*\d).{8,}$/;
  return passwordCriteria.test(password);
}

export function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export function formatEstimationNumber(value, decimalPlaces = 2) {
  const parsedValue = parseFloat(value);
  return isNaN(parsedValue) ? value : parsedValue.toFixed(decimalPlaces);
}

export function getKgInLbs(weight) {
  const lbsWeight = weight * 2.2046226218488;
  // Round to one decimal point
  const roundedWeight = Math.round(lbsWeight * 10) / 10;
  // Convert to string and ensure it has two decimal places if needed
  const formattedWeight = roundedWeight.toFixed(1).replace(/\.0$/, "");
  return parseFloat(formattedWeight);
}

function checkState() {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;

        const colorado = {
          north: 40.674101,
          south: 38.775687,
          east: -103.375789,
          west: -106.124978,
        };

        const georgia = {
          north: 34.813689,
          south: 32.745988,
          east: -83.102169,
          west: -84.976652,
        };

        if (
          latitude >= colorado.south &&
          latitude <= colorado.north &&
          longitude >= colorado.west &&
          longitude <= colorado.east
        ) {
          resolve("CO");
        } else if (
          latitude >= georgia.south &&
          latitude <= georgia.north &&
          longitude >= georgia.west &&
          longitude <= georgia.east
        ) {
          resolve("GA");
        } else {
          resolve(null);
        }
      },
      (error) => {
        console.log("GEO Error", error);
        reject(error);
      }
    );
  });
}

export function useCurrentLocation() {
  const [location, setLocation] = useState({});
  const [error, setError] = useState(null);
  const queryParams = new URLSearchParams(window.location.search);
  const stateQueryParam = queryParams.get("state");
  const language = window.location.pathname.split("/")[1];

  //initial useEffect to set location based on language
  useEffect(() => {
    setLocation(
      locationsData.find((loc) => loc.locale === language && loc.state === null)
    );
  }, [language]);

  //useEffect to set location based on state query param if present
  useEffect(() => {
    async function fetchLocation() {
      try {
        const currentState =
          language === "en-US" ? stateQueryParam || (await checkState()) : null;

        const foundLocation =
          locationsData.find(
            (loc) =>
              loc.locale === language &&
              loc.state?.toLowerCase() === currentState?.toLowerCase()
          ) ||
          locationsData.find(
            (loc) => loc.locale === language && loc.state === null
          );

        setLocation(foundLocation);
      } catch (err) {
        setError(err);
      }
    }

    fetchLocation();
  }, [stateQueryParam, language]);

  return {
    location: {
      name: "",
      takebackMethod: [],
      takebackDisbursement: [],
      locale: "",
      country: language || null,
      state: stateQueryParam || null,
      ...location,
    },
    error,
  };
}

//get German decimal separator format
export const localizeDecimalSeparator = (rawValue, currentLocation) => {
  return currentLocation?.locale !== "en-US"
    ? rawValue?.toString().replace(".", ",")
    : rawValue;
};
