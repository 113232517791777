import React, { useRef } from "react";
import { TakePhotoButton } from "./TakePhotoButton";
import { Webcam } from "./Webcam";

export const TakePhoto = ({ dispatch, title, onCancel, onPhotoTaken }) => {
  const webcamRef = useRef(null);

  const handleCapturePackage = (picture) => {
    onPhotoTaken(picture);
  };

  return (
    <div className="z-50 fixed inset-0 h-full w-full">
      <div className="relative bg-white h-full">
        <Webcam ref={webcamRef} />

        <div className="absolute bottom-0 left-0 bg-white w-full text-4xl font-normal">
          <div className="flex gap-8 flex-col p-8">
            <div className="w-full flex flex-col gap-2">
              <div className="flex flex-row justify-between">
                <h1 className="text-6xl text-[#282F44] flex flex-row items-center gap-2">
                  {title}
                </h1>
              </div>
              <p className="text-4xl font-medium text-[#464C64]">
                Spread the contents out on the sorting surface and take a top
                down photo.
              </p>
            </div>

            <div className="flex flex-row w-full gap-8">
              <button
                className="rounded-lg text-red border-2 border-red py-4 w-full m-0"
                onClick={() => {
                  onCancel();
                }}
              >
                Cancel
              </button>
              <TakePhotoButton
                dispatch={dispatch}
                onPhotoConfirmed={handleCapturePackage}
                capturePhoto={() => {
                  return webcamRef.current.getScreenshot();
                }}
                title={title}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
