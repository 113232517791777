import { useState, useEffect, useCallback } from "react";
import WeightInput from "components/weight-input";
import WeightEstimation from "components/weight-estimation";
import { AnimatePresence } from "framer-motion";
import FormContainer from "components/form-container";
import { useLocation, useOutletContext } from "react-router-dom";
import ProgressBar from "components/progress-bar";
import { calculateEstimate } from "lib/calculateEstimate";
import { localizeDecimalSeparator, getQueryParams } from "lib/utils";
import { useTranslation } from "react-i18next";
import weightRestrictions from "data/weight-restrictions.json";
import InputValidation from "components/input-validation";
import { TAKEBACK_METHOD_NAME } from "consts/query-params";
import { useCurrentLocation } from "lib/utils";
import useWhen from "hooks/useWhen";
import { PAYMENT_ARGS } from "consts/feature-config";
import RewardOverview from "components/reward-overview";

export default function WeightPage() {
  const [weightInput, setWeightInput] = useState(null);
  const [skipMethodPage, setSkipMethodPage] = useState(false);
  const [weightAlarm, setWeightAlarm] = useState(null);

  // translation
  const { t } = useTranslation();

  // Get query params
  const location = useLocation();
  const queryParams = getQueryParams(location.search);

  const unit = `${t("scale.weight")}`;

  // contitional navigation based on location
  const { location: currentLocation } = useCurrentLocation();

  useEffect(() => {
    if (
      currentLocation?.takebackMethod?.includes("mail") &&
      currentLocation?.takebackMethod?.length === 1
    ) {
      setSkipMethodPage(true);
    } else {
      setSkipMethodPage(false);
    }
  }, [queryParams, currentLocation]);

  const destination = skipMethodPage
    ? `${t("urls.trade_in_checklist")}`
    : `${t("urls.trade_in_method")}`;

  // Set right side visual
  const { setSidebarVisual } = useOutletContext();
  useEffect(() => {
    setSidebarVisual(currentLocation.locale === "de-DE" ? "euro" : "dollar");
  }, [currentLocation, setSidebarVisual]);

  //handle input change and max weight
  // const isStateExempt =
  //   currentLocation.state === "CO" || currentLocation.state === "GA";

  const maxWeight =
    currentLocation?.takebackMethod?.includes("mail") &&
    currentLocation?.takebackMethod?.length === 1
      ? weightRestrictions.find((weight) => weight.unit === unit).maxMail
      : weightRestrictions.find((weight) => weight.unit === unit).maxInstore;

  const minWeight =
    currentLocation?.takebackMethod?.includes("mail") &&
    currentLocation?.takebackMethod?.length === 1
      ? weightRestrictions.find((weight) => weight.unit === unit).minMail
      : weightRestrictions.find((weight) => weight.unit === unit).minInstore;

  const checkWeightLimits = useCallback(
    (weight) => {
      if (maxWeight && weight > maxWeight) {
        setWeightAlarm(
          t("ui.weight_alarm_max", {
            maxWeight: maxWeight,
            unit: unit,
          })
        );
      } else if (minWeight && weight < minWeight) {
        setWeightAlarm(
          t("ui.weight_alarm_min", {
            minWeight: minWeight,
            unit: unit,
          })
        );
      } else {
        setWeightAlarm(null);
      }
    },
    [t, maxWeight, minWeight, unit]
  );

  // Initialize input with query param
  useEffect(() => {
    if (queryParams.w) {
      checkWeightLimits(parseFloat(queryParams.w));
      setWeightInput(queryParams.w);
      setWeightAlarm(null);
    }
  }, [queryParams.w, checkWeightLimits]);

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    const newNumericValue = newValue.replace(/,/g, ".");
    setWeightInput(newValue);
    checkWeightLimits(parseFloat(newNumericValue));
  };

  const formattedWeight =
    currentLocation?.locale !== "en-US"
      ? weightInput?.toString().replace(",", ".")
      : weightInput;

  const parsedWeight = parseFloat(formattedWeight).toFixed(1);
  const weightEstimate =
    currentLocation?.locale !== "en-US"
      ? parsedWeight.toString().replace(".", ",")
      : parsedWeight;

  const estimate = calculateEstimate(formattedWeight, currentLocation?.country);

  // Generate updated params
  const updatedParams = { ...queryParams, w: formattedWeight };
  if (skipMethodPage) {
    updatedParams[TAKEBACK_METHOD_NAME] = "mail";
  }

  //only show cash payment in appropriate environments
  const showPayment = useWhen(PAYMENT_ARGS);

  return (
    <FormContainer
      submitLabel={t("ui.button_continue")}
      submitHref={destination}
      submitParams={updatedParams}
      submitDisabled={!weightInput || weightAlarm}
      submitTracking="Trade-in weight submitted"
      secondaryLabel={t("ui.button_cancel")}
      secondaryHref="/"
    >
      <ProgressBar step={1} />
      <div className="flex flex-col gap-regular">
        <h2 className="text-primary text-6xl">{t("trade-in.weight.title")}</h2>
        <p className="text-tertiary text-2xl">
          {t("trade-in.weight.description")}
        </p>
      </div>
      <div>
        <WeightInput
          caption={t("ui.input_weight")}
          placeholder="0"
          unit={unit}
          className="max-w-6xl sm:max-w-64"
          value={weightInput}
          onChange={handleInputChange}
          locale={currentLocation?.locale}
        />
        {weightAlarm && <InputValidation errorMessage={weightAlarm} />}
        <div className="mt-4 text-tertiary text-xs">
          {t("trade-in.weight.footnote")}
        </div>
      </div>

      <AnimatePresence>
        {weightInput && (
          <WeightEstimation
            className="mt-4"
            title={t("ui.estimate_part1")}
            singleValue={
              !showPayment
                ? localizeDecimalSeparator(estimate.gift, currentLocation)
                : null
            }
            range={
              showPayment
                ? [
                    localizeDecimalSeparator(estimate.payment, currentLocation),
                    localizeDecimalSeparator(estimate.gift, currentLocation),
                    currentLocation,
                  ]
                : null
            }
            animate
            locale={currentLocation?.locale}
            description={t("ui.estimate_part2", {
              weight: weightEstimate,
              unit: unit,
            })}
            minMaxReached={weightAlarm}
          />
        )}
        {!weightInput && showPayment && <RewardOverview />}
      </AnimatePresence>
    </FormContainer>
  );
}
