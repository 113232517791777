import { useWarehouseContext } from "./useWarehouseContext";
import { Footer } from "./components/Footer";
import { WeightCapture } from "./components/WeightCapture";
import { StepHeader } from "./components/StepHeader";
import { FullscreenModal } from "./components/FullscreenModal";

const TITLE = "Assembled Weight";

export const AssembledWeightCapture = ({ dispatch }) => {
  const { assembledWeight, setAssembledWeight } = useWarehouseContext();

  return (
    <FullscreenModal>
      <StepHeader dispatch={dispatch} />

      <Footer>
        <WeightCapture
          title={TITLE}
          instruction="Empty the Assembled Non-conform material into the control container and record weight. Do not weigh the Non-conform bucket itself."
          value={assembledWeight}
          onChange={(weight) => setAssembledWeight(weight)}
          dispatch={dispatch}
        />
      </Footer>
    </FullscreenModal>
  );
};

AssembledWeightCapture.title = TITLE;
