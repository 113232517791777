import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { getQueryParams } from "lib/utils";
import { useLocation, useNavigate } from "react-router-dom";
import { Header } from "components/header";
import PasswordInput from "components/password-input";
import { Button } from "components/button";

const { REACT_APP_API } = process.env;

const PasswordUpdate = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = getQueryParams(location.search);
  const [password, setPassword] = useState(``);
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(`${REACT_APP_API}/password/update`, {
        method: `POST`,
        body: JSON.stringify({
          email: queryParams.email,
          resetCode: queryParams.resetCode,
          password,
        }),
      });

      if (!response.ok) {
        throw new Error();
      }

      navigate(queryParams.returnToPath);
    } catch (error) {
      console.error(error);
      alert("Please try again");
    }
  };

  return (
    <div className="min-h-screen">
      <Header />
      <form
        className="w-full flex flex-col gap-x-large"
        onSubmit={handleSubmit}
      >
        <h2 className="text-2xl font-semibold mb-6">
          {t(`ui.password_update`)}
        </h2>
        <PasswordInput
          label={t("ui.input_password")}
          type="password"
          onChange={(event) => setPassword(event.target.value)}
          value={password}
        />
        <Button type="submit">{t(`ui.button_continue`)}</Button>
      </form>
    </div>
  );
};

export default PasswordUpdate;
