import React from "react";
import { Header } from "components/header";
import { Outlet } from "react-router-dom";
import PropTypes from "prop-types";

export default function StoreViewLayout({ showHeader = true }) {
  return (
    <div className="flex flex-col min-h-screen">
      {showHeader && <Header />}
      <div className="flex-grow w-full min-h-[calc(100vh-81px)] overflow-scroll">
        <Outlet />
      </div>
    </div>
  );
}

StoreViewLayout.propTypes = {
  showHeader: PropTypes.bool,
};
