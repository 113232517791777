import React, { useEffect, useState, useRef } from "react";
import KioskStartButton from "../components/start-button";
import FlyingBricks from "../assets/flyingBricks.svg";

export default function PlaceMoreBricks({
  navigate,
  loggedWeight,
  removeLastLoggedWeight,
  setInputWeight,
}) {
  const [initialWeight, setInitialWeight] = useState(null);
  const prevInputWeightRef = useRef();

  useEffect(() => {
    setInitialWeight(loggedWeight);
    prevInputWeightRef.current = loggedWeight;
  }, [loggedWeight]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        localStorage.removeItem("hasAnimated");
        navigate("scale");
      }
    };

    window.addEventListener("keypress", handleKeyPress);
    return () => {
      window.removeEventListener("keypress", handleKeyPress);
    };
  }, [initialWeight, navigate]);

  return (
    <div
      className="flex flex-col items-center justify-between w-full h-full relative"
      // onClick={() => navigate("scale")}
    >
      <p className="text-[4.5vh] text-center max-w-[50vh] text-white mt-[3vh] px-[4vh] leading-[130%]">
        Place your LEGO® bricks into the tray
      </p>
      <KioskStartButton
        className="relative z-50 w-1/4"
        onClick={(e) => {
          e.stopPropagation();
          navigate("scale");
          setInputWeight(0);

          // removeLastLoggedWeight();
        }}
      >
        Cancel
      </KioskStartButton>
      <img
        src={FlyingBricks}
        alt="Simplified depiction of flying LEGO bricks"
        className="absolute bottom-[-10vh] shrink-0"
      />
    </div>
  );
}
