import { Header } from "components/header";
import {
  COUNTRY_NAME,
  LOCALE_NAME,
  STATE_NAME,
  TAKEBACK_METHOD_NAME,
} from "consts/query-params";
import useIsMobile from "hooks/useIsMobile";
import { getQueryParams } from "lib/utils";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import stores from "data/stores.json";
import { Trans } from "react-i18next";
import AuthContext from "contexts/AuthContext";
import { Button } from "components/button";
import useWhen from "hooks/useWhen";
import { PAYMENT_ARGS } from "consts/feature-config";

import { TradeInIcon } from "pages/svgs/tradein-logo";
import BgAccepted from "assets/landingAccepted.png";
import BgNotAccepted from "assets/landingNotAccepted.png";
import { Footer } from "components/footer/footer";

export default function StoreLanding() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);

  // Get query params
  const location = useLocation();
  const queryParams = getQueryParams(location.search);

  //feature flag
  const showPayment = useWhen(PAYMENT_ARGS);

  // redirect to main landing page if no store query param
  useEffect(() => {
    if (!queryParams[TAKEBACK_METHOD_NAME]) {
      navigate("/");
    }
  }, [queryParams, navigate]);

  // scroll to
  const moveTo = (elementId, offset = 0) => {
    const element = document.getElementById(elementId);
    if (element) {
      const elementPosition =
        element.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - offset;
      window.scrollTo({ top: offsetPosition, behavior: "smooth" });
    }
  };

  const handleScrollTo = (e, elementId, offset) => {
    e.preventDefault();
    moveTo(elementId, offset);
  };

  //landing page content
  const CtaButton = ({ className }) => {
    return (
      <Button
        tracking="Start trade-in clicked"
        href={`${t("urls.signup_store_checklist")}`}
        queryParams={{
          [LOCALE_NAME]: "en-US",
          [STATE_NAME]: stores.find(
            (store) => store.value === queryParams[TAKEBACK_METHOD_NAME]
          )?.state,
          [COUNTRY_NAME]: "US",
        }}
        className={className}
      >
        {t("landing.button_start_new")}
      </Button>
    );
  };
  const isMobile = useIsMobile();

  const mobileContent = (
    <>
      <Link to={t("urls.my_trade_ins")}>
        <TradeInIcon />
      </Link>
    </>
  );

  return (
    <div className="min-h-screen">
      <Header mobileChildren={mobileContent} classNames="bg-yellow border-none">
        <div className="flex gap-large">
          <CtaButton className="hidden lg:block" />

          {!isMobile && (
            <Button
              href={user ? `${t("urls.my_trade_ins")}` : `${t("urls.login")}`}
              variant="secondary"
              queryParams={queryParams}
            >
              {user
                ? t("landing.button_my_trade_ins")
                : t("landing.button_sign_in")}
            </Button>
          )}
        </div>
      </Header>
      <div className="bg-[#fff9ce] p-small md:text-center text-xs z-20 w-full">
        <Trans
          i18nKey={"landing.pentatonic"}
          components={{
            1: (
              <Link
                to={t("urls.terms_and_conditions")}
                className="cursor-pointer text-blue"
                target="_blank"
                rel="noopener noreferrer"
              />
            ),
          }}
        />
      </div>
      <div
        id="hero"
        className="bg-gradient-to-b from-[#FFF087] via-[#FFE950] to-[#FFE21B] px-x-large md:px-xxxx-large relative"
      >
        <div className="container mx-auto flex flex-col md:flex-row md:items-end gap-xx-large max-w-6xl relative z-10 md:pb-0">
          <div className="mx-auto md:mx-0 mt-x-large md:mt-xxx-large max-w-2xl mb-xx-large md:mb-[200px] lg:mb-xxxx-large">
            <h1 className="text-primary font-bold text-[3rem] leading-[3.875rem] md:text-7xl lg:text-8xl mb-x-large md:mb-xx-large">
              <Trans i18nKey={"landing.hero.heading"} />
            </h1>
            <div className="text-xl mb-medium pr-0 md:pr-56 lg:pr-0 font-bold">
              Welcome to the LEGO Store
              {queryParams[TAKEBACK_METHOD_NAME] &&
                ` ${
                  stores.find(
                    (store) => store.value === queryParams[TAKEBACK_METHOD_NAME]
                  )?.name
                }`}
              .
            </div>
            <p className="text-secondary text-xl mb-xx-large pr-0 md:pr-56 lg:pr-0">
              {showPayment
                ? t("landing.hero.sub_heading_withPayment_instore")
                : t("landing.hero.sub_heading_instore")}
            </p>
            <div className="flex flex-col items-stretch md:items-center md:flex-row space-y-large md:space-y-0 md:space-x-x-large max-w-screen min-w-0">
              <CtaButton />
              <Button
                href="#"
                onClick={(e) => handleScrollTo(e, "whatWeAccept", 80)}
                variant="secondary"
                icon="arrow_downward"
              >
                {t("landing.hero.secondary_btn_instore")}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div id="whatWeAccept">
        <div className="bg-green-400 flex flex-col justify-center py-xxx-large md:py-xxxx-large px-x-large md:px-xx-large">
          <h2 className="text-primary text-5xl md:text-6xl font-bold text-center mb-xxx-large">
            {t("landing.what_we_accept.heading")}
          </h2>
          <div className="flex flex-col lg:flex-row justify-stretch mx-auto space-y-xxx-large lg:space-y-0 lg:gap-xxx-large lg:max-w-6xl">
            <div className="bg-white p-x-large shadow-sm flex-1">
              <div className="flex gap-large mb-large items-center">
                <img
                  src={BgAccepted}
                  alt={t("landing.what_we_accept.accepted_icon_alt")}
                  className="w-10 h-10 shrink-0"
                />
                <h3 className="text-primary text-2xl font-bold">
                  {t("landing.what_we_accept.accepted_heading")}
                </h3>
              </div>
              <ul className="list-disc list-inside pl-regular">
                <li className="mb-large">
                  <Trans i18nKey="landing.what_we_accept.accepted_bullets.item1" />
                </li>
                <li className="mb-large">
                  {t("landing.what_we_accept.accepted_bullets.item2")}
                </li>
                <li className="mb-large">
                  {t("landing.what_we_accept.accepted_bullets.item3")}
                </li>
              </ul>
            </div>
            <div className="bg-white p-x-large shadow-sm flex-1">
              <div className="flex gap-large mb-large items-center">
                <img
                  src={BgNotAccepted}
                  alt={t("landing.what_we_accept.not_accepted_icon_alt")}
                  className="w-10 h-10 shrink-0"
                />
                <h3 className="text-primary text-2xl font-bold">
                  {t("landing.what_we_accept.not_accepted_heading")}
                </h3>
              </div>
              <ul className="list-disc list-inside pl-regular">
                <li className="mb-large">
                  {t("landing.what_we_accept.not_accepted_bullets.item1")}
                </li>
                <li className="mb-large">
                  {t("landing.what_we_accept.not_accepted_bullets.item2")}
                </li>
                <li className="mb-large">
                  {t("landing.what_we_accept.not_accepted_bullets.item3")}
                </li>
                <li className="mb-large">
                  {t("landing.what_we_accept.not_accepted_bullets.item4")}
                </li>
                <li className="mb-large">
                  {t("landing.what_we_accept.not_accepted_bullets.item5")}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
