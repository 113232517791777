import { useEffect, useState, Fragment } from "react";
import { Button } from "components/button";
import { Header } from "components/header";
import MyTradeInsCard from "components/my-trade-ins-card";
import { useMyTakebacks, useCreateTakeback } from "api/use-takebacks";
import PayallModal from "components/payall-modal";
import { useLocation } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { AnimatePresence } from "framer-motion";
import ImpressumFooter from "components/footer-impressum";
import LoadingSpinner from "components/loading-spinner";
import { getQueryParams } from "lib/utils";
import PulseLoader from "react-spinners/PulseLoader";

export const MyTradeIns = () => {
  const [offset, setOffset] = useState(0);
  const [takebacks, setTakebacks] = useState([]);
  const [previousData, setPreviousData] = useState(null);
  const { data, refetch, isLoading, totalCount } = useMyTakebacks({ offset });
  const [cachedTotalCount, setCachedTotalCount] = useState();
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const location = useLocation();
  const queryParams = getQueryParams(location.search);
  const locationId = localStorage.getItem("locationId");
  const storeLocations = {
    80401: { state: "CO", country: "US" },
    30043: { state: "GA", country: "US" },
  };
  const storeLocaction = storeLocations[locationId];
  const [ready, setReady] = useState(false);
  const [moreReady, setMoreReady] = useState(false);
  const [readyCount, setReadyCount] = useState(0);
  // State to update the payment status after Payall modal is completed without needing a page reload / re-fetch
  const [payallCompleted, setPayallCompleted] = useState(false);
  const handleMore = () => setOffset(offset + 5);

  useCreateTakeback();

  useEffect(() => {
    if (isNaN(cachedTotalCount) && !isNaN(totalCount)) {
      setCachedTotalCount(totalCount);
    }
  }, [cachedTotalCount, totalCount]);

  // useEffect(() => {
  //   console.log(JSON.stringify(data) !== JSON.stringify(previousData));
  //   if (JSON.stringify(data) !== JSON.stringify(previousData)) {
  //     setPreviousData(data);
  //     setTakebacks([...takebacks, ...(data || [])]);
  //   }
  // }, [data, previousData, takebacks]);

  // fix to remove duplicates and sort the takebacks
  useEffect(() => {
    if (
      data &&
      data.length > 0 &&
      JSON.stringify(data) !== JSON.stringify(previousData)
    ) {
      setPreviousData(data);
      setTakebacks((prevTakebacks) => {
        const uniqueEntries = new Map(
          [...prevTakebacks, ...data].map((item) => [item.number, item])
        );
        const sortedEntries = Array.from(uniqueEntries.values()).sort((a, b) =>
          a.sys_created_on < b.sys_created_on ? 1 : -1
        );
        return sortedEntries;
      });
    }
  }, [data, previousData]);

  useEffect(() => {
    if (readyCount >= offset + 5 || readyCount >= cachedTotalCount) {
      setReady(true);
      setMoreReady(true);
    } else {
      setMoreReady(false);
    }
  }, [readyCount, cachedTotalCount, offset]);

  return (
    <div className="min-h-screen relative flex flex-col justify-between">
      <div>
        <Header />
        {!ready && (
          <LoadingSpinner
            className="fixed z-20 left-0 top-[81px] w-screen bg-white bg-opacity-25"
            isLoading
          />
        )}
        <div
          className={`container min-h-[calc(100vh - 81px)] m-auto py-16 px-4 md:px-0 ${!ready ? `opacity-0	` : `opacity-100`}`}
        >
          <div className="flex justify-between items-center">
            <h1 className="text-4xl md:text-6xl font-semibold">
              {t("my_trade_ins.title")}
            </h1>
            {/* <Button
              href={t("urls.trade_in_weight")}
              variant="secondary"
              className=""
              queryParams={{
                country:
                  queryParams.country || storeLocaction?.country || `null`,
                state: queryParams.state || storeLocaction?.state || `null`,
              }}
            >
              {t("ui.button_start")}
            </Button> */}
          </div>

          {takebacks?.length > 0 && (
            <div className="mt-x-large -mb-[16px]">
              <div className="rounded-lg w-full h-full overflow-hidden relative">
                <div className="h-full w-2 bg-blue absolute top-0 left-0"></div>
                <div className="flex flex-col p-4 pl-6 bg-blue-300 w-full">
                  <div className="font-semibold">
                    <Trans
                      i18nKey={"my_trade_ins.extended_processing_times_title"}
                    />
                  </div>
                  <div className="text-secondary text-sm">
                    <Trans
                      i18nKey={"my_trade_ins.extended_processing_times_body"}
                      components={{
                        1: (
                          <a
                            href="mailto:support@lego.com"
                            className="text-blue"
                          >
                            contact support
                          </a>
                        ),
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {takebacks?.map((takeback, index) => (
            <Fragment key={takeback.number}>
              <AnimatePresence>
                {modalOpen === takeback.number && (
                  <PayallModal
                    caseId={takeback.number}
                    setModalOpen={setModalOpen}
                    setPayallCompleted={(payallComplete) => {
                      refetch();
                      setPayallCompleted(payallComplete);
                    }}
                  />
                )}
              </AnimatePresence>
              {takeback.u_lego_return_type !== "credit" && (
                <MyTradeInsCard
                  setPayallOpen={() => setModalOpen(takeback.number)}
                  payallCompleted={payallCompleted}
                  setReadyCount={setReadyCount}
                  id={takeback.sys_id}
                  index={index}
                  storeLocation={storeLocaction}
                  queryParams={queryParams}
                />
              )}
            </Fragment>
          ))}
          {(takebacks?.length < cachedTotalCount || !moreReady) && (
            <Button
              variant="secondary"
              className="mt-8 "
              onClick={handleMore}
              disabled={isLoading}
            >
              <div className="flex items-center gap-regular">
                {t("my_trade_ins.load_more")}
                {(isLoading || !moreReady) && (
                  <PulseLoader color="#000" size={3} />
                )}
              </div>
            </Button>
          )}
        </div>
      </div>
      <ImpressumFooter className="" />
    </div>
  );
};
