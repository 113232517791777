import React, { useState } from "react";
import { useWarehouseContext } from "./useWarehouseContext";
import { ReactComponent as DeleteSvg } from "./svgs/delete.svg";
import { Footer } from "./components/Footer";
import { RejectButton } from "./components/RejectButton";
import { StepHeader } from "./components/StepHeader";
import { FullscreenModal } from "./components/FullscreenModal";
import { TakePhoto } from "./components/TakePhoto";
import { ReactComponent as CameraSvg } from "./svgs/camera.svg";
import { ReactComponent as PhotoSvg } from "./svgs/photo.svg";

export const RejectContents = ({ dispatch }) => {
  const [error, setError] = useState("");
  const [showCamera, setShowCamera] = useState(false);
  const [reason, setReason] = useState("");
  const [showThumbnails, setShowThumbnails] = useState(false);

  const {
    rejectedContentsPictures,
    addRejectContentsPicture,
    deleteRejectContentsPicture,
  } = useWarehouseContext();

  const onPhotoTaken = (picture) => {
    setShowCamera(false);
    addRejectContentsPicture(picture);
  };

  const onCancel = () => {
    setShowCamera(false);
  };

  return (
    <FullscreenModal>
      <StepHeader dispatch={dispatch} />

      <Footer>
        <div className="flex gap-8 flex-col p-8 pt-4">
          <div className="w-full flex flex-col">
            {error && (
              <div
                className="bg-[#F9DAE3] text-[#51001C] border-l-2 border-l-[#C12960] text-xl p-4 w-full"
                style={{
                  color: "red",
                }}
              >
                {error}
              </div>
            )}
            <div className="flex flex-row justify-between items-center gap-2">
              <div className="flex flex-row justify-start items-center gap-2">
                <h1 className="text-6xl text-[#282F44] flex flex-row items-center gap-2">
                  Reject Take Back
                </h1>
                <span className="relative">
                  <PhotoSvg style={{ width: "34px" }} />
                  {rejectedContentsPictures?.length > 0 && (
                    <sup className="absolute top-0 right-[-0.25rem] rounded-full w-5 h-5 bg-red text-white text-sm text-center">
                      {rejectedContentsPictures.length}
                    </sup>
                  )}
                </span>
              </div>
              <button
                className="p-6 bg-white rounded-lg border-2 border-[#DAE3F9]"
                onClick={() => {
                  setShowThumbnails(!showThumbnails);
                }}
              >
                <PhotoSvg style={{ width: "24px", height: "24px" }} />
              </button>
            </div>
            <p className="text-4xl font-medium text-[#464C64]">
              Take photos of the package including any damage
            </p>
          </div>

          <div className="flex flex-col gap-4 w-full">
            <textarea
              id="reason"
              placeholder="Rejection reason"
              rows={10}
              className="rounded-lg p-2 border-2 focus:outline-none focus:ring-0 w-full border-[#C6CCE8] h-[60px]  min-h-[60px] max-h-[420px]"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </div>

          {showThumbnails && rejectedContentsPictures.length > 0 && (
            <div className="text-white h-full flex flex-row gap-2 align-top">
              <div className="grid gap-4 grid-cols-6 lg:grid-cols-12">
                {rejectedContentsPictures.map((image, index) => (
                  <div key={index} className="relative">
                    <img
                      src={image}
                      alt={`Captured ${index}`}
                      className="w-full h-auto border rounded-lg"
                    />
                    <button
                      onClick={() => deleteRejectContentsPicture(image)}
                      className="absolute top-[-1rem] right-[-1rem]"
                    >
                      <DeleteSvg
                        className="w-6 h-6 fill-red"
                        alt="Delete image"
                      />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="flex flex-row w-full gap-8">
            <button
              className="rounded-lg text-[#00CFA8] py-4 w-full m-0 border-2 border-[#00CFA8]"
              onClick={() => {
                setShowCamera(true);
              }}
            >
              <div className="flex flex-row justify-center items-center gap-2">
                <CameraSvg className="fill-[#00CFA8]" />
                <span>Take Photo</span>
              </div>
            </button>

            {showCamera ? (
              <TakePhoto
                dispatch={dispatch}
                title="Reject Take Back"
                onPhotoTaken={onPhotoTaken}
                onCancel={onCancel}
              />
            ) : null}

            <button
              className="rounded-lg text-red py-4 w-full m-0 border-2 border-red"
              onClick={() => {
                dispatch({ type: "cancel-reject" });
              }}
            >
              Cancel
            </button>
            <RejectButton
              className="rounded-lg bg-[#173991] text-white py-4 w-full m-0"
              text="Confirm"
              onError={(message) => {
                setError(
                  "Failed to reject Take Back. Please try again. If this issue persists, please log an issue."
                );
              }}
              dispatch={dispatch}
              reason={reason}
            />
          </div>
        </div>
      </Footer>
    </FullscreenModal>
  );
};
