import { useWarehouseContext } from "./useWarehouseContext";
import { Footer } from "./components/Footer";
import { WeightCapture } from "./components/WeightCapture";
import { StepHeader } from "./components/StepHeader";
import { FullscreenModal } from "./components/FullscreenModal";

const TITLE = "Control Weight";

export const ControlWeightCapture = ({ dispatch }) => {
  const { controlWeight, setControlWeight } = useWarehouseContext();

  return (
    <FullscreenModal>
      <StepHeader dispatch={dispatch} />

      <Footer>
        <WeightCapture
          title={TITLE}
          instruction="Ensure that the only item on the scale is the control container. This control container must be used for ALL weighing operations for this takeback."
          value={controlWeight}
          onChange={(weight) => setControlWeight(weight)}
          dispatch={dispatch}
        />
      </Footer>
    </FullscreenModal>
  );
};

ControlWeightCapture.title = TITLE;
