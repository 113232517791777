import React from "react";
import { Button } from "components/button";
import { AnimatePresence, motion } from "framer-motion";
import PropTypes from "prop-types";

const ExitModal = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  body,
  primaryText,
  secondaryText,
}) => {
  if (!isOpen) return null;

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          {/* Overlay */}
          <motion.div
            className="fixed inset-0 bg-[#0B1C47] z-30"
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.6 }}
            exit={{ opacity: 0 }}
          />
          {/* Modal content */}
          <motion.div
            className="fixed inset-0 flex items-center justify-center z-40"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div className="bg-white p-6 rounded-xl shadow-lg w-full max-w-lg max-h-[95vh] overflow-auto z-20">
              <h2 className="text-[24px] text-[#282F44] text-left font-bold text-balance mb-2">
                {title}
              </h2>
              <div className="mb-6 text-[#464C64] text-[18px] text-left font-light flex justify-center max-h-[70vh]">
                {body}
              </div>
              <div className="flex justify-between gap-6">
                <Button
                  onClick={onConfirm}
                  className="w-full rounded-xl text-[21px] p-6 bg-[#173991]"
                >
                  {primaryText}
                </Button>
                <Button
                  onClick={onClose}
                  variant="secondary"
                  className="w-full border-2 rounded-xl text-[21px] font-normal h-[72px] p-6 border-[#DAE3F9]"
                >
                  {secondaryText}
                </Button>
              </div>
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};

ExitModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  title: PropTypes.string,
  body: PropTypes.node,
  primaryText: PropTypes.string,
  secondaryText: PropTypes.string,
};

export default ExitModal;
