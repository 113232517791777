import { StepHeader } from "./components/StepHeader";
import { ReactComponent as EscalatedSvg } from "./svgs/escalated.svg";
import React from "react";
import { useWarehouseContext } from "./useWarehouseContext";
import { FullscreenModal } from "./components/FullscreenModal";

export const AutoEscalated = ({ dispatch }) => {
  const { clearTakeback } = useWarehouseContext();

  return (
    <FullscreenModal>
      <div className="absolute top-0 flex p-4 bg-white w-full h-[6rem] justify-end items-center">
        <StepHeader noExit opacity="100%" />
      </div>
      <div className="relative flex items-center justify-center w-full min-h-full">
        <div className="flex items-center justify-center bg-[#EFF6FF] h-full">
          <div className="text-center font-sans">
            <div className="flex flex-col items-center justify-center gap-4">
              <EscalatedSvg />
              <span>
                <h1 className="font-semibold text-6xl max-w-lg">
                  Auto Escalation
                </h1>
                <p className="text-tertiary text-2xl">
                  Set aside the separated materials along with the original
                  packaging.
                </p>
              </span>

              <button
                className="rounded-lg text-4xl bg-blue text-white px-x-large py-regular mt-large"
                onClick={() => {
                  clearTakeback();
                  dispatch({ type: "exit" });
                }}
              >
                OK, Done
              </button>
            </div>
          </div>
        </div>
      </div>
    </FullscreenModal>
  );
};
