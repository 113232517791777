import { Fragment } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

export function EmailValidation({ emailAlarm = null }) {
  const { t } = useTranslation();
  return (
    <Fragment>
      {emailAlarm === true ? (
        <div className={`text-secondary text-sm mt-regular`}>
          <span
            className={`material-symbols-outlined scale-75 text-red align-bottom mr-regular`}
          >
            error
          </span>{" "}
          {t("trade-in.sign_up.email_alert")}
        </div>
      ) : null}
    </Fragment>
  );
}

EmailValidation.propTypes = {
  emailAlarm: PropTypes.bool,
};
