export const usePluralizer = (locale) => {
  return (count, singular) => {
    const pluralRules = new Intl.PluralRules(locale);
    const grammaticalNumber = pluralRules.select(count);

    switch (grammaticalNumber) {
      case "one":
      case "1":
      case 1:
        return `${count} ${singular}`;
      case `other`:
        return `${count} ${singular}s`;
      default:
        throw new Error(`Unknown: ${grammaticalNumber}`);
    }
  };
};
