import React from "react";
import { Button } from "components/button";
import BottomBar from "components/bottom-bar";
import { useTranslation } from "react-i18next";

export default function CheckIn1() {
  const { t } = useTranslation();

  const locationId = localStorage.getItem("locationId");
  const storeState = locationId === "30043" ? "atlanta" : "denver";
  return (
    <div className="flex flex-col h-[calc(100vh-81px)]">
      <div className="flex flex-grow flex-col items-center justify-center px-6 py-4">
        <h1 className="text-primary text-6xl text-center mb-regular">
          {t("store.check-in.title")}
        </h1>
        <p className="text-secondary text-center sm:max-w-sm mb-x-large">
          {t("store.check-in.get-started")}
        </p>
        <Button
          href={t("urls.store_check_in_qr")}
          className="w-full max-w-sm py-6 mb-6"
        >
          {t("store.check-in.scan-qr")}
        </Button>
        <Button
          variant="secondary"
          href={t("urls.store_check_in_email")}
          className="w-full max-w-sm py-6 mb-6"
        >
          {t("store.check-in.check-in-email")}
        </Button>
        <Button
          variant="secondary"
          href={`${t("urls.signup_store_qr_entrance").replace(
            ":l",
            storeState
          )}`}
          className="w-full max-w-sm py-6 mb-6"
        >
          {t("store.check-in.check-in-qr-code")}
        </Button>
      </div>
      <BottomBar />
    </div>
  );
}
