import React from "react";
import KioskStartButton from "../components/start-button";

export default function FinishConfirm({
  navigate,
  previousScreen,
  setprevCustomerLoggedWeights,
  loggedWeights,
}) {
  const handleFinish = () => {
    setprevCustomerLoggedWeights(loggedWeights);
    localStorage.removeItem("hasAnimated");
    navigate("start");
  };

  return (
    <div
      className="flex flex-col items-center justify-between w-full h-full relative"
      //   onClick={() => navigate("clear-tray")}
    >
      <p className="text-[4.8vh] text-center max-w-[50vh] text-white mt-[3vh] leading-[130%]">
        All done weighing your LEGO® bricks?
      </p>
      <div className="flex flex-col items-center w-full  relative z-10 gap-[1vh]">
        <div className="grid grid-cols-2 w-full gap-[48px] px-[4vh] relative z-10">
          <KioskStartButton
            className="relative z-50"
            onClick={() => navigate(previousScreen)}
            icon="arrow_back"
            iconColor="black"
          >
            Back
          </KioskStartButton>
          <KioskStartButton
            className={`relative z-50 font-bold`}
            onClick={handleFinish}
            icon={null}
            color="yellow"
          >
            Confirm
          </KioskStartButton>
        </div>
      </div>
    </div>
  );
}
