import React, { useEffect, useState } from "react";
import { Header } from "components/header";
import { Outlet } from "react-router-dom";
import ImpressumFooter from "components/footer-impressum";

export default function StoreSignupLayout() {
  console.log(localStorage.getItem("quickRegEmailInput"));
  const [bannerClosed, setBannerClosed] = useState(false);

  // Check if the banner is closed based on session storage
  const checkBannerStatus = () => {
    return sessionStorage.getItem("bannerClosed") === "true";
  };

  useEffect(() => {
    setBannerClosed(checkBannerStatus());
  }, []);

  useEffect(() => {
    const handleBannerStateChange = () => {
      setBannerClosed(checkBannerStatus());
    };

    window.addEventListener("bannerStateChanged", handleBannerStateChange);

    return () => {
      window.removeEventListener("bannerStateChanged", handleBannerStateChange);
    };
  }, []);

  return (
    <div className="flex flex-col min-h-screen overflow-scroll">
      <Header />
      <div
        className={`w-full ${bannerClosed ? "min-h-[calc(100vh-115px)] " : "min-h-[calc(100vh-199px)]"}`}
      >
        <Outlet />
      </div>
      <ImpressumFooter className="" />
    </div>
  );
}
