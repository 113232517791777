import { when } from "hooks/useWhen";
import { PAYMENT_ARGS } from "consts/feature-config";

export const calculateEstimate = (
  weight,
  country = "US",
  existingGiftRatio
) => {
  const showPayment = when(PAYMENT_ARGS);
  const giftRatio = existingGiftRatio || (country === `US` ? 6 : 12);
  const paymentRatio =
    country === `US` ? (showPayment ? 3.6 : 2.4) : showPayment ? 7.2 : 4.8;
  let payment, gift;

  if (country === "US") {
    payment = (weight * paymentRatio).toFixed(2);
    gift = (weight * giftRatio).toFixed(2);
  } else {
    payment = (weight * paymentRatio).toFixed(2);
    gift = (weight * giftRatio).toFixed(2);
  }
  return { gift, payment };
};
