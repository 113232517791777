import React from "react";
import { ReactComponent as EditSvg } from "../svgs/edit.svg";

export const EditTitleButton = ({
  title,
  onClick,
  justifyBetween,
  canEdit = true,
}) => {
  return (
    <div
      className={`flex flex-row items-center gap-2 ${justifyBetween ? "justify-between" : ""}`}
    >
      <p className="text-[#464C64] text-2xl">{title}</p>
      {canEdit && (
        <button onClick={onClick}>
          <EditSvg style={{ width: "1.5rem" }} />
        </button>
      )}
    </div>
  );
};
