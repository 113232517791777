import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "components/button";
import BottomBar from "components/bottom-bar";
import { useUserExists } from "api/use-user-exists";
import { isValidEmail } from "lib/utils";
import { Input } from "components/input";
import { useTranslation } from "react-i18next";

export default function CheckInEmail() {
  const { t } = useTranslation();
  const [combinedState, setCombinedState] = useState({
    submitting: false,
    email: "",
    error: false,
  });
  const navigate = useNavigate();
  const validEmail = isValidEmail(combinedState.email);
  const { exists, isLoading } = useUserExists({
    email: combinedState.email,
    skip: !combinedState.submitting,
  });
  const handleSubmit = async (event) => {
    event.preventDefault();
    setCombinedState((prev) => ({ ...prev, error: false, submitting: true }));
  };
  const handleEmailChange = (event) =>
    setCombinedState((prev) => ({ ...prev, email: event.target.value }));

  useEffect(() => {
    if (!isLoading && combinedState.submitting && exists !== undefined) {
      if (exists) {
        navigate(
          t("urls.store_check_in_tradeins_email").replace(
            ":email",
            encodeURIComponent(combinedState.email)
          )
        );
      } else {
        setCombinedState((prev) => ({
          ...prev,
          error: true,
          submitting: false,
        }));
      }
    }
  }, [
    combinedState,
    combinedState.email,
    combinedState.submitting,
    exists,
    isLoading,
    navigate,
    t,
  ]);

  useEffect(() => {
    setCombinedState((prev) => ({ ...prev, error: false }));
  }, [combinedState.email]);

  return (
    <form
      className="flex flex-col h-[calc(100vh-81px)]"
      onSubmit={handleSubmit}
    >
      <div className="flex flex-grow flex-col items-center justify-center px-6 py-4 max-w-md self-center">
        <h1 className="text-6xl font-bold text-center mb-2">
          {t("store.check-in.title")}
        </h1>
        <p className="text-center text-2xl text-secondary mb-x-large">
          Enter the email address the customer used to create the trade-in.
        </p>
        <div className="w-full mb-6">
          <Input
            label="Email Address"
            type="email"
            id="email"
            name="email"
            value={combinedState.email}
            onChange={handleEmailChange}
          />
          {combinedState.email && !validEmail && (
            <div className="text-secondary font-light mt-2">
              <span className="material-symbols-outlined scale-75 text-red align-bottom mr-1">
                error
              </span>
              Please enter a valid email.
            </div>
          )}
          {combinedState.error && !isLoading && (
            <div className="w-full bg-[#f1c6b2] border-l-8 border-red text-xl rounded-lg py-4 mt-8 px-4 flex flex-col">
              <h1 className="text-[#000000] text-2xl font-bold">
                Customer Not Found
              </h1>
              <p>
                No customer was found with the entered email address. Please
                check and try again.
              </p>
            </div>
          )}
          <Button
            className="w-full py-6 mt-6"
            disabled={!validEmail || combinedState.isLoading}
            type="submit"
          >
            Find Customer
          </Button>
          <div className="flex py-2 justify-center w-full">
            <a href="check-in" className="text-center text-[#1f6cb7] text-xl">
              Cancel
            </a>
          </div>
        </div>
      </div>
      <BottomBar />
    </form>
  );
}
