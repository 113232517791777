import React from "react";
import logo from "assets/logo.jpg";

export default function GeoRestrict() {
  return (
    <div className="flex min-h-screen flex-col items-center justify-center bg-background px-4 py-12 sm:px-6 lg:px-8">
      <div className="flex flex-col text-center items-center space-y-6 max-w-lg md:w-2/3 lg:w-1/2">
        <img
          src={logo}
          className="w-1/2 sm:w-1/4 lg:w-1/6 max-w-xs"
          alt="logo"
        />
        <h2 className="text-4xl font-bold tracking-tighter text-foreground sm:text-5xl lg:text-6xl">
          LEGO® Brick Take Back
        </h2>
        <span className="text-base font-light sm:text-lg lg:text-xl">
          The LEGO Brick Take Back program is currently only available in the US
          &amp; Germany.
        </span>
      </div>
    </div>
  );
}
