import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "components/button";
import BottomBar from "components/bottom-bar";
import WeightInput from "components/weight-input";
import { useTranslation } from "react-i18next";

export default function CreateShipmentForm() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const unit = `${t("scale.weight")}`;

  const [weight, setWeight] = useState("");
  const handleWeightChange = (e) => {
    setWeight(e.target.value);
  };

  const handleNextStep = () => {
    const searchParams = new URLSearchParams({ weight }).toString();
    navigate(`${t("urls.store_confirm_weight")}?${searchParams}`);
  };

  return (
    <div className="flex flex-col h-[calc(100vh-81px)]">
      <div className="flex flex-grow flex-col items-center justify-center px-6 py-4">
        <h1 className="text-6xl text-primary mb-regular">Create Shipment</h1>
        <p className="text-center sm:max-w-md text-2xl mb-x-large text-secondary">
          Create a shipping label and attach it securely to the container
          holding the combined bricks ready for shipment.
        </p>
        <div className="flex flex-col gap-4 mt-10 ">
          <WeightInput
            caption="Estimated Package Weight"
            unit={unit}
            value={weight}
            onChange={handleWeightChange}
          />
          <Button
            onClick={handleNextStep}
            className="w-full max-w-sm py-6 mb-2 mt-4"
            disabled={!weight}
          >
            Next
          </Button>
        </div>
      </div>
      <BottomBar />
    </div>
  );
}
