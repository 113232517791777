import { Button } from "components/button";
import { Header } from "components/header";
import React, { useContext, useEffect } from "react";
import AuthContext from "contexts/AuthContext";
import { useCurrentLocation } from "lib/utils";
import "material-symbols/outlined.css";
import { useTranslation } from "react-i18next";

//query params
import { LOCALE_NAME } from "consts/query-params";
import { STATE_NAME } from "consts/query-params";
import { COUNTRY_NAME } from "consts/query-params";

export default function Uat() {
  // translation
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  // auth
  const { user } = useContext(AuthContext);

  // Get location settings
  const { location: currentLocation } = useCurrentLocation();

  // change language with location
  useEffect(() => {
    if (currentLocation) {
      i18n.changeLanguage(currentLocation.locale);
    }
  }, [currentLocation, i18n]);

  return (
    <div>
      <Header />
      <div className="flex items-center justify-center bg-gray-100 pt-32">
        <div className="bg-white p-8 pt-0 rounded w-full max-w-xl">
          <h1 className="text-6xl font-semibold text-center mb-6">
            {t("landing.url")}
          </h1>
          <p className="text-center mb-8">
            Your location: {currentLocation.name}
          </p>
          <h2 className="text-6xl font-semibold text-center mb-6">
            {t("landing.your_options")}
          </h2>
          <div className="w-full bg-[#e3e3e3] flex rounded-lg mb-8">
            {currentLocation?.takebackMethod?.includes("mail") && (
              <div className="flex-1 flex p-3 justify-center">
                <span className="material-symbols-outlined text-[#757575] align-bottom mr-1">
                  package_2
                </span>
                {t("landing.method_mail")}
              </div>
            )}
            {currentLocation?.takebackMethod?.includes(`in_store_ga`) && (
              <div className="flex-1 flex p-3 justify-center">
                <span className="material-symbols-outlined text-[#757575] align-bottom mr-1">
                  storefront
                </span>
                {t("landing.method_store")}
              </div>
            )}
            {currentLocation?.takebackMethod?.includes(`in_store_co`) && (
              <div className="flex-1 flex p-3 justify-center">
                <span className="material-symbols-outlined text-[#757575] align-bottom mr-1">
                  storefront
                </span>
                {t("landing.method_store")}
              </div>
            )}
          </div>
          <div className="flex gap-4 w-full justify-center">
            <Button
              href={`${t("urls.trade_in_weight")}`}
              queryParams={{
                [LOCALE_NAME]: currentLocation.locale,
                [STATE_NAME]: currentLocation.state,
                [COUNTRY_NAME]: currentLocation.country,
              }}
            >
              {t("landing.button_start_new")}
            </Button>
            <Button
              href={user ? `${t("urls.my_trade_ins")}` : `${t("urls.login")}`}
            >
              {user
                ? t("landing.button_my_trade_ins")
                : t("landing.button_sign_in")}
            </Button>
          </div>
          <div className="flex flex-col gap-4 w-full justify-center mt-16">
            <Button
              variant="secondary"
              onClick={() => window.location.assign("/en-US?state=null")}
            >
              Change location to USA General
            </Button>
            <Button
              variant="secondary"
              onClick={() => window.location.assign("/en-US?state=GA")}
            >
              Change location to Georgia, US
            </Button>
            <Button
              variant="secondary"
              onClick={() => window.location.assign("/en-US?state=CO")}
            >
              Change location to Colorado, US
            </Button>
            <Button
              variant="secondary"
              onClick={() => window.location.assign("/de-DE")}
            >
              Change location to Deutschland
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
