import React, {
  useRef,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import QRCode from "react-qr-code";
import { useLocation, useNavigate } from "react-router-dom";
import { getQueryParams } from "lib/utils";
import { Button } from "components/button";
import { useTranslation } from "react-i18next";
import AuthContext from "contexts/AuthContext";
import ReactToPrint from "react-to-print";
import { useCreateShipping } from "api/use-shipping";

const coZip = "80401";

export default function GeneratePackageQRCode() {
  const location = useLocation();
  const queryParams = getQueryParams(location.search);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [shipment, setShipment] = useState(null);
  const requestSent = useRef(false);
  const pdfRef = useRef();
  const pdfRef2 = useRef();
  const timeGenerated = new Date().toLocaleString();

  const { weight, email } = queryParams;

  const handleSuccess = useCallback((data) => {
    setShipment(data);
  }, []);

  const { mutate: createShippingRecord, isLoading: isLoadingCreate } =
    useCreateShipping({
      onSuccess: handleSuccess,
      onError: (error) => {
        console.error("Error creating shipping record:", error);
        // Display error to the user
      },
    });

  useEffect(() => {
    if (
      !shipment &&
      !isLoadingCreate &&
      user.locationId &&
      email &&
      weight &&
      !requestSent.current
    ) {
      requestSent.current = true;
      createShippingRecord({
        storeZip: user.locationId,
        u_lego_shipped_by: email,
        u_lego_shipment_weight: weight,
        // u_lego_contained_takebacks // Include a list of IDs of takebacks in the shipment
      });
    }
  }, [
    shipment,
    isLoadingCreate,
    user.locationId,
    email,
    weight,
    createShippingRecord,
  ]);

  return (
    <div className="flex flex-col items-center justify-between h-[calc(100vh-81px)] p-6">
      <div className="my-auto items-center text-center">
        <div className="flex flex-col items-center justify-center h-full print:h-screen">
          <span className="text-2xl font-normal text-[#757575] mt-2 text-center">
            {t("Scan this QR code to get the package information.")}
          </span>
          <span className="text-2xl font-normal text-[#757575] mt-2 text-center">
            {t("Confirmed Weight")}: {weight}
          </span>
          <span className="text-2xl font-normal text-[#757575] mt-2 text-center">
            {t("Store Location")}:{" "}
            {user.locationId === coZip ? "Denver, CO" : "Atlanta, GA"}
          </span>
          <hr className="w-full p-6" />

          {!shipment && isLoadingCreate && (
            <p>{t("Generating shipping label...")}</p>
          )}

          <div className="flex gap-4">
            <div className="flex-1">
              <h2 className="font-semibold text-6xl mt-6">Package Label</h2>
              <div
                ref={pdfRef}
                className="qr-code-print flex flex-col items-center justify-center"
              >
                {shipment?.u_number && (
                  <div>
                    <p className="font-semibold">
                      Place this code on the outside of the package
                    </p>
                    <p className="font-normal">
                      {t("Time Generated")}: {timeGenerated}
                    </p>
                    <p className="font-normal">
                      {t("Store Location")}:{" "}
                      {user.locationId === coZip ? "Denver, CO" : "Atlanta, GA"}
                    </p>
                    <p className="font-normal">
                      {t("Shipment Reference")}: {shipment.u_number}
                    </p>
                    <QRCode
                      value={shipment?.u_number}
                      size={250}
                      className="mt-6"
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="flex-1">
              <h2 className="font-semibold text-6xl mt-6">Shipping Label</h2>
              <div
                ref={pdfRef2}
                className="shipping-label-print flex flex-col items-center justify-center"
              >
                {shipment?.shipping_label && (
                  <div className="w-full flex justify-center p-6 py-[200px]">
                    <img
                      src={`data:image/png;base64,${shipment?.shipping_label}`}
                      alt="shipping label"
                      className="rotate-90"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sticky bottom-0">
        <div className="flex justify-between gap-20 w-full px-large md:px-xxx-large md:pb-xx-large pt-large md:pt-0">
          <Button
            variant="secondary"
            onClick={() => navigate(t("urls.store_create_shipment"))}
            className="w-1/3"
          >
            {t("Back")}
          </Button>
          <ReactToPrint
            trigger={() => (
              <Button
                className="ml-6 w-1/3"
                disabled={!shipment && isLoadingCreate}
              >
                {t("Print Package Label")}
              </Button>
            )}
            content={() => pdfRef.current}
          />
          <ReactToPrint
            trigger={() => (
              <Button
                className="ml-6 w-1/3"
                disabled={!shipment && isLoadingCreate}
              >
                {t("Print Shipping Label")}
              </Button>
            )}
            content={() => pdfRef2.current}
          />
          <Button
            variant="secondary"
            onClick={() => navigate(t("urls.store_create_shipment"))}
            className="w-1/3"
          >
            {t("Done")}
          </Button>
        </div>
      </div>
    </div>
  );
}
