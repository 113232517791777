import React, { useCallback, useState, useMemo, useEffect } from "react";
import { ReactComponent as KeyboardSvg } from "../svgs/keyboard.svg";
import { useLocale } from "../useLocale";
import { LocaleWeightInput } from "./LocaleWeightInput";
import { GotoAllStepsButton } from "./GotoAllStepsButton";
import { clsx } from "clsx";
import { OnPageRejectButton } from "./OnPageRejectButton";

export const WeightCapture = ({
  title,
  instruction,
  value,
  onChange,
  dispatch,
}) => {
  const remoteScalesSupport = false;
  const [manualInput, setManualInput] = useState(!remoteScalesSupport);
  const [invalidInputError, setInvalidInputError] = useState("");
  const [separatorError, setSeparatorError] = useState("");

  const locale = useLocale();
  const numberFormatter = useMemo(
    () => new Intl.NumberFormat(locale, { style: "decimal" }),
    [locale]
  );

  const trackingInputCallback = useCallback(
    (inputElement) => {
      if (inputElement || manualInput) {
        inputElement?.focus();
      }
    },
    [manualInput]
  );

  useEffect(() => {
    const invalidInput = Number.isNaN(value);

    let timeoutId;

    if (separatorError) {
      setInvalidInputError(
        separatorError === "de-DE"
          ? "Please use commas to denote decimals. For example, use 1,1 instead of 1.1."
          : separatorError === "en-US"
            ? "Please use periods to denote decimals. For example, use 1.1 instead of 1,1."
            : ""
      );
    } else if (value !== "" && (invalidInput || value <= 0)) {
      timeoutId = setTimeout(() => {
        setInvalidInputError(
          invalidInput
            ? "Please enter a valid number"
            : "Please enter a weight greater than 0"
        );
      }, 1500);
    } else {
      setInvalidInputError("");
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [value, separatorError]);

  return (
    <div className="flex gap-8 flex-col p-8">
      <div className="w-full flex flex-col">
        <div className="flex flex-row justify-between">
          <h1 className="text-6xl text-[#282F44]">{title}</h1>
          <div className="">
            {remoteScalesSupport && (
              <button
                className="p-6 bg-white rounded-lg"
                onClick={() => {
                  setManualInput(!manualInput);
                }}
              >
                <KeyboardSvg style={{ width: "24px", height: "24px" }} />
              </button>
            )}
            <GotoAllStepsButton dispatch={dispatch} />
          </div>
        </div>
        <p className="text-4xl font-medium text-[#464C64] max-w-[80%]">
          {instruction}
        </p>
      </div>
      <div
        className="grid grid-cols-2 w-full gap-8"
        style={
          !manualInput
            ? { height: "0", opacity: "0", position: "fixed", top: -1000 }
            : {}
        }
      >
        <div className="" />
        <div className="flex flex-col gap-2">
          <div className="flex flex-row justify-center items-center w-full">
            <LocaleWeightInput
              ref={trackingInputCallback}
              className={clsx(
                "rounded-l-lg p-4 border-2 border-[#C6CCE8] focus:outline-none focus:ring-0 text-5xl font-normal w-full",
                {
                  "border-[#C12960]": invalidInputError,
                  "border-[#C6CCE8]": !invalidInputError,
                  "bg-[#F9DAE3]": invalidInputError,
                }
              )}
              defaultValue={value}
              locale={locale}
              onChange={(val) => onChange(val)}
              separatorError={separatorError}
              setSeparatorError={setSeparatorError}
            />
            <div className="w-[6rem] h-full flex items-center justify-center rounded-r-lg border-2 border-l-0 border-[#C6CCE8]">
              Kg
            </div>
          </div>
          {invalidInputError && (
            <p className="text-xl h-4 flex flex-row gap-2 align-top mb-large text-[#C12960]">
              {invalidInputError}
            </p>
          )}
        </div>
      </div>
      <div className="grid grid-cols-2 w-full gap-8">
        <OnPageRejectButton dispatch={dispatch} />
        <button
          className="rounded-lg bg-[#173991] disabled:cursor-not-allowed disabled:bg-[#C6CCE8] text-white py-4 w-full m-0"
          disabled={value === "" || value <= 0 || Number.isNaN(value)}
          onClick={() => {
            dispatch({ type: "next" });
          }}
        >
          Record Weight
          <span style={{ display: manualInput ? "none" : "inline" }}>
            {" "}
            - {numberFormatter.format(value)} kg
          </span>
        </button>
      </div>
    </div>
  );
};
