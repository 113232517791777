import { useMemo } from "react";

export const useLocale = () => {
  const locale = useMemo(() => {
    const path = window.location.pathname;
    const match = path.match(/\/(de-DE|en-US)\//);
    return match ? match[1] : "en-US";
  }, []);

  return locale;
};
