import React from "react";
import PropTypes from "prop-types";

const DataTable = ({ data }) => {
  return (
    <div className="overflow-x-auto">
      <table className="table-auto w-full mt-regular border-collapse">
        <thead>
          <tr className="bg-gray-100 text-xs font-normal">
            <th className="text-left pl-large px-regular py-large border-b border-gray-300">
              ID
            </th>
            <th className="text-left px-regular py-large border-b border-gray-300">
              STATUS
            </th>
            <th className="text-left px-regular py-large border-b border-gray-300">
              DATE CREATED
            </th>
            <th className="text-left px-regular py-large border-b border-gray-300">
              QUANTITY
            </th>
            <th className="text-left pr-large px-regular py-large border-b border-gray-300">
              VALUE
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr
              key={index}
              onClick={item.onClick}
              className="hover:bg-slate-50 cursor-pointer"
            >
              <td className="text-left pl-large px-regular py-large border-b border-gray-300">
                {item.id}
              </td>
              <td className="text-left px-regular py-large border-b border-gray-300">
                {item.status}
              </td>
              <td className="text-left px-regular py-large border-b border-gray-300">
                {item.dateCreated}
              </td>
              <td className="text-left px-regular py-large border-b border-gray-300">
                {item.quantity}lbs
              </td>
              <td className="text-left pr-large px-regular py-large border-b border-gray-300">
                {item.value}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

DataTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      dateCreated: PropTypes.string.isRequired,
      quantity: PropTypes.number.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default DataTable;
