import { cn } from "lib/utils";

export default function MessageBanner({
  colour = "red",
  title,
  body,
  className,
  ...props
}) {
  return (
    <div
      className={cn(
        `bg-${colour}-300 flex rounded-lg w-full h-full overflow-hidden`,
        className
      )}
      {...props}
    >
      <div className={`h-full w-[5px] bg-${colour} shrink-0`}></div>
      <div className="flex flex-col p-4">
        {title && <div className="font-semibold">{title}</div>}
        {body && <div className="text-secondary text-sm">{body}</div>}
      </div>
    </div>
  );
}
