import weightRestrictions from "data/weight-restrictions.json";

/**
 * Validates if the weight is within the acceptable range for the given return method (mail or instore) and unit (kg or lbs)
 * @param {number} weight - The weight to validate
 * @param {string} method - The return method ("mail" or "instore")
 * @param {string} unit - The unit of weight ("kg" or "lbs")
 * @returns {boolean} - Returns true if the weight is valid, otherwise false
 */

export function isValidWeight(weight, method, unit) {
  // Check if the weight is zero or negative
  if (weight <= 0) {
    console.error("Weight must be a positive number");
    return false;
  }

  const restriction = weightRestrictions.find((r) => r.unit === unit);

  if (!restriction) {
    console.error(`Unsupported unit: ${unit}`);
    return false;
  }

  // Select the minimum and maximum based on method (mail or instore)
  const min = method === "mail" ? restriction.minMail : restriction.minInstore;
  const max = method === "mail" ? restriction.maxMail : restriction.maxInstore;

  // Check if the weight is valid
  return weight > min && weight <= max;
}

/**
 * Validates if the payout method is valid based on the provided valid payout methods.
 * @param {string} payoutMethod - The payout method to validate.
 * @param {Array<string>} validMethods - An array of valid payout methods.
 * @returns {boolean} - Returns true if the payout method is valid, otherwise false.
 */
export function isValidPayoutMethod(payoutMethod, validMethods) {
  return validMethods.includes(payoutMethod);
}
