import React from "react";
import { motion, AnimatePresence } from "framer-motion";

const colors = [
  "#00963E",
  "#EE7500",
  "#FFD500",
  "#F5B4D2",
  "#2CAFE5",
  "#E3000B",
  "#004A80",
];

const Brick = ({
  style,
  rotation,
  size,
  isVisible,
  isFalling = false,
  transitionDelay,
}) => {
  const randomColor = colors[Math.floor(Math.random() * colors.length)];
  const noduleCount = size === "one" ? 1 : size === "two" ? 2 : 4;
  const rotateDirection = Math.random() < 0.5 ? -1 : 1;
  const exitRotation = Math.random() * 20 * rotateDirection;
  const exitDuration = 0.5 + Math.random() * 1;

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          className={`falling-brick ${size}`}
          style={{ ...style, backgroundColor: randomColor }}
          initial={{
            opacity: isFalling ? 1 : 0,
            rotate: isFalling ? rotation : rotation - 10 * rotateDirection,
            y: isFalling ? 0 : -50,
            scale: isFalling ? 1 : 1.1,
          }}
          animate={{
            opacity: 1,
            rotate: rotation,
            y: 0,
            scale: 1,
          }}
          exit={{
            y: "100vh",
            rotate: rotation + exitRotation,
            transition: {
              duration: exitDuration,
              ease: "easeIn",
            },
          }}
          transition={{
            type: "spring",
            damping: 25,
            stiffness: 100,
            duration: 0.5,
            delay: Math.random() * transitionDelay,
          }}
        >
          {[...Array(noduleCount)].map((_, index) => (
            <div
              key={index}
              className="nodule"
              style={{ backgroundColor: randomColor }}
            />
          ))}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Brick;
