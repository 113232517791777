import React, { useEffect, useState } from "react";
import KioskStartButton from "../components/start-button";
import FallingBricks from "../components/falling-bricks";

export default function PlaceBricks({
  navigate,
  inputWeight,
  previousScreen,
  removeLastLoggedWeight,
}) {
  const [showBricks, setShowBricks] = useState(false);

  useEffect(() => {
    setShowBricks(true);
    if (inputWeight !== 0) {
      if (localStorage.getItem("hasAnimated")) {
        localStorage.removeItem("hasAnimated");
      }
      navigate("scale");
    }
  }, [inputWeight, navigate]);

  // const toggleVisibility = () => {
  //   setShowBricks(!showBricks);
  // };

  return (
    <div className="flex flex-col items-center justify-between w-full h-full relative">
      <p className="text-[4.5vh] text-center max-w-[50vh] text-white mt-[3vh] px-[4vh] leading-[130%] relative z-10">
        Place your
        <br />
        LEGO® bricks onto the scale
      </p>
      <div className="w-full max-h-[1000px] z-1">
        <FallingBricks
          isVisible={showBricks}
          brickCount={50}
          transitionDelay={0}
        />
      </div>
      <KioskStartButton
        className="relative z-10 w-1/4"
        onClick={() => {
          // toggleVisibility();
          navigate(previousScreen === "terms" ? "start" : previousScreen);
        }}
      >
        Cancel
      </KioskStartButton>
    </div>
  );
}
