import { localizeDecimalSeparator, useCurrentLocation } from "lib/utils";
import { useTranslation } from "react-i18next";

export default function useLocalizedCurrencyValue(rawValue) {
  const { t } = useTranslation();
  const { location: currentLocation } = useCurrentLocation();

  const formattedValue =
    currentLocation?.locale === "de-DE"
      ? `${localizeDecimalSeparator(rawValue)} ${t("currency.symbol")}`
      : `${t("currency.symbol")}${rawValue}`;

  return formattedValue;
}
