import { useWarehouseContext } from "./useWarehouseContext";
import { Footer } from "./components/Footer";
import { WeightCapture } from "./components/WeightCapture";
import { StepHeader } from "./components/StepHeader";
import { FullscreenModal } from "./components/FullscreenModal";

const TITLE = "Content Weight";

export const ContentWeightCapture = ({ dispatch }) => {
  const { contentWeight, setContentWeight } = useWarehouseContext();

  return (
    <FullscreenModal>
      <StepHeader dispatch={dispatch} />

      <Footer>
        <WeightCapture
          title={TITLE}
          instruction="Empty the package's contents into the control container without any external packaging."
          value={contentWeight}
          onChange={(weight) => setContentWeight(weight)}
          dispatch={dispatch}
        />
      </Footer>
    </FullscreenModal>
  );
};

ContentWeightCapture.title = TITLE;
