import { AnimatePresence, motion } from "framer-motion";
import { CapturePackagePhotos } from "../CapturePackagePhotos";
import { useWarehouseContext } from "../useWarehouseContext";
import React, { useState } from "react";
import { Button } from "components/button";
import { ReactComponent as WarningSvg } from "../svgs/warning.svg";

export const OnPageRejectButton = ({ dispatch }) => {
  const { packagePictures } = useWarehouseContext();
  const [showModal, setShowModal] = useState(false);

  const handleRejectClick = () => {
    if (packagePictures.length === 0) {
      setShowModal(true);
    } else {
      dispatch({ type: "reject" });
    }
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleAddPhotos = () => {
    dispatch({
      type: "jump-to",
      props: {
        StepComponent: CapturePackagePhotos,
        completed: false,
      },
    });
  };

  return (
    <>
      <button
        className="rounded-lg text-red py-4 w-full m-0 border-2 border-red"
        onClick={handleRejectClick}
      >
        Reject
      </button>
      <AnimatePresence>
        {showModal && (
          <>
            <motion.div
              className="fixed inset-0 bg-[#0B1C47] z-30"
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.6 }}
              exit={{ opacity: 0 }}
            />
            <motion.div
              className="fixed inset-0 flex items-center justify-center z-40"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <div className="bg-white p-6 rounded-xl shadow-lg w-full max-w-lg max-h-[95vh] overflow-auto z-20">
                <div className="flex flex-row items-center gap-2 mb-4">
                  <WarningSvg
                    style={{
                      width: "32px",
                      height: "32px",
                      color: "#D8A924",
                    }}
                  />
                  <h2 className="text-[24px] text-[#282F44] font-bold">
                    No Package Photos
                  </h2>
                </div>
                <p className="text-[18px] text-[#464C64] font-light mb-6">
                  Please add Package Photos before rejecting the package.
                </p>
                <div className="flex justify-between gap-6">
                  <Button
                    onClick={handleCancel}
                    variant="secondary"
                    className="w-full border-2 rounded-xl text-[21px] font-normal h-[72px] p-6 border-[#DAE3F9]"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleAddPhotos}
                    className="w-full rounded-xl text-[21px] p-6 bg-[#173991]"
                  >
                    Add Photos
                  </Button>
                </div>
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </>
  );
};
