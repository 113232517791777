import Cash from "assets/cashPict.jpg";
import GiftCard from "assets/giftcardPict.jpg";
import useLocalizedCurrencyValue from "hooks/useLocalizedCurrencyValue";
import { calculateEstimate } from "lib/calculateEstimate";
import { useCurrentLocation } from "lib/utils";
import { useTranslation } from "react-i18next";

export default function RewardOverview() {
  const { location: currentLocation } = useCurrentLocation();
  const pricePerUnit = calculateEstimate(1, currentLocation?.country);
  const pricePerUnitGift = useLocalizedCurrencyValue(pricePerUnit.gift);
  const pricePerUnitCash = useLocalizedCurrencyValue(pricePerUnit.payment);

  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-x-large w-full">
      <div className="font-bold">{t("ui.reward_overview.title")}</div>
      <div className="flex flex-col gap-medium w-full">
        <div className="flex w-full items-center flex-nowrap gap-[16px]">
          <img
            src={GiftCard}
            alt="Cash Icon"
            className="w-[56px] aspect-[16/10] rounded-xs"
          />
          <div className="flex flex-col">
            <div>{t("ui.reward_overview.giftcard_title")}</div>
            <div className="text-sm text-black-300">
              {t("ui.reward_overview.giftcard_value", {
                value: pricePerUnitGift,
              })}
            </div>
          </div>
        </div>
        <div className="flex items-center gap-[4px]">
          <div className="w-[16px] h-[1px] bg-black-300/50"></div>
          <div>{t("ui.reward_overview.or")}</div>
          <div className="w-[16px] h-[1px] bg-black-300/50"></div>
        </div>
        <div className="flex w-full items-center flex-nowrap gap-[16px]">
          <img
            src={Cash}
            alt="Cash Icon"
            className="w-[56px] aspect-[16/10] rounded-xs"
          />
          <div className="flex flex-col">
            <div>{t("ui.reward_overview.cash_title")}</div>
            <div className="text-sm text-black-300">
              {t("ui.reward_overview.cash_value", {
                value: pricePerUnitCash,
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
