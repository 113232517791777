import { useContext } from "react";
import AuthContext from "../contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { isAdminEmail } from "../consts/user";

export const ProtectedRoute = ({ children }) => {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();

  const isStoreRoute = window.location.pathname.includes("/store");

  if (!user) {
    return <Navigate to={t("urls.login")} />;
  }

  if (isStoreRoute && !isAdminEmail(user.email)) {
    return <Navigate to="/" />;
  }

  return children;
};
