import { Footer } from "./components/Footer";
import { SortBucket } from "./components/SortBucket";
import { useWarehouseContext } from "./useWarehouseContext";
import { SORTED_PICTURES } from "./WarehouseProvider";
import React from "react";
import { StepHeader } from "./components/StepHeader";
import { GotoAllStepsButton } from "./components/GotoAllStepsButton";
import { FullscreenModal } from "./components/FullscreenModal";
import { OnPageRejectButton } from "./components/OnPageRejectButton";

const TITLE = "Sort Contents";

export const SortContents = ({ dispatch }) => {
  const { sortedPictures, setSortedPicture } = useWarehouseContext();

  return (
    <FullscreenModal>
      <StepHeader dispatch={dispatch} showModal={true} />
      <div className="flex items-center justify-center w-full">
        <div className="grid grid-cols-3 grid-rows-2 p-8 gap-y-8">
          <SortBucket
            dispatch={dispatch}
            onPhotoCaptured={(picture) => {
              setSortedPicture(SORTED_PICTURES.all, picture);
            }}
            picture={sortedPictures[SORTED_PICTURES.all]}
            heading="All Non-Conform Buckets"
            description="Overview Picture"
          />
          <SortBucket
            dispatch={dispatch}
            onPhotoCaptured={(picture) => {
              setSortedPicture(SORTED_PICTURES.bucket2, picture);
            }}
            picture={sortedPictures[SORTED_PICTURES.bucket2]}
            heading="Bucket 2"
            description="Assembled Non-conform"
          />
          <SortBucket
            dispatch={dispatch}
            onPhotoCaptured={(picture) => {
              setSortedPicture(SORTED_PICTURES.bucket3a, picture);
            }}
            picture={sortedPictures[SORTED_PICTURES.bucket3a]}
            heading="Bucket 3A"
            description="DUPLO, clean, good quality, disassembled "
          />
          <SortBucket
            dispatch={dispatch}
            onPhotoCaptured={(picture) => {
              setSortedPicture(SORTED_PICTURES.bucket3b, picture);
            }}
            picture={sortedPictures[SORTED_PICTURES.bucket3b]}
            heading="Bucket 3B"
            description="Poor quality / dirty LEGO and DUPLO"
          />
          <SortBucket
            dispatch={dispatch}
            onPhotoCaptured={(picture) => {
              setSortedPicture(SORTED_PICTURES.bucket3c, picture);
            }}
            picture={sortedPictures[SORTED_PICTURES.bucket3c]}
            heading="Bucket 3C"
            description="Any electronic, battery, sharp"
          />
          <SortBucket
            dispatch={dispatch}
            onPhotoCaptured={(picture) => {
              setSortedPicture(SORTED_PICTURES.bucket3d, picture);
            }}
            picture={sortedPictures[SORTED_PICTURES.bucket3d]}
            heading="Bucket 3D"
            description="Any non-LEGO item"
          />
        </div>
      </div>
      <Footer>
        <div className="flex gap-8 flex-col p-8 pt-4">
          <div className="w-full flex flex-col">
            <div className="flex flex-row gap-4 justify-between mb-2">
              <h1 className="text-6xl text-[#282F44] flex flex-row items-center gap-2">
                {TITLE}
              </h1>
              <GotoAllStepsButton dispatch={dispatch} />
            </div>
            <p className="text-4xl font-medium text-[#464C64]">
              Sort the materials into the non-conform containers and take
              pictures of the non-empty ones.
            </p>
          </div>

          <div className="flex flex-row w-full gap-8">
            <OnPageRejectButton dispatch={dispatch} />

            <button
              className="rounded-lg bg-[#173991] text-white py-4 w-full m-0"
              disabled={false}
              onClick={() => {
                dispatch({ type: "next" });
              }}
            >
              Continue
            </button>
          </div>
        </div>
      </Footer>
    </FullscreenModal>
  );
};

SortContents.title = TITLE;
