import { useState, useEffect, useCallback, useRef } from "react";

const { REACT_APP_API } = process.env;

export const useConnectionStatus = (options = {}) => {
  const {
    pingURL = `${REACT_APP_API}/health`,
    checkInterval = 5000,
    timeout = 2000,
    onOffline,
    onOnline,
  } = options;

  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const controllerRef = useRef(null);
  const timeoutRef = useRef(null);

  const handleConnectionChange = useCallback(
    (online) => {
      setIsOnline(online);
      if (online) {
        onOnline?.();
      } else {
        onOffline?.();
      }
    },
    [onOnline, onOffline]
  );

  const checkConnection = useCallback(async () => {
    // Clear any existing timeout and abort controller
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    if (controllerRef.current) controllerRef.current.abort();

    // Create new abort controller for this request
    controllerRef.current = new AbortController();

    try {
      // Set timeout to abort request after specified time
      timeoutRef.current = setTimeout(() => {
        if (controllerRef.current) {
          controllerRef.current.abort();
        }
      }, timeout);

      const response = await fetch(pingURL, {
        method: "HEAD",
        mode: "cors",
        signal: controllerRef.current.signal,
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
        },
      });

      clearTimeout(timeoutRef.current);

      if (response.ok) {
        handleConnectionChange(true);
      } else {
        handleConnectionChange(false);
      }
    } catch (error) {
      handleConnectionChange(false);
    }

    // Schedule next check
    timeoutRef.current = setTimeout(checkConnection, checkInterval);
  }, [pingURL, timeout, checkInterval, handleConnectionChange]);

  useEffect(() => {
    const handleOnline = () => checkConnection();
    const handleOffline = () => handleConnectionChange(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    // Start the initial check
    checkConnection();

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      if (controllerRef.current) controllerRef.current.abort();
    };
  }, [checkConnection, handleConnectionChange]);

  return isOnline;
};
