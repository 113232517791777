import React from "react";

export default function CloseButton({ onClick }) {
  return (
    <div className="ml-auto mb-auto">
      <button
        onClick={onClick}
        type="button"
        class="bg-gray-300 rounded-full p-2 inline-flex items-center justify-center text-[#000000] focus:outline-none focus:ring-2 focus:ring-inset"
      >
        <span class="sr-only">Close menu</span>
        <svg
          class="h-6 w-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
    </div>
  );
}
