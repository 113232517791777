import { useContext } from "react";
import { useMutation } from "react-query";
import AuthContext from "contexts/AuthContext";

const { REACT_APP_API } = process.env;

const uploadImages = async ({ accessToken, payload, timeout = 120000 }) => {
  const controller = new AbortController();
  const timeoutId = setTimeout(
    () => controller.abort({ message: "Request timed out" }),
    timeout
  );

  const { ignore412, ...patchPayload } = payload;

  const response = await fetch(`${REACT_APP_API}/v2/takeback/upload-images`, {
    signal: controller.signal,
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(patchPayload),
  });

  clearTimeout(timeoutId);

  if (!response.ok) {
    const body = await response.text();

    if (response.status === 412) {
      const errorMessage =
        "Takeback already processed and verified. If you think this is a mistake please contact support.";

      if (ignore412) {
        return {
          code: response.status,
          message: errorMessage,
        };
      }

      throw new Error(errorMessage);
    }

    throw new Error(`Failed to upload images: ${body}`);
  }

  return { code: 200, message: "OK" };
};

export const useUploadImages = (options = {}) => {
  const { user, authTokens } = useContext(AuthContext);
  const username = user?.email;
  const accessToken = authTokens?.access_token;
  const { timeout, ...reactQueryOptions } = options;

  return useMutation({
    mutationKey: ["upload-images", username, accessToken],
    mutationFn: (payload) =>
      uploadImages({
        accessToken,
        payload,
        timeout,
      }),
    ...reactQueryOptions,
  });
};
