import { createContext, useEffect, useState } from "react";
import { useLocalForage } from "../hooks/useLocalForage";
import { useUploadImages } from "../api/useUploadImages";
import { useUpdateTakeback } from "../api/use-takebacks";
import { useConnectionStatus } from "../hooks/useConnectionStatus";

export const TakebackQueueContext = createContext({});

export const TakebackQueueProvider = ({ children }) => {
  const [queue, setQueue] = useLocalForage("takebackQueue");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isOnline = useConnectionStatus();

  const { mutateAsync: uploadImages } = useUploadImages({
    timeout: 180000,
    retry: false,
  });
  const { mutateAsync: updateTakeback } = useUpdateTakeback({
    retry: false,
    timeout: 60000,
    useV2: true,
  });

  useEffect(() => {
    if (!isSubmitting && isOnline && queue?.length > 0) {
      setIsSubmitting(true);

      const submitTakeback = async ({
        caseId,
        imagesToUpload,
        isRejected = false,
        ignore412 = false,
        ...payload
      } = {}) => {
        if (imagesToUpload.length > 0) {
          await uploadImages({
            caseId,
            imagesMap: imagesToUpload,
            ignore412,
          });
        }

        return await updateTakeback({ caseId, ...payload, ignore412 });
      };

      const nextTakebackSubmission = queue[0];

      submitTakeback({ ...nextTakebackSubmission, ignore412: true })
        .then(() => {
          // success so remove it from the queue now
          setQueue(
            queue.filter(
              (takebackSubmission) =>
                takebackSubmission !== nextTakebackSubmission
            )
          );
        })
        .catch((error) => {
          console.log("failed submission", error);
          setQueue([...queue.slice(1), queue[0]]);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  }, [isOnline, isSubmitting, queue, setQueue, updateTakeback, uploadImages]);

  const add = (takebackSubmission) => {
    // EDGE-CASE, should the takeback caseId already be in the queue, then ignore this new one because it means
    // the user is trying to re-do a takeback which isn't allowed!
    if (!queue?.some(({ caseId }) => caseId === takebackSubmission.caseId)) {
      setQueue([...(queue ?? []), takebackSubmission]);
    }
  };

  return (
    <TakebackQueueContext.Provider value={{ add, queueCount: queue?.length }}>
      {children}
    </TakebackQueueContext.Provider>
  );
};
