import { ReactComponent as StepsSvg } from "../svgs/steps.svg";
import React from "react";

export const GotoAllStepsButton = ({ dispatch }) => {
  return (
    <button
      className="p-6 bg-white rounded-lg border-2 border-[#DAE3F9]"
      onClick={() => {
        dispatch({ type: "open-all-steps" });
      }}
    >
      <StepsSvg style={{ width: "24px", height: "24px" }} />
    </button>
  );
};
