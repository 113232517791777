import React, { useEffect, useState } from "react";
import CryptoJS from "crypto-js";
import { QrReader } from "react-qr-reader";
import { useNavigate, useLocation } from "react-router-dom";
import { getQueryParams } from "lib/utils";
import FormContainer from "components/form-container";
import { useTranslation } from "react-i18next";
import { SIGNING_KEY } from "pages/scales/scales";

export default function TradeInWeight() {
  const [scannedQrData, setScannedQrData] = useState();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const location = useLocation();
  const queryParams = getQueryParams(location.search);

  useEffect(() => {
    if (queryParams.w) {
      setScannedQrData(queryParams.w);
    }
  }, [queryParams.w]);

  const destination = t("urls.store_payout");

  useEffect(() => {
    if (scannedQrData) {
      const updatedParams = { ...queryParams, w: scannedQrData };
      const searchParams = new URLSearchParams(updatedParams).toString();
      navigate(`${destination}?${searchParams}`);
      navigate(0);
    }
    return () => setScannedQrData();
  }, [scannedQrData, navigate, queryParams, destination]);

  const updatedParams = { ...queryParams, w: scannedQrData };

  return (
    <FormContainer submitParams={updatedParams} submitHref={destination}>
      <div className="flex flex-col flex-grow py-4">
        <QrReader
          constraints={{
            facingMode: "environment",
          }}
          containerStyle={{
            width: "100%",
            height: "100%",
          }}
          videoContainerStyle={{
            width: "100%",
            height: "100%",
          }}
          videoStyle={{
            width: "100%",
            height: "100%",
          }}
          onResult={(result) => {
            if (result?.text) {
              const [weight, signiture] = result.text.split(`_`);

              const signedWeight = CryptoJS.SHA256(
                `${weight}_${SIGNING_KEY}`
              ).toString(CryptoJS.enc.Hex);

              if (signiture === signedWeight) {
                setScannedQrData(weight);
              }
            }
          }}
        />

        <div className="absolute bottom-0 w-full bg-black bg-opacity-50 text-white text-center py-4">
          {t("ui.scan_qr_code")}
        </div>
      </div>
    </FormContainer>
  );
}
