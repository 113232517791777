import { useEffect, useContext, useRef, useState } from "react";
import FormContainer from "components/form-container";
import ProgressBar from "components/progress-bar";
import {
  useLocation,
  useOutletContext,
  createSearchParams,
} from "react-router-dom";
import { getQueryParams } from "lib/utils";
import { useRestartIfAbsent } from "hooks/useRestartIfAbsent";
import { useTranslation, Trans } from "react-i18next";
import { TAKEBACK_METHOD_NAME } from "consts/query-params";
import { Button } from "components/button";
import AuthContext from "contexts/AuthContext";

export default function VerificationPage() {
  const { t } = useTranslation();
  const emailInput = localStorage.getItem("emailInput");
  const { resendEmailVerificationHandler } = useContext(AuthContext);
  const [isResending, setIsResending] = useState(false);

  // reset right side visual
  const { setSidebarVisual } = useOutletContext();
  useEffect(() => {
    setSidebarVisual("man");
  }, [setSidebarVisual]);

  // Get query params and restart flow if absent
  const location = useLocation();
  const queryParams = getQueryParams(location.search);
  useRestartIfAbsent(
    queryParams,
    ["w", TAKEBACK_METHOD_NAME, "p"],
    t("urls.trade_in_weight")
  );
  const resendSuccessful = useRef(false);

  const handleResendEmail = () => {
    setIsResending(true);
    const params = queryParams
      ? createSearchParams(queryParams).toString()
      : "";
    resendEmailVerificationHandler(
      {
        email: emailInput,
        redirectTo: `${window.location.origin}${t(
          `urls.trade_in_sign_in`
        )}?${params}`,
      },
      {
        onSuccess: () => {
          resendSuccessful.current = true;
          setIsResending(false);
        },
      },
      {
        onError: (error) => {
          console.error("Failed to resend verification email", error.message);
        },
      }
    );
  };

  return (
    <FormContainer>
      <ProgressBar step={5} />
      <div className="flex flex-col gap-regular">
        <h2 className="font-semibold text-6xl max-w-lg">
          {t("trade-in.verification.title")}
        </h2>
        <p className="text-[#7E7D7D] text-2xl leading-normal font-light">
          <Trans
            i18nKey="trade-in.verification.description"
            components={{ email: emailInput }}
          />
        </p>
        <Button
          variant="default"
          type="button"
          className={`w-full ${
            isResending && "opacity-50 hover:bg-blue"
          } md:w-1/2 mx-auto md:ml-0 mt-8`}
          onClick={handleResendEmail}
        >
          <div className="flex flex-row items-center">
            {isResending ? (
              <div className="h-6 w-6 animate-spin mr-4 rounded-full border-b-2 border-t-2 border-blue-500"></div>
            ) : null}
            <span>{t("trade-in.verification.resend")}</span>
          </div>
        </Button>
        {resendSuccessful.current && (
          <div className="w-full mt-10 flex flex-col bg-[#C0DFC3] border-l-4 px-4 py-3 border-[#00963E] rounded-md">
            <h2 className="font-semibold text-2xl max-w-lg">
              {t("trade-in.verification.resend-title")}
            </h2>
            <span className="max-w-lg text-base font-light leading-normal">
              <Trans
                i18nKey="trade-in.verification.resend-description"
                components={{ email: emailInput }}
              />
            </span>
          </div>
        )}
      </div>
    </FormContainer>
  );
}
