import React from "react";
import TradeInCard from "components/trade-in-card";
import BottomBar from "components/bottom-bar";
import SearchBar from "components/search-tradeins";
import { useMyTakebacks } from "api/use-takebacks";
import { useTranslation } from "react-i18next";

export default function TradeIns() {
  const { t } = useTranslation();

  const { data: tradeins = [] } = useMyTakebacks();

  const [search, setSearch] = React.useState("");

  const locationId = localStorage.getItem("locationId");
  const storeState = locationId === "30043" ? "in_store_ga" : "in_store_co";

  const filteredTradeIns = tradeins.filter(
    ({ u_lego_return_type: returnType }) => returnType === storeState
  );

  const formattedTradeIns = filteredTradeIns.filter((tradein) => {
    const searchLower = search.toLowerCase();
    return (
      tradein.consumer?.firstName?.toLowerCase().includes(searchLower) ||
      tradein.consumer?.lastName?.toLowerCase().includes(searchLower) ||
      tradein.consumer?.email?.toLowerCase().includes(searchLower) ||
      tradein.u_lego_presort_weight
        ?.toString()
        .toLowerCase()
        .includes(searchLower) ||
      tradein.number?.toLowerCase().includes(searchLower) ||
      tradein.description?.toLowerCase().includes(searchLower)
    );
  });

  return (
    <div className="flex flex-col px-6 pt-4 pb-28 items-center min-h-full">
      <div className="flex flex-col mr-auto">
        <div>
          <h1 className="text-6xl pb-2 font-bold">
            {t("store.trade-ins.title")}
          </h1>
        </div>
      </div>
      <div className="flex flex-col items-left justify-between w-full">
        <span className="text-regular text-primary">
          {t("store.trade-ins.search")}
        </span>
        <SearchBar value={search} onChange={(e) => setSearch(e.target.value)} />
      </div>
      <div className="grid w-full gap-large">
        {formattedTradeIns.map((tradein, index) => (
          <TradeInCard
            key={index}
            name={`${tradein.consumer?.firstName ?? ""} ${
              tradein.consumer?.lastName ?? ""
            }`}
            weight={tradein.u_lego_presort_weight}
            completed={tradein.u_lego_value_currency !== "0"}
          />
        ))}
      </div>
      <BottomBar />
    </div>
  );
}
