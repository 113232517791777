import React from "react";
import { CircularProgress } from "@mui/material";
import { createPortal } from "react-dom";

export const BusyFullscreen = ({ message }) => {
  return createPortal(
    <div className="fixed inset-0 bg-[#060E23] bg-opacity-60 h-full w-full">
      <div className="flex flex-col items-center justify-center gap-4 w-full h-full">
        <CircularProgress size={48} />
        <p className="text-tertiary text-2xl text-white">{message}</p>
      </div>
    </div>,
    document.body
  );
};
