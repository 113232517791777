import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectGroup,
  SelectItem,
} from "components/select";
import { Input } from "components/input";
import { Label } from "components/label";
import usStates from "data/us-states.json";
import availableCountries from "data/countries.json";
import SearchIcon from "assets/icons/search.svg";
import { useDebouncedCallback } from "use-debounce";
import GoogleLogo from "assets/google.png";
import { getPlaceDetails, usePlacesSearch } from "api/use-address-autocomplete";
import { v4 as uuidv4 } from "uuid"; // import UUID generator
import AuthContext from "contexts/AuthContext";
import { PulseLoader } from "react-spinners";
// import useWhen from "hooks/useWhen";
// import ManualInput from "./manualInput";
// import { ADDRESS_AUTOCOMPLETE_ARGS } from "consts/feature-config";

export const Address = ({ address, setAddress, currentLocation }) => {
  const { t, i18n } = useTranslation();

  const isValidZip = (value) => {
    const zipRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
    return zipRegex.test(value);
  };
  const [zipBlur, setZipBlur] = useState(false);
  // Filter available countries based on current location
  let filteredCountries = availableCountries;
  if (currentLocation) {
    filteredCountries = availableCountries.filter(
      (country) => country.shorthand === currentLocation.country
    );
  }

  // const showAddressAutocomplete = useWhen([ADDRESS_AUTOCOMPLETE_ARGS]);

  //autocomplete logic
  const [showAllFields, setShowAllFields] = useState(false);
  const [showSuggestionsDropdown, setShowSuggestionsDropdown] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const inputRef = useRef(null);
  const [suggestions, setSuggestions] = useState([]);
  const [placeDetailsLoading, setPlaceDetailsLoading] = useState(false);
  const [hasOptedForManualEntry, setHasOptedForManualEntry] = useState(false);

  const [sessionToken] = useState(uuidv4());
  const country = currentLocation.country.toLowerCase();
  const language = currentLocation.country === "DE" ? "de" : "en";

  //place suggestions call
  const { data, isLoading: searchLoading } = usePlacesSearch(
    searchQuery,
    country,
    language,
    sessionToken
  );
  useEffect(() => {
    if (data) {
      setSuggestions(data);
    }
  }, [data]);

  const debouncedSearch = useDebouncedCallback(async (value) => {
    if (hasOptedForManualEntry) {
      return;
    }
    if (value.length < 3) {
      setShowSuggestionsDropdown(false);
      setSearchQuery("");
      return;
    }
    setSearchQuery(value);
    setShowSuggestionsDropdown(true);
  }, 300);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    setSelectedIndex(-1);
    debouncedSearch(value);
  };

  const { authTokens } = useContext(AuthContext);

  const handleSelectSuggestion = async (suggestion) => {
    setPlaceDetailsLoading(true);
    try {
      const placeDetails = await getPlaceDetails({
        accessToken: authTokens?.access_token,
        placeId: suggestion.place_id,
        language,
      });

      const addressComponents = placeDetails.address_components;
      const getComponent = (type) =>
        addressComponents.find((comp) => comp.types.includes(type))
          ?.long_name || "";
      const getComponentShortName = (type) =>
        addressComponents.find((comp) => comp.types.includes(type))
          ?.short_name || "";

      const addressLine1 =
        currentLocation.country === "US"
          ? `${getComponent("street_number")} ${getComponent("route")}`
          : `${getComponent("route")} ${getComponent("street_number")}`;

      const country = getComponentShortName("country");
      const state = getComponentShortName("administrative_area_level_1");
      const city = getComponent("locality") || getComponent("sublocality");

      const updatedAddress = {
        addressLine1,
        addressLine2: getComponent("subpremise"),
        addressCity: city,
        addressState: currentLocation.country === "US" ? state : "",
        addressCountry: country,
        addressZip: getComponent("postal_code"),
      };

      setAddress(updatedAddress);
      setInputValue(updatedAddress.addressLine1);
      setShowAllFields(true);

      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 100);
    } catch (error) {
      console.error("Error fetching place details:", error);
    } finally {
      setPlaceDetailsLoading(false);
      setShowSuggestionsDropdown(false);
    }
  };

  const handleInputBlur = () => {
    setTimeout(() => {
      setShowSuggestionsDropdown(false);
    }, 200);
  };

  //keep addressLine1 value in sync with inputValue
  useEffect(() => {
    setAddress((prev) => ({
      ...prev,
      addressLine1: inputValue,
    }));
  }, [inputValue, setAddress]);

  const changeToManualEntry = (e) => {
    e.stopPropagation();
    setShowSuggestionsDropdown(false);
    setAddress((prev) => ({
      ...prev,
      addressLine1: "",
      addressLine2: "",
      addressCity: "",
      addressState: "",
      addressZip: "",
    }));
    setShowAllFields(true);
    setHasOptedForManualEntry(true);
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 100);
  };

  return (
    <div className="w-full flex flex-col gap-x-large">
      {/* {!showAddressAutocomplete ? (
        <ManualInput
          address={address}
          setAddress={setAddress}
          currentLocation={currentLocation}
        />
      ) : (
        <> */}
      <div className="relative">
        <Input
          label={t("ui.address_input_autocomplete")}
          type="text"
          name="addressLine1"
          onChange={handleInputChange}
          onKeyDown={(e) => {
            if (e.key === "ArrowDown") {
              e.preventDefault();
              setSelectedIndex((prev) =>
                prev < suggestions.length - 1 ? prev + 1 : suggestions.length
              );
            } else if (e.key === "ArrowUp") {
              e.preventDefault();
              setSelectedIndex((prev) => (prev > 0 ? prev - 1 : prev));
            } else if (e.key === "Escape") {
              setShowSuggestionsDropdown(false);
            } else if (e.key === "Enter" && selectedIndex >= 0) {
              if (selectedIndex === suggestions.length) {
                e.preventDefault();
                changeToManualEntry(e);
              } else {
                e.preventDefault();
                handleSelectSuggestion(suggestions[selectedIndex]);
              }
            }
          }}
          onBlur={handleInputBlur} // Add this line
          value={inputValue}
          pattern="[A-Za-z0-9\s,.\-äöüÄÖÜß]+"
          placeholder={
            hasOptedForManualEntry
              ? ""
              : t("ui.address_autocomplete_placeholder")
          }
          required
          autocomplete="address-line1"
          id="address-input"
          className="w-full"
          inputClassName="pr-10"
          autoComplete="off"
          ref={inputRef}
          aria-autocomplete="list"
          aria-controls="address-suggestions"
          aria-expanded={suggestions.length > 0}
        />
        {!hasOptedForManualEntry &&
          (placeDetailsLoading || searchLoading ? (
            <div className="absolute right-4 top-1/2 translate-y-[10%] pointer-events-none">
              <PulseLoader color="#757575" size={3} />
            </div>
          ) : (
            <img
              src={SearchIcon}
              alt="search"
              className="absolute right-4 top-1/2 translate-y-[15%] pointer-events-none"
            />
          ))}
        {showSuggestionsDropdown && (
          <ul
            id="address-suggestions"
            className="absolute translate-y-[5px] z-10 w-full bg-white shadow-lg max-h-100 rounded-lg ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
            role="listbox"
          >
            {suggestions.length > 0 && (
              <>
                {suggestions.map((suggestion, index) => (
                  <li
                    key={suggestion.place_id}
                    className={`cursor-pointer text-xl select-none relative py-3 pl-3 pr-9 hover:bg-[#f0f0f0] ${
                      index === selectedIndex ? "bg-[#f0f0f0]" : "text-gray-900"
                    }`}
                    role="option"
                    aria-selected={index === selectedIndex}
                    onClick={() => handleSelectSuggestion(suggestion)}
                  >
                    {suggestion.description}
                  </li>
                ))}
                <li
                  key="google-logo"
                  className="select-none relative py-2 pl-3 flex text-black-300"
                >
                  Results by{" "}
                  <img
                    src={GoogleLogo}
                    alt="Google logo"
                    className="w-[59.5px] h-[18px] ml-[4px] mt-[2px]"
                  />
                </li>
              </>
            )}
            <li
              key="manual-entry"
              className={`cursor-pointer select-none text-xl relative py-3 pl-3 pr-9 text-blue-600 hover:bg-[#f0f0f0] border-t border-[#e0e0e0] ${
                selectedIndex === suggestions.length && "bg-[#f0f0f0]"
              }`}
              role="option"
              aria-selected={false}
              onClick={changeToManualEntry}
            >
              {t("ui.input_address_manual")}
            </li>
          </ul>
        )}
      </div>

      {/* <Input
            label={t("ui.input_address_line1")}
            type="text"
            name="addressLine1"
            onChange={(event) =>
              setAddress((prev) => ({
                ...prev,
                addressLine1: event.target.value,
              }))
            }
            value={address.addressLine1}
            pattern="[A-Za-z0-9\s,.\-äöüÄÖÜß]+"
            maxlength="35"
            required
            autocomplete="address-line1"
          />

          <div
            className="text-blue hover:underline cursor-pointer text-[14px] -mt-[16px]"
            onClick={goBackToAutocomplete}
          >
            {t("ui.input_address_back_to_autocomplete")}
          </div> */}
      {showAllFields && (
        <>
          <Input
            label={t("ui.input_address_line2")}
            type="text"
            name="addressLine2"
            onChange={(event) =>
              setAddress((prev) => ({
                ...prev,
                addressLine2: event.target.value,
              }))
            }
            value={address.addressLine2}
            pattern="[A-Za-z0-9\s,.\-äöüÄÖÜß]+"
            maxlength="35"
            autocomplete="address-line2"
          />
          <div
            className={`flex ${
              i18n.language === "de-DE"
                ? "flex-col-reverse sm:flex-row-reverse"
                : "flex-col sm:flex-row"
            } gap-y-x-large sm:gap-x-x-large w-full`}
          >
            <Input
              label={t("ui.input_city")}
              type="text"
              name="addressCity"
              onChange={(event) =>
                setAddress((prev) => ({
                  ...prev,
                  addressCity: event.target.value,
                }))
              }
              value={address.addressCity}
              className="flex-1"
              autocomplete="address-level2"
            />
            <div className="flex-1 flex-col gap-2">
              <Input
                label={t("ui.input_zip_code")}
                type="text"
                name="addressZip"
                onChange={(event) =>
                  setAddress((prev) => ({
                    ...prev,
                    addressZip: event.target.value,
                  }))
                }
                onBlur={() => setZipBlur(true)}
                value={address.addressZip}
                className="flex-1"
              />
              {!isValidZip(address.addressZip) &&
                zipBlur &&
                currentLocation.locale === "en-US" && (
                  <div className="mt-2 text-left">
                    <span
                      className={`material-symbols-outlined scale-75 text-red align-bottom block`}
                    >
                      error
                    </span>
                    <span className="text-sm">
                      {t("ui.input_zip_code_error")}
                    </span>
                  </div>
                )}
            </div>
          </div>
          <div className="flex flex-col sm:flex-row gap-y-x-large sm:gap-x-x-large w-full">
            {address.addressCountry === "US" && (
              <div className="flex-1">
                <Label htmlFor="state">{t("ui.select_state")}</Label>
                <Select
                  id="state"
                  value={address.addressState || "SELECT"}
                  onValueChange={(value) =>
                    setAddress((prev) => ({ ...prev, addressState: value }))
                  }
                  autocomplete="address-level1"
                >
                  <SelectTrigger>
                    <SelectValue placeholder={t("ui.select_state")} />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem key="SELECT" value="SELECT" disabled>
                        {t("ui.please_select")}
                      </SelectItem>
                      {usStates.map((state) => (
                        <SelectItem
                          key={state.shorthand}
                          value={state.shorthand}
                        >
                          {state.name}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
            )}
            <div className="flex-1">
              <Label htmlFor="country">{t("ui.select_country")}</Label>
              <Select
                id="country"
                value={address.addressCountry}
                onValueChange={(value) =>
                  setAddress((prev) => ({ ...prev, addressCountry: value }))
                }
              >
                <SelectTrigger>
                  <SelectValue placeholder={t("ui.select_country")} />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {filteredCountries.map((country) => (
                      <SelectItem
                        key={country.shorthand}
                        value={country.shorthand}
                      >
                        {country.name}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
          </div>
          {/* </>
          )} */}
        </>
      )}
    </div>
  );
};
