import Webcam from "react-webcam";
import { useRef } from "react";

export const Step6 = ({
  handleDeleteNonConfirmingPicture,
  setNonconformingPictures,
  nonConformingPictures,
  handlePreviousStep,
  handleNextStep,
}) => {
  const webcamRef = useRef(null);

  const handleCapturePackage = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setNonconformingPictures([...nonConformingPictures, imageSrc]);
  };

  return (
    <div className="space-y-4">
      <h2 className="text-xl font-semibold">
        Step 6: Capture none-conforming images
      </h2>
      <div className="flex flex-col items-center">
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          width={1920}
          height={1080}
          screenshotQuality={1}
          className="mb-4 w-[800px]"
          forceScreenshotSourceSize={true}
          videoConstraints={{
            width: { ideal: 3840 },
            height: { ideal: 2160 },
            facingMode: { ideal: "environment" },
          }}
        />
        <button
          onClick={handleCapturePackage}
          className="border border-gray-200 bg-yellow hover:bg-blue-700 font-bold py-2 px-4 rounded"
        >
          Capture Non-Conforming Images
        </button>
      </div>
      {nonConformingPictures.length > 0 && (
        <div className="mt-4">
          <h3 className="text-lg font-semibold">Captured Images:</h3>
          <div className="grid grid-cols-3 gap-2">
            {nonConformingPictures.map((image, index) => (
              <div key={index} className="relative">
                <img
                  src={image}
                  alt={`Captured ${index}`}
                  className="w-full h-auto border rounded-lg"
                />
                <button
                  onClick={() => handleDeleteNonConfirmingPicture(index)}
                  className="absolute top-0 right-0 bg-red-600 text-white rounded-full p-1 w-10 h-10"
                >
                  &times;
                </button>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="flex justify-between mt-4">
        <button
          onClick={handlePreviousStep}
          className="bg-gray hover:bg-gray-600 font-bold py-2 px-4 rounded"
        >
          Previous
        </button>
        <button
          onClick={handleNextStep}
          className="bg-green text-white hover:bg-green-600 font-bold py-2 px-4 rounded"
        >
          Next
        </button>
      </div>
    </div>
  );
};
