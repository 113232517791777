import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

export function PasswordValidation({ passwordAlarm = null }) {
  // translation
  const { t } = useTranslation();

  return (
    <div className={`text-secondary text-sm mt-regular`}>
      {passwordAlarm === true ? (
        <span
          className={`material-symbols-outlined scale-75 text-red align-bottom mr-regular`}
        >
          error
        </span>
      ) : passwordAlarm === false ? (
        <span
          className={`material-symbols-outlined scale-75 text-green align-bottom mr-regular`}
        >
          check_circle
        </span>
      ) : null}
      {t("ui.password_validation")}
    </div>
  );
}

PasswordValidation.propTypes = {
  passwordAlarm: PropTypes.bool,
};
