import { formatSnowDate } from "lib/utils";

export const Step0 = ({
  currentTakeback,
  codeType,
  setScannedResult,
  scannedResult,
  isLoadingTakeback,
  isValidTracking,
  updateTakeback,
  handleNextStep,
}) => {
  return (
    <>
      <div className="container">
        <div className={`flex flex-col gap-4`}>
          <label>
            Scanned Tracking Code:
            <input
              className={`border ml-2`}
              value={scannedResult?.text || ""}
              onChange={(e) =>
                setScannedResult({
                  ...scannedResult,
                  text: e.target.value,
                })
              }
            />
          </label>

          {scannedResult.text && isLoadingTakeback ? (
            <p>Finding takeback</p>
          ) : (
            <div className="flex flex-col gap-4">
              <button
                onClick={() => {
                  if (currentTakeback.number) {
                    updateTakeback(
                      {
                        caseId: currentTakeback.number,
                        u_lego_received_at:
                          currentTakeback.u_lego_received_at ||
                          formatSnowDate(new Date()),
                        u_warehouse_started: formatSnowDate(new Date()),
                      },
                      {
                        onSuccess: () => {
                          handleNextStep();
                        },
                      }
                    );
                  }
                }}
                disabled={!codeType || !isValidTracking}
                className={`border border-gray-200 bg-yellow hover:bg-blue-700 font-bold py-2 px-4 rounded ${
                  (!codeType || !isValidTracking) && "!bg-gray"
                }`}
              >
                {codeType ? `Start processing ${codeType}` : "Unknown code"}
              </button>
              <button
                onClick={() => {
                  if (currentTakeback.number) {
                    updateTakeback(
                      {
                        caseId: currentTakeback.number,
                        u_lego_received_at: formatSnowDate(new Date()),
                      },
                      {
                        onSuccess: () => {
                          window.location.reload();
                        },
                      }
                    );
                  }
                }}
                disabled={!codeType || !isValidTracking}
                className={`border border-gray-200 bg-yellow hover:bg-blue-700 font-bold py-2 px-4 rounded ${
                  (!codeType || !isValidTracking) && "!bg-gray"
                }`}
              >
                {codeType ? `Mark as received` : "Unknown code"}
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
