import { useContext, useState } from "react";
import AuthContext from "../contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useRoutes } from "./useRoutes";
import { useUnprotectedRoutes } from "./useUnprotectedRoutes";

const SUPPORTED_LANGUAGES = ["en-US", "de-DE"];

export const I18nRouter = () => {
  const { user } = useContext(AuthContext);
  const { t, i18n } = useTranslation();

  const pathLang = window.location.pathname.split("/")[1];
  const userLang = navigator.language || navigator.userLanguage;
  const defaultLang = SUPPORTED_LANGUAGES.includes(pathLang)
    ? pathLang
    : SUPPORTED_LANGUAGES.includes(userLang)
      ? userLang
      : "en-US";
  const routes = useRoutes(defaultLang);
  const unprotectedRoutes = useUnprotectedRoutes();
  const [languageNeedsChange, setLanguageNeedsChange] = useState(
    i18n.language !== defaultLang
  );

  if (languageNeedsChange) {
    i18n.changeLanguage(defaultLang);
    setLanguageNeedsChange(false);
  }

  if (
    !languageNeedsChange &&
    !user &&
    !unprotectedRoutes.includes(window.location.pathname)
  ) {
    return window.location.assign(
      `${t("urls.login")}?redirectTo=${window.location.pathname}`
    );
  }

  return <RouterProvider router={createBrowserRouter(routes)} />;
};
