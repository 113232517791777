import { cn } from "lib/utils";
import React from "react";
import { Trans } from "react-i18next";

export default function PaymentConfirmation({
  className,
  heading,
  message,
  warningState = false,
}) {
  return (
    <div className={cn("mt-large", className)}>
      <div
        className={`flex rounded-lg w-full h-full overflow-hidden ${
          warningState ? "bg-red-300" : "bg-[#c0dfc3]"
        }`}
      >
        <div
          className={`h-full w-[5px] ${warningState ? "bg-red" : "bg-[#00963e]"}`}
        ></div>
        <div className="flex flex-col p-4">
          <div className="font-semibold">
            {warningState ? (
              <Trans i18nKey={"my_trade_ins.payall_failed_heading"} />
            ) : (
              heading || (
                <Trans i18nKey={"my_trade_ins.payment_confirmation_title"} />
              )
            )}
          </div>
          <div>
            {warningState ? (
              <Trans
                i18nKey={"my_trade_ins.payall_failed_message"}
                components={{
                  1: (
                    <a
                      href="mailto:support@legobricktakeback.com"
                      className="font-bold"
                    >
                      contact support
                    </a>
                  ),
                }}
              />
            ) : (
              message || (
                <Trans i18nKey={"my_trade_ins.payment_confirmation_body"} />
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
