import React from "react";
import KioskStartButton from "../components/start-button";

export default function CancelConfirm({
  navigate,
  previousScreen,
  loggedWeights,
  setInputWeight,
}) {
  // const handleConfirm = () => {
  //   loggedWeights && loggedWeights.length > 0 && setInputWeight(0);
  //   navigate("scale");
  // };
  return (
    <div
      className="flex flex-col items-center justify-between w-full h-full relative"
      //   onClick={() => navigate("clear-tray")}
    >
      <p className="text-[4.8vh] leading-[130%] text-center max-w-[50vh] text-white mt-[3vh] px-[4vh]">
        Not ready to move forward? Just double-checking.
      </p>
      <div className="flex flex-col items-center w-full  relative z-10 gap-[1vh]">
        <div className="grid grid-cols-2 w-full gap-[48px] px-[4vh] relative z-10">
          <KioskStartButton
            className={`relative z-50`}
            onClick={() => {
              // loggedWeights.length > 0 ? handleConfirm() : navigate("start");
              navigate("start");
            }}
            icon={null}
          >
            Confirm
          </KioskStartButton>
          <KioskStartButton
            className="relative z-50 font-bold"
            onClick={() => navigate(previousScreen)}
            icon="arrow_back"
            iconColor="black"
            color="yellow"
          >
            Back
          </KioskStartButton>
        </div>
      </div>
    </div>
  );
}
