import React, { useContext } from "react";
import { ReactComponent as CloudSync } from "../svgs/cloud_sync.svg";
import { TakebackQueueContext } from "../../../../takeback-queue/TakebackQueueProvider";

export const TakebackQueueIcon = () => {
  const { queueCount } = useContext(TakebackQueueContext);

  return (
    <span className="relative">
      <CloudSync style={{ width: "2rem", height: "2rem" }} />
      {queueCount > 0 && (
        <sup className="absolute top-0 left-[-0.5rem] rounded-full w-5 h-5 bg-red text-white text-sm text-center">
          {queueCount}
        </sup>
      )}
    </span>
  );
};
