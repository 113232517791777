import { motion } from "framer-motion";
import CloseIcon from "assets/icons/close.svg";
import GiftCardBig from "assets/giftcardBig.jpg";
import { Button } from "components/button";

export default function GiftcardModal({
  cardNumber = "",
  cardPin = "",
  title,
  setModalOpen = () => {},
}) {
  return (
    <div className="fixed w-screen h-screen top-0 left-0 z-50 flex items-center justify-center">
      <motion.div
        className="bg-[#000000b6] w-full h-full fixed"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
        onClick={() => setModalOpen(false)}
      ></motion.div>
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.9 }}
        transition={{ duration: 0.2 }}
        className="bg-white rounded-xl w-[min(500px,90vw)] mx-auto overflow-scroll p-6 relative flex flex-col items-center"
      >
        <div
          className="absolute top-6 right-6 cursor-pointer"
          onClick={() => setModalOpen(false)}
        >
          <img src={CloseIcon} alt="" />
        </div>
        <img src={GiftCardBig} alt="Gift Card" className="mt-8 w-[60%]" />
        <h2 className="text-5xl font-bold mt-8">{title}</h2>
        <div className="mt-2">CODE: {cardNumber}</div>
        <div className="">PIN: {cardPin}</div>
        <Button className="mt-4 w-full" onClick={() => setModalOpen(false)}>
          Done
        </Button>
      </motion.div>
    </div>
  );
}
