import React, { useState, useEffect } from "react";
import Brick from "./FallingBrick";
import "./falling-bricks.css";

const GRID_SIZE = 50; // Increased grid size for more placement options
const brickSizes = ["one", "two", "three", "four"];

export default function FallingBricks({
  brickCount = 50,
  transitionDelay = 1,
  isVisible = true,
  isFalling = false,
}) {
  const [bricks, setBricks] = useState([]);

  useEffect(() => {
    const grid = Array(GRID_SIZE)
      .fill()
      .map(() => Array(GRID_SIZE).fill(false));
    const newBricks = [];

    const placeBrick = (size) => {
      const width =
        size === "one" ? 2 : size === "two" ? 3 : size === "three" ? 4 : 5;
      const height = 3; // Increased height to account for rotation

      for (let attempts = 0; attempts < 100; attempts++) {
        const x = Math.floor(Math.random() * (GRID_SIZE - width));
        const y = Math.floor(Math.random() * (GRID_SIZE - height));

        if (canPlaceBrick(x, y, width, height, grid)) {
          for (let dy = 0; dy < height; dy++) {
            for (let dx = 0; dx < width; dx++) {
              grid[y + dy][x + dx] = true;
            }
          }
          return {
            x: x + width / 2,
            y: y + height / 2,
            size,
            rotation: Math.random() * 360,
          };
        }
      }
      return null; // Failed to place brick after 100 attempts
    };

    const canPlaceBrick = (x, y, width, height, grid) => {
      for (let dy = -1; dy <= height; dy++) {
        for (let dx = -1; dx <= width; dx++) {
          const checkX = x + dx;
          const checkY = y + dy;
          if (
            checkX < 0 ||
            checkX >= GRID_SIZE ||
            checkY < 0 ||
            checkY >= GRID_SIZE ||
            grid[checkY][checkX]
          ) {
            return false;
          }
        }
      }
      return true;
    };

    for (let i = 0; i < brickCount; i++) {
      // Use brickCount prop here
      const size = brickSizes[Math.floor(Math.random() * brickSizes.length)];
      const brick = placeBrick(size);
      if (brick) {
        newBricks.push({
          ...brick,
          x: (brick.x / GRID_SIZE) * 100,
          y: (brick.y / GRID_SIZE) * 100,
        });
      }
    }

    setBricks(newBricks);
  }, [brickCount]); // Add brickCount to the dependency array

  // const toggleVisibility = () => {
  //   setIsVisible(!isVisible);
  // };

  return (
    <div className="falling-brick-container">
      {/* <button
        className="px-4 py-3 bg-green-400 rounded-xl"
        onClick={toggleVisibility}
      >
        {isVisible ? "Hide Bricks" : "Show Bricks"}
      </button> */}
      {bricks.map((brick, index) => (
        <Brick
          key={index}
          style={{
            position: "absolute",
            left: `${brick.x}%`,
            top: `${brick.y}%`,
            transform: `translate(-50%, -50%)`,
          }}
          size={brick.size}
          rotation={brick.rotation}
          isVisible={isVisible}
          transitionDelay={transitionDelay}
          isFalling={isFalling}
        />
      ))}
    </div>
  );
}
