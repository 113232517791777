import { useEffect, useRef } from "react";
import logo from "assets/logo.jpg";
import BgLandingHero from "assets/landingHero.png";
import { useTranslation } from "react-i18next";
import { useCurrentLocation } from "lib/utils";

export default function ComingSoon() {
  const { t, i18n } = useTranslation();

  // Get location settings
  const { location: currentLocation } = useCurrentLocation();

  // change language with location
  useEffect(() => {
    if (currentLocation) {
      i18n.changeLanguage(currentLocation.locale);
    }
  }, [currentLocation, i18n]);
  const initialLanguage = useRef(i18n.language);

  useEffect(() => {
    if (initialLanguage.current !== i18n.language) {
      window.location.reload();
    }
  }, [i18n.language]);
  return (
    <div className="bg-gradient-to-b from-[#FFF087] via-[#FFE950] to-[#FFE21B] h-screen w-screen relative overflow-hidden">
      <div>
        <img
          src={BgLandingHero}
          alt={t("landing.hero.image_alt")}
          className="absolute right-0 bottom-0 h-[50vh] w-auto shrink-0 object-cover"
          style={{ objectPosition: "right bottom" }}
        />
      </div>
      <div className="p-16">
        <img src={logo} alt="LEGO Logo" className="w-[55px] h-[55px] mb-8" />
        <div className="font-bold text-6xl mb-4">{t("coming_soon.title")}</div>
        <div>{t("coming_soon.subtitle")}</div>
      </div>
    </div>
  );
}
