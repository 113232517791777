import React from "react";
import { motion } from "framer-motion";
import { Button } from "components/button";

export default function RestrictedModal({ setModalOpen = () => {} }) {
  return (
    <div className="fixed w-screen h-screen top-0 left-0 z-[60] flex items-center">
      <motion.div
        className="bg-[#000000b6] w-full h-full fixed"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
        onClick={setModalOpen}
      ></motion.div>
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.9 }}
        transition={{ duration: 0.2 }}
        className="bg-white rounded-3xl w-[min(500px,90vw)] mx-auto overflow-scroll px-3 md:px-5 py-6 relative"
      >
        <h1 className="text-4xl font-bold mb-4">
          Not available in your country
        </h1>
        <div className="text-black-300">
          The LEGO® Brick Take Back program is a test run that is currently
          only available in the <strong>United States</strong> and{" "}
          <strong>Germany</strong>.
        </div>
        <div className="flex justify-end mt-8">
          <Button onClick={setModalOpen}>Got it</Button>
        </div>
      </motion.div>
    </div>
  );
}
