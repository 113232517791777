import React, { useEffect, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { Header } from "components/header";
import { useTranslation } from "react-i18next";
import ImpressumFooter from "components/footer-impressum";
import { Address } from "components/address";
import { Button } from "components/button";
import { useCurrentLocation } from "lib/utils";
import AuthContext from "contexts/AuthContext";
import LoadingSpinner from "components/loading-spinner";

const { REACT_APP_API } = process.env;

const fetchAddress = async ({ setAddress, accessToken, email }) => {
  try {
    const response = await fetch(
      `${REACT_APP_API}/address/${encodeURIComponent(email)}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error();
    }

    const json = await response.json();

    setAddress(json);
  } catch (error) {
    console.error(error);
    alert("Error. Please try again");
  }
};

const UpdateAddress = () => {
  const { email: providedEmail } = useParams();
  const { authTokens, user } = useContext(AuthContext);
  const email = providedEmail || user.email;
  const { t } = useTranslation();
  const { location: currentLocation } = useCurrentLocation();
  const [address, setAddress] = useState();
  const [isUpdating, setIsUpdating] = useState(false);
  const accessToken = authTokens.access_token;
  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsUpdating(true);

    try {
      const response = await fetch(
        `${REACT_APP_API}/address/${encodeURIComponent(email)}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(address),
        }
      );

      if (!response.ok) {
        throw new Error();
      }

      const json = await response.json();

      setAddress(json);
      alert("Success. Address updated");
      window.location.href = t(`urls.my_trade_ins`);
    } catch (error) {
      console.error(error);
      alert("Error. Please try again");
    }

    setIsUpdating(false);
  };

  useEffect(() => {
    fetchAddress({ setAddress, accessToken, email });
  }, [accessToken, email]);

  if (!address) {
    return <LoadingSpinner isLoading />;
  }

  return (
    <div className="min-h-screen flex flex-col p-6">
      <div>
        <Header />
        <form onSubmit={handleSubmit}>
          <Address
            currentLocation={currentLocation}
            address={address}
            setAddress={setAddress}
          />
          <div className="flex justify-end mt-8">
            <Button type="submit" disabled={isUpdating}>
              {t("ui.button_submit1")}
            </Button>
          </div>
        </form>
      </div>
      <ImpressumFooter />
    </div>
  );
};

export default UpdateAddress;
