import * as React from "react";
import { ProfileHead } from "pages/svgs/profile";
import { DotsButton } from "components/dots-button";
import { useTranslation } from "react-i18next";

const ScheduleCard = ({ time, name, weight }) => {
  // translation
  const { t } = useTranslation();

  const unit = `${t("scale.weight")}`;

  return (
    <div className="bg-[#EFF6FF] dark:bg-gray-800 p-6 flex items-start justify-between">
      <div className="flex flex-1 items-start">
        <div className="bg-white text-[#2C2C2C] border-[#D2D2D2] border-2 px-3 py-2 rounded-lg mr-4 my-auto">
          <div className="font-normal">{time}</div>
        </div>
        <div className="bg-white px-4 py-3 rounded-lg w-full border-[#D2D2D2] border-2">
          <div className="flex-1 w-full flex">
            <div className="mr-4 my-auto bg-[#E4E4E4] rounded-full p-2">
              <ProfileHead />
            </div>
            <div>
              <div className="font-bold text-[#000000]">{name}</div>
              <div className="text-[#2C2C2C] dark:text-gray-400">
                {weight}
                {unit} of LEGO
              </div>
            </div>
            <div className="flex ml-auto mb-auto">
              <DotsButton />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleCard;
