import React from "react";
import { Button } from "components/button";
import BottomBar from "components/bottom-bar";

export default function ShippingLabel() {
  return (
    <div className="flex flex-col h-[calc(100vh-81px)]">
      <div className="flex flex-grow flex-col items-center justify-center px-6 py-4">
        <h1 className="text-6xl font-bold text-center mb-2">
          Shipping Label Ready
        </h1>
        <p className="text-center  sm:max-w-sm text-xl mb-10">
          A shipping label has been generated and is ready to be printed and
          attached to the receptacle.
        </p>
        <Button href="" className="w-full max-w-sm py-6 mb-6">
          Print Shipping Label
        </Button>
        <a
          href="create-shipment"
          className="text-center text-[#1f6cb7] text-xl"
        >
          Done
        </a>
      </div>
      <BottomBar />
    </div>
  );
}
