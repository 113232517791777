import { useMutation } from "react-query";
import { useContext } from "react";
import AuthContext from "contexts/AuthContext";

const { REACT_APP_API } = process.env;

const checkPin = async ({ accessToken, payload }) => {
  const response = await fetch(`${REACT_APP_API}/warehouse/check-pin`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(payload),
  });

  if (!response.ok) {
    throw new Error("Invalid Pin");
  }

  return true;
};

export const useWarehouseCheckPin = (options) => {
  const { authTokens } = useContext(AuthContext);
  const accessToken = authTokens?.access_token;

  return useMutation({
    mutationKey: ["check-pin", accessToken],
    mutationFn: (pin) => checkPin({ accessToken, payload: { pin } }),
    options,
  });
};
