import React, { useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "components/button";
import WeightInput from "components/weight-input";
import { getQueryParams } from "lib/utils";
import { useTranslation } from "react-i18next";
import AuthContext from "contexts/AuthContext";

export default function ConfirmWeight() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const queryParams = getQueryParams(location.search);
  const [weight, setWeight] = useState(queryParams.weight || "");

  const handleWeightChange = (e) => {
    setWeight(e.target.value);
  };

  const handleGenerateShippingLabel = () => {
    const timestamp = new Date().toISOString();
    const email = user?.email;
    const storeId = email.includes("testinstoreGA")
      ? "sugarloaf"
      : email.includes("testinstoreCO")
        ? `in_store_co`
        : undefined;

    const searchParams = new URLSearchParams({
      weight,
      email,
      timestamp,
      storeId,
    }).toString();

    navigate(`${t("urls.store_package_qr_code")}?${searchParams}`);
  };

  return (
    <div className="flex flex-col h-[calc(100vh-81px)]">
      <div className="flex flex-grow flex-col items-center justify-center px-6 py-4">
        <h1 className="text-6xl text-primary mb-regular">Confirm Weight</h1>
        <p className="text-center sm:max-w-md text-2xl mb-x-large text-secondary">
          Please confirm the package weight before generating the shipping
          label.
        </p>
        <div className="flex flex-col gap-4 mt-10 ">
          <WeightInput
            caption="Estimated Package Weight"
            unit={`${t("scale.weight")}`}
            value={weight}
            onChange={handleWeightChange}
          />
          <Button
            onClick={handleGenerateShippingLabel}
            className="w-full max-w-sm py-6 mb-2 mt-4"
            disabled={!weight}
          >
            Generate Shipping Label
          </Button>
          <Button
            variant="secondary"
            onClick={() => navigate(-1)}
            className="w-full max-w-sm py-6 mb-2 mt-4"
          >
            Back
          </Button>
        </div>
      </div>
    </div>
  );
}
