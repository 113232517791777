import React from "react";
import { Button } from "components/button";
import PropTypes from "prop-types";
import { motion, AnimatePresence } from "framer-motion";

const ConfirmationDialog = ({
  isOpen,
  onClose,
  onConfirm,
  print = false,
  title,
  body,
  primaryText,
  secondaryText,
  pdfUrl,
}) => {
  const handlePrint = () => {
    if (pdfUrl) {
      const printWindow = window.open(pdfUrl, "_blank");
      printWindow.onload = () => {
        printWindow.print();
      };
    }
  };

  const handlePrimaryAction = () => {
    if (print) {
      handlePrint();
    } else if (onConfirm) {
      onConfirm();
    }
  };

  // const modalRef = useRef();

  // useEffect(() => {
  //   const handleOutsideClick = (e) => {
  //     if (modalRef.current && !modalRef.current.contains(e.target)) {
  //       onClose();
  //     }
  //   };
  // });

  if (!isOpen) return null;

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="fixed inset-0 flex items-center justify-center z-50"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <div className="bg-white p-6 rounded-lg shadow-lg z-20 px-10 w-full max-w-3xl max-h-[95vh] overflow-auto">
            <h2 className="text-4xl text-center font-bold text-balance mb-4">
              {title}
            </h2>
            <div className="mb-4 flex justify-center max-h-[70vh]">{body}</div>
            <div className="flex justify-between gap-4 pt-8">
              {(print && pdfUrl) || (!print && onConfirm) ? (
                <Button onClick={handlePrimaryAction} className="w-full">
                  {primaryText}
                </Button>
              ) : null}
              <Button onClick={onClose} variant="secondary" className="w-full">
                {secondaryText}
              </Button>
            </div>
          </div>
          <div className="fixed inset-0 bg-black opacity-50 z-10"></div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

ConfirmationDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  title: PropTypes.string.isRequired,
  body: PropTypes.node.isRequired,
  primaryText: PropTypes.string.isRequired,
  secondaryText: PropTypes.string.isRequired,
  pdfUrl: PropTypes.string,
  print: PropTypes.bool,
};

export default ConfirmationDialog;
