import { ENV_BRANCH, ENV_LOCAL, ENV_NEXT, ENV_PRODUCTION } from "hooks/useWhen";

export const PAYMENT_ARGS = {
  env: [ENV_LOCAL, ENV_NEXT, ENV_BRANCH, ENV_PRODUCTION],
};

export const END_OF_PILOT_ARGS = {
  env: [ENV_LOCAL, ENV_NEXT, ENV_BRANCH, ENV_PRODUCTION],
};

export const ADDRESS_AUTOCOMPLETE_ARGS = {
  env: [ENV_LOCAL, ENV_NEXT, ENV_BRANCH, ENV_PRODUCTION],
};
