import React from "react";
import { motion } from "framer-motion";
import { Button } from "components/button";
import { createSearchParams, useNavigate } from "react-router-dom";

export default function ConfirmNavigationModal({
  title,
  body,
  buttonLabel,
  buttonHref,
  buttonParams,
  blocker,
  cancelLabel = "Cancel",
}) {
  const navigate = useNavigate();

  const handleSubmit = () => {
    blocker.proceed();
    const navigateOptions = { pathname: buttonHref };
    if (buttonParams) {
      navigateOptions.search = createSearchParams(buttonParams).toString();
    }
    navigate(navigateOptions);
  };

  return (
    <div className="fixed w-screen h-screen top-0 left-0 z-50 flex items-center">
      <motion.div
        className="bg-[#000000b6] w-full h-full fixed"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
        onClick={() => blocker.reset()}
      ></motion.div>
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.9 }}
        transition={{ duration: 0.2 }}
        className="bg-white rounded-xl w-[min(400px,90vw)] mx-auto overflow-scroll p-6 relative"
      >
        <h1 className="text-6xl font-bold">{title}</h1>
        <p className="mt-2 text-black-300">{body}</p>
        <div className="flex justify-end mt-8 gap-2">
          <Button onClick={() => blocker.reset()} variant="secondary">
            {cancelLabel}
          </Button>
          {buttonHref ? (
            <Button onClick={handleSubmit}>{buttonLabel}</Button>
          ) : (
            <Button onClick={() => blocker.proceed()}>{buttonLabel}</Button>
          )}
        </div>
      </motion.div>
    </div>
  );
}
