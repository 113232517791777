import * as React from "react";
import { cn } from "lib/utils";

// Utility function to generate unique IDs
const generateId = () => `input-${Math.random().toString(36).substr(2, 9)}`;

const Input = React.forwardRef(
  ({ className, type, label, id, inputClassName = "", ...props }, ref) => {
    const inputId = id || generateId();

    return (
      <div className={cn(className, "relative")}>
        {label && (
          <label
            htmlFor={inputId}
            className={cn(
              "block mb-2 max-w-full overflow-hidden text-ellipsis whitespace-nowrap",
              {
                "w-full": !className,
              }
            )}
            style={{ maxWidth: className ? undefined : "100%" }}
          >
            {label}
          </label>
        )}
        <input
          id={inputId}
          type={type}
          className={cn(
            "text-secondary flex h-12 w-full rounded-lg border border-gray bg-white px-3 py-2 ring-offset-white file:border-0 file:bg-transparent file:font-medium placeholder:text-tertiary focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-blue focus:border-blue disabled:cursor-not-allowed disabled:opacity-50",
            inputClassName
          )}
          ref={ref}
          {...props}
        />
      </div>
    );
  }
);
Input.displayName = "Input";

export { Input };
