import { useState, useEffect } from "react";
import localForage from "localforage";

export const useLocalForage = (key) => {
  const [value, setValue] = useState(null);

  useEffect(() => {
    const fetchValue = async () => {
      const storedValue = await localForage.getItem(key);
      if (storedValue !== null) {
        setValue(storedValue);
      }
    };

    fetchValue();
  }, [key]);

  useEffect(() => {
    const storeValue = async () => {
      if (value !== null) {
        await localForage.setItem(key, value);
      }
    };

    storeValue();
  }, [value, key]);

  return [value, setValue];
};
