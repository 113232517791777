import { useWarehouseContext } from "./useWarehouseContext";
import { Footer } from "./components/Footer";
import { WeightCapture } from "./components/WeightCapture";
import { StepHeader } from "./components/StepHeader";
import { FullscreenModal } from "./components/FullscreenModal";

const TITLE = "Conforming Weight";

export const ConformingWeightCapture = ({ dispatch }) => {
  const { conformingWeight, setConformingWeight } = useWarehouseContext();

  return (
    <FullscreenModal>
      <StepHeader dispatch={dispatch} />

      <Footer>
        <WeightCapture
          title={TITLE}
          instruction="Transfer the conforming material from the sorting tray into the control container and record weight."
          value={conformingWeight}
          onChange={(weight) => setConformingWeight(weight)}
          dispatch={dispatch}
        />
      </Footer>
    </FullscreenModal>
  );
};

ConformingWeightCapture.title = TITLE;
