/* currently skipped in flow */
import React, { useContext, useEffect, useState, useRef } from "react";
import WeightEstimation from "components/weight-estimation";
import egift_card from "assets/egift_card.svg";
import egift_cardWEBP from "assets/egift_cardWEBP.webp";
import StoreFormContainer from "components/store-form-container";
import { calculateEstimate } from "lib/calculateEstimate";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getQueryParams } from "lib/utils";
import { useRestartIfAbsent } from "hooks/useRestartIfAbsent";
import { Trans, useTranslation } from "react-i18next";
import AuthContext from "contexts/AuthContext";
import { TAKEBACK_DISBURSEMENT_NAME } from "consts/query-params";
import { useTakebackById } from "api/use-takebacks";
import Checklist from "components/checklist";
import InputValidation from "components/input-validation";
import TermsText from "components/terms-text";

const { REACT_APP_API } = process.env;

export default function StoreTerms() {
  const { authTokens } = useContext(AuthContext);
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const [termsChecked, setTermsChecked] = useState(false);
  const [othersChecked, setOthersChecked] = useState(false);
  const [warning, setWarning] = useState(false);
  const [isBottom, setIsBottom] = useState(false);

  // translation
  const { t, i18n } = useTranslation();
  const unit = t("scale.weight");

  const location = useLocation();
  const queryParams = getQueryParams(location.search);

  const previouslySetWeight = queryParams.w;

  const estimate = calculateEstimate(
    previouslySetWeight,
    i18n.language.slice(-2)
  );

  useRestartIfAbsent(
    queryParams,
    ["w", TAKEBACK_DISBURSEMENT_NAME],
    "/store/trade-in"
  );

  const checklistItems1 = [
    { name: "item1", label: t("trade-in.terms.check1") },
  ];

  const checklistItems2 = [
    { name: "item2", label: t("trade-in.terms.check2") },
    { name: "item3", label: t("trade-in.terms.check3") },
  ];

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
    if (scrollTop + clientHeight >= scrollHeight - 5) {
      setIsBottom(true);
      setWarning(false);
    }
  };

  const takeback = useTakebackById({ id: queryParams.id });

  const updatedParams = { ...queryParams };

  const accessToken = authTokens.access_token;

  const handleSubmit = async () => {
    const response = await fetch(
      `${REACT_APP_API}/takeback/${queryParams.id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          u_accepted_terms: true,
          inStoreVerified: true,
          u_lego_postsort_weight: queryParams.w,
          paymentType: updatedParams[TAKEBACK_DISBURSEMENT_NAME],
        }),
      }
    );

    if (response.ok) {
      const searchParams = new URLSearchParams(updatedParams).toString();
      navigate(`${t("urls.store_confirmation")}?${searchParams}`);
    } else {
      alert("Please try again");
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    container.addEventListener("scroll", handleScroll);
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <StoreFormContainer
      onSubmit={handleSubmit}
      submitLabel="Confirm Trade-In"
      submitDisabled={!othersChecked || !termsChecked}
      secondaryLabel="Back"
      secondaryHref={t("urls.store_payout_confirm")}
      secondaryParams={updatedParams}
    >
      <div className="flex flex-col flex-grow py-4">
        <div className="flex flex-col mr-auto">
          <h1 className="text-6xl">
            Hey, {takeback ? takeback.consumer.firstName : "Customer"}
          </h1>
          <div className="text-tertiary text-2xl">
            Please review and agree to the Terms and Conditions.
          </div>
        </div>
        <div className="text-2xl">
          {/* <WeightEstimation
            title="YOUR SELECTION"
            description={`LEGO® e-Gift Card. Based on ${previouslySetWeight.toFixed(
              1
            )} ${unit}`}
            className=""
            singleValue={estimate.gift}
            image={egift_card}
            imgSource={egift_cardWEBP}
            animate
          /> */}
          {takeback?.u_lego_payment_type === "giftcard" ? (
            <WeightEstimation
              title="YOUR SELECTION"
              description={`LEGO® e-Gift Card. Based on ${previouslySetWeight} ${unit}`}
              singleValue={estimate.gift}
              className=""
              image={egift_card}
              imgSource={egift_cardWEBP}
              locale="en-US"
              animate
            />
          ) : (
            <WeightEstimation
              title="YOUR SELECTION"
              description={`Digital cash. ${previouslySetWeight}${unit} confirmed weight`}
              className=""
              singleValue={estimate.payment}
              image={egift_card}
              imgSource={egift_cardWEBP}
              animate
            />
          )}
        </div>
        <div className="mt-xxx-large px-auto">
          <div className="flex flex-col">
            <label className="mb-regular text-2xl font-semibold">
              Terms and Conditions
            </label>
            <div
              ref={containerRef}
              className="overflow-y-scroll h-[600px] w-full border border-gray-300 p-4 mb-6 rounded-xl"
            >
              <TermsText scroll={false} />
            </div>
            {warning && (
              <InputValidation errorMessage={t("trade-in.terms.warning")} />
            )}
            <div
              onClick={
                !isBottom ? () => setWarning(true) : () => setWarning(false)
              }
            >
              <Checklist
                checklistItems={checklistItems1}
                setAllChecked={setTermsChecked}
                className="mt-large"
                disabled={!isBottom}
              />
            </div>
            <Checklist
              checklistItems={checklistItems2}
              setAllChecked={setOthersChecked}
              className="mt-8"
            />
            <div className="mt-8">
              <Trans
                i18nKey="trade-in.terms.add1"
                components={{
                  1: (
                    <Link
                      to={t("urls.terms_conditions")}
                      className="text-blue"
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  ),
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </StoreFormContainer>
  );
}
