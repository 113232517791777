import React from "react";
import { Button } from "components/button";
import OpenInNew from "assets/icons/open_in_new.svg";
import { useTranslation } from "react-i18next";
import LockIcon from "assets/icons/lock.svg";
// import Mastercard from "assets/Mastercard.svg";
import Tango from "assets/Tango.svg";
import { useUpdateTakeback } from "api/use-takebacks";

export default function TangoButton({
  caseId,
  className,
  u_tango_redeem_link,
}) {
  const { t } = useTranslation();

  const { mutate: updateTakeback } = useUpdateTakeback();

  return (
    <div className={className}>
      <Button
        onClick={() => updateTakeback({ caseId, state: "3" })}
        disabled={!Boolean(u_tango_redeem_link)}
        className="w-full"
      >
        <a
          href={u_tango_redeem_link}
          target="_blank"
          rel="noreferrer"
          className="text-[14px] md:text-base"
        >
          {t("my_trade_ins.button_tango")}{" "}
          <img
            src={OpenInNew}
            alt="Open in new window icon"
            className="w-5 ml-2 mb-1 hidden md:inline-block"
          />
        </a>
      </Button>
      <div className="flex w-full justify-center gap-2 mt-large mb-4">
        <img src={Tango} className="w-16" alt="Tango Logo" />
        {/* <img src={Mastercard} alt="Mastercard Logo" /> */}
        <img src={LockIcon} alt="Lock Icon" />
        <div className="text-black-300">
          <span className="font-semibold text-[14px]">
            Select or Manage Your Digital Cash Reward{" "}
          </span>
          <span>will open in a new window.</span>
        </div>
      </div>
      <details
        className="w-full text-black-300 mt-small group text-[11px]"
        open
      >
        <summary>Tango information</summary>
        <div className="transition-all ease-in-out group:&[open]:bg-blue-100 group-closed:bg-red-100">
          <p className="mb-4">
            Tango will facilitate the disbursement of your Digital Cash reward.
            You will be taken to a new window where you can select one of 3
            disbursement options:
          </p>
          <ul className="list-disc list-inside mb-4">
            <li>Deposit to bank account</li>
            <li>Deposit to debit card</li>
            <li>Prepaid Mastercard&reg;*</li>
          </ul>
          <p>
            *Please note that funds transferred to the prepaid Mastercard&reg;
            will expire after 6 months. You will receive an email from
            no-reply@tangocard.com with instructions to activate your prepaid
            Mastercard&reg;.
          </p>
        </div>
      </details>
    </div>
  );
}
