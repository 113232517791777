import { useContext } from "react";
import { useQuery } from "react-query";
import AuthContext from "contexts/AuthContext";

const { REACT_APP_API } = process.env;

const fetchHasTakeback = async ({ accessToken }) => {
  const response = await fetch(`${REACT_APP_API}/takeback/has`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch takebacks");
  }

  return response.json();
};

const useHasTakeback = () => {
  const { authTokens } = useContext(AuthContext);
  const accessToken = authTokens?.access_token;

  return useQuery(
    [`hasTakeback`, accessToken],
    () => {
      return fetchHasTakeback({ accessToken });
    },
    {
      enabled: Boolean(accessToken),
      refetchOnWindowFocus: false,
    }
  );
};

export { useHasTakeback };
