import { useRef } from "react";
import { useTranslation, Trans } from "react-i18next";

export default function TermsText({
  scroll,
  whiteText = false,
  disableLinks = false,
}) {
  const { t, i18n } = useTranslation();

  //scroll to
  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  const section4Ref = useRef(null);
  const section5Ref = useRef(null);
  const section6Ref = useRef(null);

  const scrollToSection = (ref) => {
    window.scrollTo({
      top: ref.current.offsetTop - 120,
      behavior: "smooth",
    });
  };

  const linkStyle = disableLinks
    ? { pointerEvents: "none", color: "inherit", textDecoration: "none" }
    : {};

  return (
    <div className={`text-base ${whiteText ? "text-[#c0dfc3]" : ""}`}>
      <h1 className="mb-8">
        <Trans i18nKey="terms.modified" />
      </h1>
      {i18n.language === "de-DE" && (
        <>
          <h2 className="text-2xl font-bold mb-4">{t("terms.impressum")}</h2>
          <p className="mb-6">{t("terms.pentatonic_address")}</p>
        </>
      )}

      <h2 className="text-2xl font-semibold mb-4">
        {t("terms.why_important")}
      </h2>
      <p className="mb-6">{t("terms.importance_explanation")}</p>

      <h2 className="text-2xl font-semibold mb-4">
        {t("terms.how_structured")}
      </h2>
      <p className="mb-6">{t("terms.structure_explanation")}</p>
      <p className="mb-6">{t("terms.structure_title")}</p>
      <div className="mb-6">
        <ol
          className={`flex flex-col gap-4 items-start text-left ${
            scroll === true ? "text-blue" : ""
          } align-top whitespace-pre-wrap`}
        >
          <li
            className={`text-left ${scroll === true ? "cursor-pointer" : ""}`}
            onClick={() => (scroll ? scrollToSection(section1Ref) : null)}
          >
            {t("terms.section_1_title")}
          </li>
          <li
            className={`text-left ${scroll === true ? "cursor-pointer" : ""}`}
            onClick={() => (scroll ? scrollToSection(section2Ref) : null)}
          >
            {t("terms.section_2_title")}
          </li>
          <li
            className={`text-left ${scroll === true ? "cursor-pointer" : ""}`}
            onClick={() => (scroll ? scrollToSection(section3Ref) : null)}
          >
            {t("terms.section_3_title")}
          </li>
          <li
            className={`text-left ${scroll === true ? "cursor-pointer" : ""}`}
            onClick={() => (scroll ? scrollToSection(section4Ref) : null)}
          >
            {t("terms.section_4_title")}
          </li>
          <li
            className={`text-left ${scroll === true ? "cursor-pointer" : ""}`}
            onClick={() => (scroll ? scrollToSection(section5Ref) : null)}
          >
            {t("terms.section_5_title")}
          </li>
          <li
            className={`text-left ${scroll === true ? "cursor-pointer" : ""}`}
            onClick={() => (scroll ? scrollToSection(section6Ref) : null)}
          >
            {t("terms.section_6_title")}
          </li>
        </ol>
      </div>

      <h2 className="text-2xl font-semibold mb-8 mt-8" ref={section1Ref}>
        {t("terms.section_1_title")}
      </h2>

      <h4 className="font-semibold mb-2">{t("terms.section_1_1_title")}</h4>
      <p className="mb-4">
        <Trans i18nKey="terms.section_1_1_content" />
      </p>

      <h4 className="font-semibold mb-2">{t("terms.section_1_2_title")}</h4>
      <p className="mb-4">{t("terms.section_1_2_content")}</p>

      <h4 className="font-semibold mb-2">{t("terms.section_1_3_title")}</h4>
      <p className="mb-4">{t("terms.section_1_3_content")}</p>

      <h3 className="text-2xl font-semibold mb-8 mt-8" ref={section2Ref}>
        {t("terms.section_2_title")}
      </h3>

      <h4 className="font-semibold mb-2">{t("terms.section_2_1_title")}</h4>
      <p className="mb-4">{t("terms.section_2_1_content")}</p>

      <h4 className="font-semibold mb-2">{t("terms.section_2_2_title")}</h4>
      <p className="mb-4">
        <Trans
          i18nKey="terms.section_2_2_content"
          components={{
            1: (
              <a
                href="https://legobricktakeback.com"
                className="text-blue"
                target="_blank"
                rel="noopener noreferrer"
                style={linkStyle}
              >
                www.legobricktakeback.com
              </a>
            ),
          }}
        />
      </p>

      <h4 className="font-semibold mb-2">{t("terms.section_2_3_title")}</h4>
      <p className="mb-4">{t("terms.section_2_3_content")}</p>

      {i18n.language === "en-US" && (
        <>
          <h2 className="text-2xl font-bold mb-4">
            {t("terms.section_2_3_1_title")}
          </h2>
          <p className="mb-6">{t("terms.section_2_3_1_content")}</p>
        </>
      )}

      {i18n.language === "en-US" && (
        <>
          <h2 className="text-2xl font-bold mb-4">
            {t("terms.section_2_3_2_title")}
          </h2>
          <p className="mb-6">{t("terms.section_2_3_2_content")}</p>
        </>
      )}

      <h4 className="font-semibold mb-2">{t("terms.section_2_4_title")}</h4>
      <p className="mb-4">{t("terms.section_2_4_content")}</p>

      <h4 className="font-semibold mb-2">{t("terms.section_2_5_title")}</h4>
      <p className="mb-4">{t("terms.section_2_5_content")}</p>

      <h4 className="font-semibold mb-2">{t("terms.section_2_6_title")}</h4>
      <p className="mb-4">{t("terms.section_2_6_content")}</p>
      <ul className="list-disc list-outside mb-4 ml-4">
        <li>{t("terms.section_2_6_item_1")}</li>
        <li>{t("terms.section_2_6_item_2")}</li>
        <li>{t("terms.section_2_6_item_3")}</li>
        <li>{t("terms.section_2_6_item_4")}</li>
        <li>{t("terms.section_2_6_item_5")}</li>
      </ul>

      <p className="mb-4">{t("terms.section_2_6_content2")}</p>

      <h4 className="font-semibold mb-2">{t("terms.section_2_7_title")}</h4>
      <p className="mb-4">{t("terms.section_2_7_content")}</p>

      <h3 className="text-2xl font-semibold mb-8 mt-8" ref={section3Ref}>
        {t("terms.section_3_title")}
      </h3>

      <h4 className="font-semibold mb-2">{t("terms.section_3_1_title")}</h4>

      <h5 className="font-semibold mb-2">{t("terms.section_3_1_a_title")}</h5>
      <p className="mb-4">{t("terms.section_3_1_a_content")}</p>

      <h5 className="font-semibold mb-2">{t("terms.section_3_1_b_title")}</h5>
      <p className="mb-4">{t("terms.section_3_1_b_content")}</p>

      {i18n.language === "en-US" && (
        <>
          <h5 className="font-semibold mb-2">
            {t("terms.section_3_1_c_title")}
          </h5>
          <p className="mb-4">{t("terms.section_3_1_c_content")}</p>
        </>
      )}

      <>
        <h4 className="font-semibold mb-2">{t("terms.section_3_2_title")}</h4>
        <p className="mb-4">{t("terms.section_3_2_content")}</p>
      </>

      {i18n.language === "en-US" && (
        <>
          <h4 className="font-semibold mb-2">{t("terms.section_3_3_title")}</h4>
          <p className="mb-4">
            {t("terms.section_3_3_content")}{" "}
            <a
              href="https://www.lego.com/en-us/gift-cards"
              title="Link to information about LEGO Gift Cards on LEGO.com"
              target="_blank"
              rel="noreferrer"
              className="text-blue hover:underline"
              style={linkStyle}
            >
              {t("terms.section_3_3_link")}
            </a>
            .
          </p>
        </>
      )}

      <h3 className="text-2xl font-semibold mb-8 mt-8" ref={section4Ref}>
        {t("terms.section_4_title")}
      </h3>
      <p className="mb-4 font-semibold">{t("terms.section_4_content")}</p>

      <h3 className="text-2xl font-semibold mb-8 mt-8" ref={section5Ref}>
        {t("terms.section_5_title")}
      </h3>
      <p className="mb-4">{t("terms.section_5_content")}</p>

      <h3 className="text-2xl font-semibold mb-8 mt-8" ref={section6Ref}>
        {t("terms.section_6_title")}
      </h3>
      <p className="mb-4">{t("terms.section_6_content")}</p>
    </div>
  );
}
