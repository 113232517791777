import React from "react";
import PropTypes from "prop-types";

const SearchBar = ({ value, onChange }) => {
  return (
    <div className="w-full mb-6">
      <input
        type="text"
        className="w-full px-4 py-2 rounded-lg outline-none border mt-regular focus:border-blue border-gray-400 overflow-hidden"
        placeholder=""
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

SearchBar.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SearchBar;
