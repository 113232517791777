import { useContext } from "react";
import { useMutation } from "react-query";
import AuthContext from "contexts/AuthContext";

const { REACT_APP_API } = process.env;

const createShipping = async ({ authTokens, payload }) => {
  const response = await fetch(`${REACT_APP_API}/shipping/create`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authTokens.accessToken}`,
    },
    body: JSON.stringify(payload),
  });

  if (!response.ok) {
    throw new Error("Failed to create shipping");
  }

  return response.json();
};

export const useCreateShipping = (mutationOptions) => {
  const { authTokens } = useContext(AuthContext);

  return useMutation({
    mutationKey: ["createShipping"],
    mutationFn: (payload) => createShipping({ authTokens, payload }),
    ...mutationOptions,
    enabled: !!authTokens,
  });
};
