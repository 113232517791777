import React, { useEffect, useRef, useCallback, useMemo } from "react";
import "./brick-wall.css";

const BrickWall = ({
  initialWeight = 0,
  speedFactor = 5,
  animationProgress = 0,
  size = 24,
}) => {
  const containerRef = useRef(null);
  const valueRef = useRef(null);
  const hasAnimated = localStorage.getItem("hasAnimated");

  const { brickWidth, brickHeight } = useMemo(() => {
    const emToPx = (em) => {
      return Math.floor(em * size);
    };
    return {
      brickWidth: emToPx(7.95),
      brickHeight: emToPx(2.4),
    };
  }, [size]);

  const generateSpecialBricks = useCallback(
    (rows, columns, specialBrickCount) => {
      const specialBricks = new Set();
      while (specialBricks.size < specialBrickCount) {
        const randomIndex = Math.floor(Math.random() * rows * columns);
        specialBricks.add(randomIndex);
      }
      return specialBricks;
    },
    []
  );

  const createBrick = useCallback((index, specialBricks) => {
    const brick = document.createElement("div");
    brick.classList.add("brick");
    if (specialBricks.has(index)) {
      const randomSpecialClass = `special${Math.floor(Math.random() * 3) + 1}`;
      brick.classList.add(randomSpecialClass);
    }
    for (let i = 0; i < 4; i++) {
      const nodule = document.createElement("div");
      nodule.classList.add("nodule");
      brick.appendChild(nodule);
    }
    return brick;
  }, []);

  const shuffleArray = useCallback((array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }, []);

  const animateBricks = useCallback((bricks, duration, callback) => {
    let startTime;
    const totalBricks = bricks.length;

    const animate = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const progress = timestamp - startTime;
      const index = Math.min(
        Math.floor((progress / duration) * totalBricks),
        totalBricks - 1
      );

      for (let i = 0; i <= index; i++) {
        bricks[i].style.opacity = 1;
      }

      if (index < totalBricks - 1) {
        requestAnimationFrame(animate);
      } else {
        callback();
      }
    };

    requestAnimationFrame(animate);
  }, []);

  const addRow = useCallback(
    (
      container,
      rowIndex,
      columns,
      specialBricks,
      brickAnimationDuration,
      callback
    ) => {
      const row = document.createElement("div");
      row.classList.add("row");
      row.style.bottom = `${rowIndex * brickHeight}px`;
      if (rowIndex % 2 !== 0) {
        row.style.marginLeft = `${-brickWidth / 2}px`;
      }

      const brickElements = [];
      for (let col = 0; col < columns + 1; col++) {
        const brickIndex = rowIndex * columns + col;
        const brick = createBrick(brickIndex, specialBricks);
        brickElements.push(brick);
        row.appendChild(brick);
      }

      container.appendChild(row);

      const shuffledBricks = shuffleArray(brickElements);
      animateBricks(shuffledBricks, brickAnimationDuration, callback);
    },
    [createBrick, shuffleArray, brickHeight, brickWidth, animateBricks]
  );

  const animateFilling = useCallback(
    (
      container,
      rows,
      columns,
      specialBricks,
      brickAnimationDuration,
      maxRows
    ) => {
      let currentRow = 0;

      const fillRow = () => {
        if (currentRow < maxRows) {
          addRow(
            container,
            currentRow,
            columns,
            specialBricks,
            brickAnimationDuration,
            () => {
              currentRow++;
              fillRow();
            }
          );
        }
      };

      fillRow();
    },
    [addRow]
  );

  const displayBricksImmediately = useCallback(
    (container, rows, columns, specialBricks, maxRows) => {
      for (let rowIndex = 0; rowIndex < maxRows; rowIndex++) {
        const row = document.createElement("div");
        row.classList.add("row");
        row.style.bottom = `${rowIndex * brickHeight}px`;
        if (rowIndex % 2 !== 0) {
          row.style.marginLeft = `${-brickWidth / 2}px`;
        }

        for (let col = 0; col < columns + 1; col++) {
          const brickIndex = rowIndex * columns + col;
          const brick = createBrick(brickIndex, specialBricks);
          brick.style.opacity = 1;
          row.appendChild(brick);
        }

        container.appendChild(row);
      }
    },
    [createBrick, brickHeight, brickWidth]
  );

  const animateValue = useCallback((valueElement, endValue, duration) => {
    let startTime;

    const incrementCount = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const progress = timestamp - startTime;
      const newValue = Math.min(
        Math.floor((progress / duration) * endValue),
        endValue
      );

      valueElement.textContent = newValue;

      if (newValue < endValue) {
        requestAnimationFrame(incrementCount);
      }
    };

    requestAnimationFrame(incrementCount);
  }, []);

  useEffect(() => {
    const container = containerRef.current;
    const valueElement = valueRef.current;
    const containerWidth = container.clientWidth;
    const containerHeight = container.clientHeight;

    const rows = Math.floor(containerHeight / brickHeight);
    const maxRows = Math.floor((animationProgress / 100) * rows);

    const columns = Math.ceil(containerWidth / brickWidth) + 1;
    const specialBrickCount = Math.ceil((rows * columns) / 6);
    const specialBricks = generateSpecialBricks(
      rows,
      columns,
      specialBrickCount
    );

    if (!hasAnimated) {
      const normalizedSpeedFactor = speedFactor / rows;
      const totalAnimationDuration = (100 / normalizedSpeedFactor) * rows;
      const brickAnimationDuration = totalAnimationDuration / (rows * columns);

      animateFilling(
        container,
        rows,
        columns,
        specialBricks,
        brickAnimationDuration,
        maxRows
      );
      animateValue(valueElement, initialWeight, totalAnimationDuration);
      localStorage.setItem("hasAnimated", true);
    } else {
      displayBricksImmediately(
        container,
        rows,
        columns,
        specialBricks,
        maxRows
      );

      valueElement.textContent = initialWeight;
    }

    return () => {
      container.innerHTML = "";
      if (valueElement) valueElement.textContent = "";
    };
  }, [
    initialWeight,
    generateSpecialBricks,
    animateFilling,
    animateValue,
    brickHeight,
    brickWidth,
    speedFactor,
    animationProgress,
    hasAnimated,
    displayBricksImmediately,
  ]);

  return (
    <div className="brick-wall-container">
      <div
        id="text-container"
        style={{ display: "none" }}
        className="hidden"
        ref={valueRef}
      >
        <h2 ref={valueRef}>{initialWeight}</h2>
      </div>
      <div
        id="brick-container"
        ref={containerRef}
        style={{ bottom: 0, width: "100%" }}
      ></div>
    </div>
  );
};

export default BrickWall;
