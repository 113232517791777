import { useState, useContext } from "react";
import AuthContext from "contexts/AuthContext";

const { REACT_APP_API } = process.env;

const updateUserPreferences = async ({
  preferences,
  accessToken,
  setConfig,
}) => {
  setConfig((prev) => ({ ...prev, isLoading: true }));

  try {
    const response = await fetch(`${REACT_APP_API}/user/prefrences`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(preferences),
    });

    setConfig((prev) => ({ ...prev, success: response.ok }));
  } catch (error) {
    console.error(error);
    setConfig((prev) => ({ ...prev, success: false }));
  }

  setConfig((prev) => ({ ...prev, isLoading: false }));
};

export const useUpdateUserPreferences = () => {
  const [config, setConfig] = useState({
    isLoading: false,
    success: undefined,
  });
  const { authTokens } = useContext(AuthContext);

  const updatePreferences = (preferences) => {
    updateUserPreferences({
      preferences,
      accessToken: authTokens?.access_token,
      setConfig,
    });
  };

  return [config, updatePreferences];
};
